import { loginReducer } from './LoginReducer';
import { empresaReducer } from './EmpresasReducer';
import { powerbiReducer } from './PowerBiReducer'

import { combineReducers } from 'redux';

export const Reducers = combineReducers({
  loginState: loginReducer,
  empresaReducer: empresaReducer,
  powerbiReducer: powerbiReducer,
});
