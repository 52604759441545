import React, { Component } from 'react'
import { Grid } from '@mui/material'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { setAcessToken, setAcessTokenUser } from '../../redux/actions'
import { PowerBIEmbed } from 'powerbi-client-react'
import { models } from 'powerbi-client'
import { toast } from 'react-toastify'
import PowerbiService from '../../service/PowerbiService'
import './powerbiStyles.css'
import { Utils } from '../../utilities'

class PowerBi extends Component {
  constructor(props) {
    super(props)

    this.state = {
      powerBiGroupId: '',
      powerBiReportId: '',
      accessToken: '',
      acessouError: false,
    }

    this.RefPowerBi = this.RefPowerBi.bind(this)
  }

  RefPowerBi() {
    let report = window.report
    return report
  }

  componentDidMount() {
    if (this.props.powerbi) {
      let { accessToken, powerBiGroupId, powerBiReportId } = this.props.powerbi
      this.setState({
        accessToken,
        powerBiGroupId,
        powerBiReportId,
      })
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.powerbi !== this.props.powerbi) {
      let { accessToken, powerBiGroupId, powerBiReportId } = this.props.powerbi
      this.setState({
        accessToken,
        powerBiGroupId,
        powerBiReportId,
      })
    }

    if(prevProps.mobilePowerbi !== this.props.mobilePowerbi) {
      window.location.reload()
    }

    if (prevState.acessouError !== this.state.acessouError) {
      if (this.state.acessouError) {
        PowerbiService.getToken('/usuario/token')
          .then((data) => {
            window.setAcessToken(data.accessToken)
            window.setAcessTokenUser(data.accessToken)
            window.location.reload()
            this.setState({ acessouError: false })
          })
          .catch((error) => {
            toast.error('Falha na conexão com o servidor', {
              theme: 'colored',
            })
          })
      }
    }
  }

  render() {
    let { accessToken, powerBiGroupId, powerBiReportId } = this.state

    let embebUrl = `https://app.powerbi.com/reportEmbed?reportId=${powerBiReportId}&groupId=${powerBiGroupId}?rs:Embed=True&filterPaneEnabled=False`
    window.setAcessToken = this.props.setAcessToken
    window.setAcessTokenUser = this.props.setAcessTokenUser

    if (Utils.isNotNull(window.report)) {
      window.report.on('error', async (res) => {
        if (!this.state.acessouError) {
          this.setState({
            acessouError: true,
          })
        }
      })
    }

    return (
      <Grid
        justifyContent="center"
        alignItems="center"
        minHeight={'100%'}
        padding={2}
      >
        <Grid xs={12} minHeight={'100%'} className="shadow" item>
          {this.state.powerBiReportId ===
            this.props.powerbi.powerBiReportId && (
            <PowerBIEmbed
              embedConfig={{
                type: 'report', // Supported types: report, dashboard, tile, visual and qna
                id: powerBiReportId,
                embedUrl: embebUrl,
                accessToken: accessToken,
                tokenType: models.TokenType.Aad,
                settings: {
                  panes: {
                    pageNavigation: {
                      visible: false,
                    },
                    filters: {
                      expanded: false,
                      visible: false,
                    },
                  },
                  layoutType: this.props.mobilePowerbi
                    ? models.LayoutType.MobilePortrait
                    : models.LayoutType.Master,
                  //  background: models.BackgroundType.Transparent,
                },
              }}
              eventHandlers={
                new Map([
                  [
                    'loaded',
                    function () {
                      console.log('Report loaded')
                    },
                  ],
                  [
                    'rendered',
                    function () {
                      console.log('Report rendered')
                    },
                  ],
                  [
                    'error',
                    async function (event) {                   
                      if (
                        event.detail.errorCode === '403' ||
                        event.detail.message === 'TokenExpired'
                      ) {
                        PowerbiService.getToken('/usuario/token')
                          .then((data) => {
                            window.setAcessToken(data.accessToken)
                            window.setAcessTokenUser(data.accessToken)
                            window.location.reload()
                          })
                          .catch((error) => {
                            toast.error('Falha na conexão com o servidor', {
                              theme: 'colored',
                            })
                          })
                      }
                    },
                  ],
                ])
              }
              cssClassName={'powerbi-container'}
              getEmbeddedComponent={(embeddedReport) => {
                window.report = embeddedReport
              }}
            />
          )}
        </Grid>
      </Grid>
    )
  }
}

const mapStateToProps = (store) => ({
  powerbi: store.powerbiReducer.powerbi,
  empresa: store.empresaReducer,
  mobilePowerbi: store.powerbiReducer.mobile,
})
const mapDispatchToProps = (dispatch) =>
  bindActionCreators({ setAcessToken, setAcessTokenUser }, dispatch)

export default connect(mapStateToProps, mapDispatchToProps)(PowerBi)
