import React, { useState } from 'react'
import * as S from './styles'
import {
  FullScreenDialog,
  Input,
  InputSelect,
  ModalConfirmar,
  TableComponet,
  Titulo,
} from '../../components'
import { Button, CircularProgress, Grid, Stack, Tooltip } from '@mui/material'
import AddCircleOutlineOutlinedIcon from '@mui/icons-material/AddCircleOutlineOutlined'
import SearchOutlinedIcon from '@mui/icons-material/SearchOutlined'
import { api, Utils } from '../../utilities'
import { connect } from 'react-redux'
import { toast } from 'react-toastify'
import { FilterAltOff, GroupAddOutlined } from '@mui/icons-material'
import PerfisService from '../../service/PerfisService'

function RelatorioSincronizar(props) {
  const [openModal, setOpenModal] = React.useState(false)
  const handleOpenModal = () => setOpenModal(true)
  const handleCloseModal = () => {
    setOpenModal(false)
    setUsuarioSelecionado({
      nome: null,
      ativoEmpresa: false,
    })
  }

  const [openSemCadastro, setOpenSemCadastro] = useState(false)
  const handleOpenSemCadastro = () => setOpenSemCadastro(true)
  const handleCloseSemCadastro = () => setOpenSemCadastro(false)

  const [loading, setLoading] = useState(false)

  const [usuarios, setUsuarios] = useState(props.usuarios)
  const [tipos, setTipos] = useState([])
  const [usuariosSemCadastro, setUsuarioSemCadastro] = useState([])
  //const [infoRelatorio, setUsuarioSelecionado] = useState({});
  const [selected, setSelected] = useState([])

  const [usuarioSelecionado, setUsuarioSelecionado] = useState({
    nome: null,
    ativoPerfil: false,
  })

  const [stateUsuario, setStateUsuario] = useState({
    idPerfil: props.idPerfil,
    idEmpresa: props.idEmpresa,
    descricao: null,
    tipo: null,
  })

  const headers = [
    {
      id: 'nome',
      label: 'Nome',
      props: {
        align: 'left',
      },
    },
    {
      id: 'login',
      label: 'Login',
      props: {
        align: 'right',
      },
    },
    {
      id: 'tipo',
      label: 'Tipo',
      props: {
        align: 'right',
      },
    },
    {
      id: 'statusRows',
      label: 'Vínculo',
      props: {
        align: 'right',
      },
    },
    {
      id: 'actionRows',
      label: 'Ativar/Desativar',
      props: {
        align: 'right',
      },
    },
  ]

  const headersSemcadastro = [
    {
      id: 'nome',
      label: 'Nome',
      props: {
        align: 'left',
      },
    },
    {
      id: 'login',
      label: 'Login',
      props: {
        align: 'right',
      },
    },
    {
      id: 'tipo',
      label: 'Tipo',
      props: {
        align: 'right',
      },
    },
    {
      id: 'statusRows',
      label: 'Status',
      props: {
        align: 'right',
      },
    },
  ]

  async function buscarCadastrados() {
    api
      .get('perfil/completo/' + props.idPerfil)
      .then(({ data }) => {
        setLoading(false)
        if (Utils.isNotObjectEmpty(data)) {
          setUsuarios(data.usuarios ? data.usuarios : [])
        }
      })
      .catch((error) => {
        setLoading(false)
        toast.error('Houve um problema na busca dos dados do Perfil!', {
          theme: 'colored',
        })
      })
  }

  async function buscarUsuariosSemCadastroFiltro() {
    setLoading(true)

    if (props.user.tipo === 'ADMIN') {
      api
        .post('/usuario/admin/cliente/filtro/semcadastro', {
          idEmpresa: props.idEmpresa,
        })
        .then(({ data }) => {
          setLoading(false)
          if (Utils.isNotObjectEmpty(data)) {
            setUsuarioSemCadastro(data.usuario ? data.usuarios : [])
            setTipos(data.tipos)
          } else {
            setUsuarioSemCadastro([])
          }
        })
        .catch((error) => {
          setLoading(false)
          toast.error(
            'Houve um problema ao buscar os Relatórios sem cadastro!',
            {
              theme: 'colored',
            },
          )
        })
    } else {
      api
        .post('/usuario/admin/mobioh/filtro/semcadastro', {
          idEmpresa: props.idEmpresa,
        })
        .then(({ data }) => {
          setLoading(false)
          if (Utils.isNotObjectEmpty(data)) {
            setUsuarioSemCadastro(data.usuario ? data.usuarios : [])
            setTipos(data.tipos)
          } else {
            setUsuarioSemCadastro([])
          }
        })
        .catch((error) => {
          setLoading(false)
          toast.error(
            'Houve um problema ao buscar os Relatórios sem cadastro!',
            {
              theme: 'colored',
            },
          )
        })
    }
  }

  async function buscarUsuariosSemCadastro() {
    setLoading(true)
    api
      .post('/usuario/perfil/semcadastro', stateUsuario)
      .then(({ data }) => {
        setLoading(false)
        if (Utils.isArrayNotEmpty(data)) {
          setUsuarioSemCadastro(data)
        } else {
          setUsuarioSemCadastro([])
        }
      })
      .catch((error) => {
        setLoading(false)
        toast.error('Houve um problema ao buscar os Relatórios sem cadastro!', {
          theme: 'colored',
        })
      })
  }

  function editarUsuario(data) {
    setUsuarioSelecionado(data)
    handleOpenModal()
  }

  function closeDialog() {
    setUsuarioSelecionado({})
    handleCloseModal()
  }

  function closeDialogSemCadastro() {
    handleCloseSemCadastro()
    setUsuarioSemCadastro([])
    setUsuarioSelecionado([])
  }

  // SELECAO SEM CADASTRO

  function sincronizarSelecionados() {
    setLoading(true)

    let newSincronizar = []

    selected.forEach((item) => {
      newSincronizar.push({
        idPerfil: props.idPerfil,
        idUsuario: item.idUsuario,
        ativoPerfil: true,
        idUsuarioAtualizacao: props.user.idUsuario,
      })
    })

    PerfisService.sincronizarUsuario(newSincronizar)
      .then(({ status, mensagem }) => {
        setLoading(false)
        if (status) {
          toast.success(mensagem, {
            theme: 'colored',
          })
          buscarCadastrados()
          closeDialogSemCadastro()
        } else {
          toast.error(mensagem, {
            theme: 'colored',
          })
        }
      })
      .catch((error) => {
        setLoading(false)
        toast.error('Houve um problema ao sincronizar os Relatórios!', {
          theme: 'colored',
        })
      })
  }

  function ButtonAction() {
    return (
      <Stack direction="row" xs={12} spacing={2} justifyContent="end">
        <Button
          className="confirm-button"
          variant="contained"
          size="small"
          sx={{ height: '48px', minWidth: '300px', textTransform: 'none' }}
          disabled={loading}
          endIcon={
            loading ? (
              <CircularProgress color="inherit" size={25} />
            ) : (
              <GroupAddOutlined />
            )
          }
          onClick={sincronizarSelecionados}
        >
          Vincular Usuários Selecionados
        </Button>
      </Stack>
    )
  }

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelected = usuariosSemCadastro.map((n) => {
        n.idUsuarioAtualizacao = props.user.idUsuario
        return n
      })
      setSelected(newSelected)
      return
    }
    setSelected([])
  }

  const handleClickSelected = (event, usuario) => {
    const selectedIndex = selected.findIndex(
      (selectedUsuario) => selectedUsuario.idUsuario === usuario.idUsuario,
    )
    let newSelected = []

    if (selectedIndex === -1) {
      usuario.idUsuarioAtualizacao = props.user.idUsuario
      newSelected = [...selected, usuario]
    } else {
      newSelected = selected.filter(
        (selectedUsuario) => selectedUsuario.idUsuario !== usuario.idUsuario,
      )
    }
    setSelected(newSelected)
  }

  function desativarUsuario(data) {
    setLoading(true)
    let newUser = [
      {
        idUsuario: data.idUsuario,
        idPerfil: props.idPerfil,
        ativoPerfil: !data.ativoPerfil,
        idUsuarioAtualizacao: props.user.idUsuario,
      },
    ]
    PerfisService.sincronizarUsuario(newUser)
      .then(({ status, mensagem }) => {
        setLoading(false)
        if (status) {
          toast.success(mensagem, {
            theme: 'colored',
          })
          closeDialog()
          buscarCadastrados()
        } else {
          toast.error(mensagem, {
            theme: 'colored',
          })
        }
      })
      .catch((error) => {
        setLoading(false)
        toast.error('Houve um problema ao sincronizar os Usuário!', {
          theme: 'colored',
        })
      })

    handleCloseModal()
  }

  return (
    <S.Container>
      <ModalConfirmar
        open={openModal}
        handleConfirm={() => desativarUsuario(usuarioSelecionado)}
        handleClose={handleCloseModal}
        customStyle={{ width: 400 }}
      >
        <S.TitleModal>
          {usuarioSelecionado.ativoPerfil
            ? 'Deseja DESVINCULAR o usuário nesse perfil?'
            : 'Deseja VINCULAR o usuário nesse perfil?'}
        </S.TitleModal>
      </ModalConfirmar>
      <Titulo>
        <div>Usuários Vinculados</div>
        <Stack
          direction="row"
          spacing={2}
          justifyContent="end"
          marginTop="-26px"
        >
          <Button
            className="confirm-button"
            variant="contained"
            size="medium"
            endIcon={<AddCircleOutlineOutlinedIcon />}
            //sx={{ height: '43px' }}
            onClick={() => {
              buscarUsuariosSemCadastro()
              buscarUsuariosSemCadastroFiltro()
              handleOpenSemCadastro()
            }}
          >
            Vincular Usuários
          </Button>
        </Stack>
      </Titulo>
      <S.ContainerForm>
        <Grid container padding={1}>
          <Grid xs={12}>
            <TableComponet
              headers={headers}
              data={usuarios}
              handlerDeletarAction={(data) => editarUsuario(data)}
              labelCaption="Nenhum Usuário Encontrado"
              labelTable="Lista de Usuários"
              status="ativoPerfil"
              statusLabelTrue="Ativo"
              statusLabelFalse="Inativo"
              loading={loading}
            />
          </Grid>
        </Grid>
      </S.ContainerForm>

      <FullScreenDialog
        label={'Usuários Disponíveis'}
        open={openSemCadastro}
        handleClose={closeDialogSemCadastro}
      >
        <Grid container padding={2} spacing={2} marginBottom={1} marginTop={1}>
          <Grid xs={12} md={5} item>
            <Input
              label="Pesquisar"
              id={'descricao'}
              // error={validacao(search)}
              // helperText={
              //   validacao(search) ? 'Essa informação é obrigatória' : ''
              // }
              value={stateUsuario.descricao}
              onChange={(e) => {
                setStateUsuario((state) => ({
                  ...state,
                  descricao: e.target.value,
                }))
              }}
              placeholder="Escreva o Nome ou descrição do Relatório"
              size="small"
            />
          </Grid>

          <Grid xs={2} item>
            <InputSelect
              value={stateUsuario.tipo}
              setValue={(value) => {
                setStateUsuario((state) => ({
                  ...state,
                  tipo: value,
                }))
              }}
              options={tipos}
              label="Tipo de Usuário"
              id="tipo"
              descricao="descricao"
              size="small"
              sx={{ padding: '5px 0px 0px', marginTop: '-5px' }}
            />
          </Grid>

          <Grid xs={12} md={2} item display={'flex'}>
            <Button
              className="confirm-button"
              variant="contained"
              size="large"
              startIcon={
                loading ? (
                  <CircularProgress color="inherit" size={25} />
                ) : (
                  <SearchOutlinedIcon />
                )
              }
              disabled={loading}
              sx={{ height: '39px' }}
              onClick={buscarUsuariosSemCadastro}
            >
              Buscar
            </Button>

            <Tooltip
              title={'Limpar Filtros'}
              placement="top"
              style={{ fontSize: '20px !important' }}
            >
              <Button
                className="confirm-button"
                variant="text"
                size="large"
                startIcon={
                  loading ? (
                    <CircularProgress color="inherit" size={25} />
                  ) : (
                    <FilterAltOff />
                  )
                }
                disabled={loading}
                sx={{ height: '39px', color: '#C4C4C4 !important' }}
                onClick={() =>
                  setStateUsuario((state) => ({
                    ...state,
                    descricao: '',
                    tipo: null,
                  }))
                }
              ></Button>
            </Tooltip>
          </Grid>
        </Grid>
        <Grid container padding={2}>
          <Grid xs={12} item>
            <TableComponet
              headers={headersSemcadastro}
              data={usuariosSemCadastro}
              handlerEditarAction={(data) => editarUsuario(data)}
              labelCaption="Nenhum Usuário Encontrado"
              labelTable="Lista de Usuários"
              status="ativo"
              statusLabelTrue="Ativo"
              statusLabelFalse="Inativo"
              loading={loading}
              selected={selected}
              selectedName="idUsuario"
              handleClickSelected={handleClickSelected}
              handleSelectAllClick={handleSelectAllClick}
              ActionSelect={ButtonAction}
            />
          </Grid>
        </Grid>
      </FullScreenDialog>
    </S.Container>
  )
}

const mapStateToProps = (store) => ({
  user: store.loginState.user.usuario,
})

export default connect(mapStateToProps)(RelatorioSincronizar)
