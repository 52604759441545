import React, { createContext, useEffect, useState } from 'react'
import { createTheme } from '@mui/material'
import { connect } from 'react-redux'
import ThemeProviderMaterial from '@mui/material/styles/ThemeProvider'
import theme from '../../assets/styles/theme'
import { Utils } from '../../utilities'

export const ThemesContext = createContext({})

const ThemeContextProvider = ({ children, user }) => {
  const [state, setState] = useState({})

  const [themeMaterial, setThemeMaterial] = useState(
    createTheme({
      palette: {
        primary: {
          main: theme.colors.primary,
        },
        secondary: {
          main: theme.colors.secondary,
        },
      },
    }),
  )

  const setColor = () => {
    let origin = window.location.origin

    let cores = {
      corPrimaria: '#30696e',
      corSecundaria: '#55C5D0',
      corTerciaria: '#ED3B57',
      bgLogin: '#FFF',
    }

    if (origin === 'https://bi.inovattaconsulting.com') {
      cores = {
        corPrimaria: '#2D407C',
        corSecundaria: '#209BBF',
        corTerciaria: '#ED3B57',
        bgLogin: '#FFF',
      }
    }

    if (Utils.isNotNull(user)) {
      const { usuario } = user 
      if (!usuario.utilizaPersonalizacaoEmpresa) {
        cores = {
          corPrimaria: Utils.isNotNull(usuario.corPrimaria)
            ? usuario.corPrimaria
            : '#30696e',
          corSecundaria: Utils.isNotNull(usuario.corSecundaria)
            ? usuario.corSecundaria
            : '#55C5D0',
          corTerciaria: Utils.isNotNull(usuario.corTerciaria)
            ? usuario.corTerciaria
            : '#ED3B57',
          bgLogin: '#FFF',
        }
      }
    }

    // SET CORES GLOBAIS PARA CSS
    document.documentElement.style.setProperty(
      '--cor-primaria',
      cores.corPrimaria,
    )
    document.documentElement.style.setProperty(
      '--cor-secundaria',
      cores.corSecundaria,
    )
    document.documentElement.style.setProperty(
      '--cor-terciaria',
      cores.corTerciaria,
    )
    document.documentElement.style.setProperty('--cor-bg-login', cores.bgLogin)

    setThemeMaterial(
      createTheme({
        palette: {
          primary: {
            main: cores.corPrimaria,
          },
          secondary: {
            main: cores.corSecundaria,
          },
        },
      }),
    )

    setState({
      corPrimaria: cores.corPrimaria,
      corSecundaria: cores.corSecundaria,
      corTerciaria: cores.corTerciaria,
    })
  }

  useEffect(() => {
    setColor()
  }, [])

  useEffect(() => {
    setColor()
  }, [user])

  return (
    <ThemesContext.Provider value={state}>
      <ThemeProviderMaterial theme={themeMaterial}>
        {children}
      </ThemeProviderMaterial>
    </ThemesContext.Provider>
  )
}

const mapStateToProps = (store) => ({
  user: store.loginState.user,
})

export default connect(mapStateToProps)(ThemeContextProvider)
