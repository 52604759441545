import React from 'react'
import reactCSS from 'reactcss'
import { SketchPicker } from 'react-color'

function SketchExample({ color, setColor }) {
  const [colorState, setColorState] = React.useState(color ? color : '#FFF')
  const [displayColorPicker, setDisplayColorPicker] = React.useState(false)

  const handleClick = () => {
    setDisplayColorPicker(!displayColorPicker)
  }

  const handleClose = () => {
    setDisplayColorPicker(false)
  }

  const handleChange = (color) => {
    setColor(color.hex)
    setColorState(color.hex)
  }
  const styles = reactCSS({
    default: {
      color: {
        width: '60px',
        height: '20px',
        borderRadius: '2px',
        background: `${colorState}`,
      },
      swatch: {
        background: '#fff',
        borderRadius: '1px',
        boxShadow: '0 0 0 1px rgba(0,0,0,.1)',
        display: 'inline-block',
        cursor: 'pointer',
      },
      popover: {
        position: 'absolute',
        zIndex: '2',
      },
      cover: {
        position: 'fixed',
        top: '0px',
        right: '0px',
        bottom: '0px',
        left: '0px',
        
      },
    },
  })

  return (
    <div style={{ maxWidth: '60px' }}>
      <div style={styles.swatch} onClick={handleClick}>
        <div style={styles.color} />
      </div>
      {displayColorPicker ? (
        <div style={styles.popover}>
          <div style={styles.cover} onClick={handleClose} />
          <SketchPicker color={colorState} onChange={handleChange} />
        </div>
      ) : null}
    </div>
  )
}

export default SketchExample
