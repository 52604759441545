import { api } from "../utilities";


class PowerbiService {

  static async getToken(obj) {
    let res = null;

    await api.get('/usuario/token').then(response => {
      res = response.data;
    });

    return res;
  }

}

export default PowerbiService;
