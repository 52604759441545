import React, { useContext } from 'react'
import { useHistory } from 'react-router-dom'
import {
  Button,
  CircularProgress,
  Grid,
  IconButton,
  InputAdornment,
} from '@mui/material'
import logo from '../../assets/images/logomobioh.png'
import logoInovata from '../../assets/images/INOVATTA_LOGO.png'
import CryptoJS from 'crypto-js'
import UserService from '../../service/UserService'
import { toast, ToastContainer } from 'react-toastify'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { logar } from '../../redux/actions'
import { IPInfoContext } from 'ip-info-react'
import * as S from './styles'
import Color from '../../utilities/enums/Color'
import {
  Lock,
  PersonOutline,
  Visibility,
  VisibilityOff,
} from '@mui/icons-material'
import { BannerLogin } from '../../components'
import RecuperarSenha from './RecuperarSenha'

function Login(props) {
  const [showPassword, setShowPassword] = React.useState(false)
  const [loading, setLoading] = React.useState(false)
  const [login, setLogin] = React.useState('')
  const [isLogin, setIsLogin] = React.useState(false)
  const [isPassword, setIsPassword] = React.useState(false)
  const [password, setPassword] = React.useState('')
  const [isRecuperarSenha, setIsRecuperarSenha] = React.useState(false)

  const userInfo = useContext(IPInfoContext)
  const history = useHistory()

  const handleClickShowPassword = () => setShowPassword((show) => !show)
  const handleMouseDownPassword = (event) => {
    event.preventDefault()
  }

  async function handleLogin() {
    let senha = CryptoJS.MD5(password).toString()
    let newUser = {
      login,
      senha,
      ip: userInfo.ip,
      localizacao: userInfo.city + ' - ' + userInfo.country_name,
    }

    if (login.trim() === '') {
      setIsLogin(true)
      return
    }

    if (password.trim() === '') {
      setIsPassword(true)
      return
    }

    setLoading(true)
    await UserService.authentication(newUser)
      .then((data) => {
        setLoading(false)
        if (data.statusUsuario) {
          props.logar(true, data)
          sessionStorage.setItem('auth', JSON.stringify(data))
          toast.success('Autenticado com Sucesso!', {
            theme: 'colored',
          })
          history.push('/empresas')
        } else {
          toast.error('Falha na autenticação! Usuário ou senha inválidos', {
            theme: 'colored',
          })
          console.log('Falha na autenticação! Usuário ou senha inválidos')
        }
      })
      .catch((error) => {
        setLoading(false)
        if (error.message === 'Network Error') {
          toast.error('Falha na conexão com o servidor' + error.message, {
            theme: 'colored',
          })
          console.log('Falha na conexão com o servidor')
        }
      })
  }

  function iconInput(Icon, stylesIcon) {
    return (
      <div className="btn-bege" style={stylesIcon}>
        <Icon sx={{ fontSize: '19px' }} />
      </div>
    )
  }

  function isEmpresaInovatta() {
    let isEmpresaInovatta = false
    let origin = window.location.origin
    if (origin === 'https://bi.inovattaconsulting.com') {
      isEmpresaInovatta = true
    }
    return isEmpresaInovatta
  }

  function logoInovataImg() {
    if (isEmpresaInovatta()) {
      document.title = 'Inovatta - BI'
      return logoInovata
    }
    return logo
  }

  function descricaoProduto() {
    let descricaoProduto = 'Mobioh | Bi'
    if (isEmpresaInovatta()) {
      descricaoProduto = 'Inovatta - BI'
    }
    return descricaoProduto
  }

  function descricaoRodape() {
    let descricaoRodape = 'Copyright © 2023 Mobioh Sistemas. v.5.1.3'
    if (isEmpresaInovatta()) {
      descricaoRodape = 'Copyright © 2023 Inovatta Consulting. v.5.1.3'
    }
    return descricaoRodape
  }

  return (
    <S.Container>
      <ToastContainer />
      <Grid container minWidth={'100vw'} minHeight={'100vh'} bgcolor={'#FFF'}>
        <Grid
          xs={12}
          md={6}
          display={'flex'}
          justifyContent={'center'}
          alignItems={'center'}
        >
          {!isRecuperarSenha && (
            <S.BoxLogin>
              <S.Image src={logoInovataImg()} />

              <S.Title>{descricaoProduto()}</S.Title>
              <S.Divider />

              <S.StyledTextField
                placeholder="Informe seu login"
                size="medium"
                onChange={(e) => {
                  setIsLogin(false)
                  setLogin(e.target.value)
                }}
                error={isLogin}
                helperText={isLogin ? 'Essa informação é obrigatória' : ''}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <PersonOutline fontSize="small" />
                    </InputAdornment>
                  ),
                }}
              />
              <S.StyledTextField
                placeholder="Informe sua senha"
                type={showPassword ? 'text' : 'password'}
                onChange={(e) => {
                  setIsPassword(false)
                  setPassword(e.target.value)
                }}
                error={isPassword}
                helperText={isPassword ? 'Essa informação é obrigatória' : ''}
                size="medium"
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <Lock fontSize="small" />
                    </InputAdornment>
                  ),

                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={handleClickShowPassword}
                        onMouseDown={handleMouseDownPassword}
                        edge="end"
                      >
                        {showPassword ? (
                          <VisibilityOff fontSize="small" />
                        ) : (
                          <Visibility fontSize="small" />
                        )}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />

              <Button
                className="confirm-button"
                variant="contained"
                size="small"
                endIcon={
                  loading && <CircularProgress color="inherit" size={25} />
                }
                disabled={loading}
                sx={{ height: '43px', width: '85%', marginTop: '25px' }}
                onClick={handleLogin}
              >
                Entrar
              </Button>

              <Button
                className="confirm-button"
                variant="text"
                size="small"
                // endIcon={
                //   loading && <CircularProgress color="inherit" size={25} />
                // }
                disabled={loading}
                sx={{
                  height: '20px',
                  fontWeight: 'bold',
                  width: '85%',
                  marginTop: '15px',
                  textTransform: 'initial',
                }}
                onClick={() => setIsRecuperarSenha(true)}
              >
                Esqueci minha senha
              </Button>
            </S.BoxLogin>
          )}

          {isRecuperarSenha && (
            <S.BoxLogin>
              <S.Image src={logoInovataImg()} />

              <S.Title>{descricaoProduto()}</S.Title>
              <S.Divider />
              <RecuperarSenha setIsRecuperarSenha={setIsRecuperarSenha} />
            </S.BoxLogin>
          )}

          <caption
            style={{
              fontSize: '12px',
              position: 'absolute',
              bottom: '30px',
            }}
          >
            {descricaoRodape()}
          </caption>
        </Grid>
        <Grid
          xs={12}
          md={6}
          bgcolor={Color.EMPRESA.PRIMARIA}
          display={'flex'}
          justifyContent={'center'}
          alignItems={'center'}
        >
          <BannerLogin />
        </Grid>
      </Grid>
    </S.Container>
  )
}

const mapStateToProps = (store) => ({
  loggedIn: store.loginState.loggedIn,
  idUser: store.loginState.idUser,
  idFilial: store.loginState.idFilial,
})

const mapDispatchToProps = (dispatch) => bindActionCreators({ logar }, dispatch)

export default connect(mapStateToProps, mapDispatchToProps)(Login)
