import { POWERBI, TOKEN_POWERBI, MOBILE_POWERBI } from "./ActionTypes";

export const setPowerbi = (menu, powerbi) => ({
  type: POWERBI,
  menu,
  powerbi
});

export const setAcessToken = accessToken => ({
  type: TOKEN_POWERBI,
  accessToken
})

export const setMobilePowerBi = mobile => ({
  type: MOBILE_POWERBI,
  mobile
})
