import { api } from "../utilities";


class EmpresaService {

  static async atualizar(obj) {
    let res = null;

    await api.put('/empresa', obj).then(response => {
      res = response.data;
    });

    return res;
  }

  static async cadastrar(obj) {
    let res = null;

    await api.post('/empresa', obj).then(response => {
      res = response.data;
    });

    return res;
  }

  static async buscar(obj) {
    let res = null;

    await api.post('/empresa/admin/mobioh/dados', obj).then(response => {
      res = response.data;
    });

    return res;
  }

  static async buscarCNPJ(cnpj) {
    let res = null;

    await api.get(`/empresa/admin/mobioh/consulta/publica/cnpj/${cnpj}`).then(response => {
      res = response.data;
    });

    return res;
  }

}

export default EmpresaService;
