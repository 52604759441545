import * as React from "react";
import Box from "@mui/material/Box";

import banner1 from "../../assets/images/banner/banner-login-2023-12-21-1.webp";

// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "./styles.css";
import "swiper/css";
import "swiper/css/pagination";

// import required modules
import { Pagination } from "swiper/modules";

function BannerLogin() {
  return (
    <Box sx={{
      width: "100%",
      height: "100%",
      // borderLeftStyle: "solid",
      // borderLeftWidth: "thin",
      // borderLeftColor: "rgba(0, 0, 0, 0.04)",
      backgroundColor: "#fFF"
     }}>
      {/* <iframe
        src="http://mobioh.rds.land/produtosmobioh-app"
        style={{ width: '100%', height: '100%', border: 'none' }}
      /> */}
      <Swiper
        spaceBetween={30}
        pagination={{
          clickable: true,
        }}
        modules={[Pagination]}
        className="mobiohProdutos"
      >
        <SwiperSlide>{<BannerProduto />}</SwiperSlide>
      </Swiper>
    </Box>
  )
}

function BannerProduto() {
  return (
    <Box
      sx={{
      //  width: '100vw',
      //  height: '"100vh"',
        padding: '0.6rem',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'end',
      }}
    >
       
        <img responsive src={banner1} width={100} />
      {/* <Typography
        variant="h2"
        component="h2"
        sx={{ color: '#FFF', fontSize: '2.5rem' }}
      >
        Titulo do produto
      </Typography>
      <Typography
        variant="p"
        component="p"
        sx={{ color: '#FFF', fontSize: '14px' }}
      >
        Descrição do produto
      </Typography> */}
    </Box>
  )
}

export default BannerLogin
