import { api } from "../utilities";


class PerfisService {

  static async atualizar(obj) {
    let res = null;

    await api.put('/perfil', obj).then(response => {
      res = response.data;
    });

    return res;
  }

  static async cadastrar(obj) {
    let res = null;

    await api.post('/perfil', obj).then(response => {
      res = response.data;
    });

    return res;
  }

  static async buscarMobioh(obj) {
    let res = null;

    await api.post('/perfil/admin/mobioh/dados', obj).then(response => {
      res = response.data;
    });

    return res;
  }
  
  static async buscarCliente(obj) {
    let res = null;

    await api.post('/perfil/admin/cliente/dados', obj).then(response => {
      res = response.data;
    });

    return res;
  }

  static async sincronizarRelatorio(obj) {
    let res = null;

    await api.put('/relatorio/sincronizar/perfis/', obj).then(response => {
      res = response.data;
    });

    return res;
  }

  static async sincronizarUsuario(obj) {
    let res = null;

    await api.put('/usuario/sincronizar/perfis/', obj).then(response => {
      res = response.data;
    });

    return res;
  }

}

export default PerfisService;
