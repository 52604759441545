import React from 'react'
import * as S from './styles'
import { Input, ModalConfirmar, Titulo } from '../../components'
import { connect } from 'react-redux'
import {
  Button,
  CircularProgress,
  Grid,
  IconButton,
  InputAdornment,
  Stack,
} from '@mui/material'
import { Lock, VisibilityOff, Visibility } from '@mui/icons-material'
import UserService from '../../service/UserService'
import { toast } from 'react-toastify'
import CryptoJS from 'crypto-js'
import ImageUploader from '../../components/ImageUploader/ImageUploader'
import { logar } from '../../redux/actions'
import { bindActionCreators } from 'redux'

const Perfil = (props) => {
  const [openModal, setOpenModal] = React.useState(false)
  const handleOpenModal = () => setOpenModal(true)
  const handleCloseModal = () => setOpenModal(false)

  const [state, setState] = React.useState({
    usuario: null,
    senha: null,
    imagemLogo: props.user.imagemLogo,
  })
  const [confirmarSenha, setConfirmarSenha] = React.useState(null)

  const [showPassword, setShowPassword] = React.useState(false)
  const [loading, setLoading] = React.useState(false)

  const alignmentGrid = { margin: '0  0px 20px' }

  function atualizarUsuario() {
    setLoading(true)

    // if (!Utils.isNotNull(state.senha)) {
    //   toast.error('Preencha a senha!', {
    //     theme: 'colored',
    //   })
    //   setLoading(false)
    //   return
    // }

    if (state.senha !== confirmarSenha) {
      toast.error('Confirmar senha deve ser igual ao campo senha!', {
        theme: 'colored',
      })
      setLoading(false)
      return
    }

    let senha = state.senha ? CryptoJS.MD5(state.senha).toString() : null

    let obj = {
      idUsuario: props.user.idUsuario,
      nome: props.user.nome,
      senha: senha,
      idUsuarioAtualizacao: props.user.idUsuario,
      imagemLogo: state.imagemLogo,
    }

    UserService.atualizarUsuarioPerfil(obj)
      .then(({ status, mensagem }) => {
        setLoading(false)
        if (status) {
          toast.success(mensagem, {
            theme: 'colored',
          })

          let newUser = {
            ...props.dateUser,
          }
          newUser.usuario.imagemLogo = state.imagemLogo
          
          props.logar(true, newUser)
          sessionStorage.setItem('auth', JSON.stringify(newUser))
        } else {
          toast.error(mensagem, {
            theme: 'colored',
          })
        }
      })
      .catch((error) => {
        setLoading(false)
        toast.error('Houve um problema na atualização da senha do Usuário!', {
          theme: 'colored',
        })
      })
    handleCloseModal()
  }

  function atualizarImagem(imagem) {
    setLoading(true)

    let obj = {
      idUsuario: props.user.idUsuario,
      nome: props.user.nome,
      imagemLogo: imagem,
      corPrimaria: null,
      corSecundaria: null,
      corTerciaria: null,
      idUsuarioAtualizacao: props.user.idUsuario,
    }

    UserService.atualizarUsuarioPerfil(obj)
      .then(({ status, mensagem }) => {
        setLoading(false)
        if (status) {
          toast.success(mensagem, {
            theme: 'colored',
          })
          let newUser = {
            ...props.dateUser,
          }
          newUser.usuario.imagemLogo = imagem
          
          props.logar(true, newUser)
          sessionStorage.setItem('auth', JSON.stringify(newUser))
        } else {
          toast.error(mensagem, {
            theme: 'colored',
          })
        }
      })
      .catch((error) => {
        setLoading(false)
        toast.error('Houve um problema na atualização da senha do Usuário! 2', {
          theme: 'colored',
        })
      })
    handleCloseModal()
  }

  function setImage(image) {
    setState((state) => {
      return { ...state, imagemLogo: image }
    })
  }

  return (
    <S.Container>
      <ModalConfirmar
        open={openModal}
        handleConfirm={atualizarUsuario}
        handleClose={handleCloseModal}
        customStyle={{ width: 400 }}
      >
        <S.TitleModal>Confirmar a mudança de senha?</S.TitleModal>
      </ModalConfirmar>
      <Titulo>Editar Senha</Titulo>
      <S.ContainerForm>
        <Grid container spacing={2}>
          <Grid xs={12} md={6} item sx={alignmentGrid}>
            <Input
              label="Senha"
              type={showPassword ? 'text' : 'password'}
              id={'password'}
              // error={validacao(state.senha)}
              // helperText={
              //   validacao(state.senha) ? 'Essa informação é obrigatória' : ''
              // }
              value={state.senha}
              onChange={(e) => {
                setState((state) => {
                  return { ...state, senha: e.target.value }
                })
              }}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <Lock fontSize="small" />
                  </InputAdornment>
                ),

                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={() => setShowPassword(!showPassword)}
                      edge="end"
                    >
                      {showPassword ? (
                        <VisibilityOff fontSize="small" />
                      ) : (
                        <Visibility fontSize="small" />
                      )}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
              placeholder="Senha"
            />
          </Grid>

          <Grid xs={12} md={6} item sx={alignmentGrid}>
            <Input
              label="Confirmar Senha"
              type={showPassword ? 'text' : 'password'}
              id={'confirmarSenha'}
              error={confirmarSenha !== state.senha}
              helperText={
                confirmarSenha !== state.senha
                  ? 'Os campos devem ser iguais'
                  : ''
              }
              value={confirmarSenha}
              onChange={(e) => {
                setConfirmarSenha(e.target.value)
              }}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <Lock fontSize="small" />
                  </InputAdornment>
                ),

                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={() => setShowPassword(!showPassword)}
                      edge="end"
                    >
                      {showPassword ? (
                        <VisibilityOff fontSize="small" />
                      ) : (
                        <Visibility fontSize="small" />
                      )}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
              placeholder="Confirmar Senha"
            />
          </Grid>
        </Grid>
      </S.ContainerForm>

      <Titulo>Editar Imagem do Perfil</Titulo>
      <S.ContainerForm>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <ImageUploader
              loading={loading}
              setImage={setImage}
              atualizarImagem={atualizarImagem}
              img={state.imagemLogo}
            />
          </Grid>
        </Grid>
      </S.ContainerForm>
      <Stack direction="row" spacing={2} justifyContent="end">
        <Button
          className="confirm-button"
          variant="contained"
          size="large"
          type="submit"
          onClick={handleOpenModal}
          startIcon={loading && <CircularProgress color="inherit" size={25} />}
          disabled={loading}
        >
          Salvar
        </Button>
      </Stack>
    </S.Container>
  )
}

const mapStateToProps = (store) => ({
  idUsuario: store.loginState.user.idUsuario,
  user: store.loginState.user.usuario,
  dateUser: store.loginState.user,
})

const mapDispatchToProps = (dispatch) => bindActionCreators({ logar }, dispatch)

export default connect(mapStateToProps, mapDispatchToProps)(Perfil)
