import React, { useEffect } from 'react'
import { Grid, Typography } from '@mui/material'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { getEmpresa, setPowerbi } from '../../redux/actions'
import CardEmpresa from '../../components/CardEmpresa/CardEmpresa'
import ApartmentIcon from '@mui/icons-material/Apartment'
import { useHistory } from 'react-router-dom'
import { Utils, api } from '../../utilities'
import { useContext } from 'react'
import { IPInfoContext } from 'ip-info-react'

function Empresas(props) {
  const { empresas, fantasia, user } = props
  const history = useHistory()
  const [empresaSelecionada, setEmpresaSelecionada] = React.useState(fantasia)
  const userInfo = useContext(IPInfoContext)

  const AUTH = JSON.parse(sessionStorage.getItem('auth'))

  useEffect(() => {
    setEmpresaSelecionada(fantasia)
  }, [fantasia])

  useEffect(() => {
    if(Utils.isNotNull(empresas)) {
      if(empresas.length === 1) {
        handlerEmpresa(empresas[0])
      }
    }
  }, [])

  function handlerSetPowerBi(rota, menu, powerbi) {
    props.setPowerbi(menu, powerbi)
    history.push(rota)
  }

  function logAlterarEmpresa(dados) {
    let newObj = {
      idUsuario: user.idUsuario,
      idLogLogin: user.idLogLogin, //retornado no login do usuario
      idEmpresa: dados.idEmpresa, //enviar a empresa alterada
      tipo: 'ALTEROU_EMPRESA',
      ip: userInfo.ip,
      localizacao: userInfo.city + ' - ' + userInfo.country_name,
      aparelho: '',
      navegador: '',
    }
    api
      .post('/usuario/log', newObj)
      .then(({ data }) => {
        if (data.status) {
          console.log(data.mensagem)
        } else {
          console.log(data.mensagem)
        }
      })
      .catch((error) => {
        console.log('Houve um problema ao enviar os logs do usuário!')
      })
  }

  const handlerEmpresa = (empresa) => {
    props.getEmpresa(empresa.relatorios, empresa.imagemLogo, empresa.fantasia)

    logAlterarEmpresa(empresa)

    setEmpresaSelecionada(empresa.fantasia)

    props.setPowerbi(false, {
      accessToken: "",
      powerBiGroupId: "",
      powerBiReportId: "",
      nomeMenu: "Minhas empresas",
    })

    if (Utils.isNotNull(empresa.relatorios)) {
      empresa.relatorios.forEach((menu) => {
        if (menu.paginaInicial) {
          handlerSetPowerBi(`/empresas/${menu.powerBiReportId}`, true, {
            accessToken: props.accessToken,
            powerBiGroupId: menu.powerBiGroupId,
            powerBiReportId: menu.powerBiReportId,
            nomeMenu: menu.descricao,
          })
        }
      })
    }
  }

  return (
    <Grid
      direction="column"
      justifyContent="center"
      alignItems="center"
      minHeight={'100%'}
      padding={3}
      className="shadow"
      container
      sx={{ margin: '15px 10px 10px 5px', backgroundColor: '#FFF' }}
    >
      <Typography
        variant="h4"
        width={'100%'}
        component="h4"
        marginBottom={4}
        marginTop={4}
      >
        <ApartmentIcon fontSize="large" sx={{ margin: '-3px 13px' }} />
        Minhas Empresas
      </Typography>

      <Grid
        container
        spacing={{ xs: 2, md: 3 }}
        columns={{ xs: 4, sm: 8, md: 12 }}
      >
        {empresas &&
          empresas.map((empresa, index) => {
            return (
              <Grid item xs={4} sm={4} md={4} key={index}>
                <CardEmpresa
                  empresaSelecionada={empresaSelecionada}
                  handlerEmpresa={handlerEmpresa}
                  empresa={empresa}
                />
              </Grid>
            )
          })}

        <Grid item xs={12} md={12} padding={2} marginLeft={2}>
          {!AUTH.possuiEmpresasVinculadas && (
            <Typography
              variant="h6"
              component="h2"
              color={'#d32f2f'}
            >
              {AUTH.mensagemErro}
            </Typography>
          )}
        </Grid>
      </Grid>
    </Grid>
  )
}

const mapStateToProps = (store) => ({
  empresa: store.empresaReducer.empresa,
  fantasia: store.empresaReducer.fantasia,
  accessToken: store.loginState.user.accessToken,
  empresas: store.loginState.user.empresas,
  user: store.loginState.user.usuario,
})
const mapDispatchToProps = (dispatch) =>
  bindActionCreators({ getEmpresa, setPowerbi }, dispatch)

export default connect(mapStateToProps, mapDispatchToProps)(Empresas)
