import styled from 'styled-components'

export const Container = styled.div`
  user-select: none;

  display: flex;
  justify-content: center;
  align-items: center;

  border-radius: 100%;
  width: 5rem;
  height: 5rem;

  letter-spacing: 1px;
  font-size: 1rem;
  font-weight: 600;
  text-transform: uppercase;

  color: #0000;
  background: transparent;
`
