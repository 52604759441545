import React, { useState } from "react";
import { Box, Button, Checkbox, CircularProgress, FormControlLabel, Grid, Stack } from "@mui/material";
import { toast } from "react-toastify";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import { api, Utils } from "../../utilities";

const orderOptions = {
  abc: "abc",
  qdt: "qdt",
  none: "nenhum"
};

const styles = {
  boxInfo: {
    marginBottom: "50px",
    padding: "10px 0 20px 0",
    backgroundColor: "#FFF"
  }
};

// a little function to help us with reordering the result
const reorder = (list, startIndex, endIndex) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);

  let newList = [];

  if (result) {
    result.forEach((item, index) => {
      item.ordemPerfil = index + 1;
      newList.push(item);
    });
  }

  return newList;
};

const grid = 8;

const getItemStyle = (isDragging, draggableStyle) => ({
  // some basic styles to make the items look a bit nicer
  userSelect: "none",
  padding: grid * 2,
  margin: `0 0 ${grid}px 0`,
  height: 92,
  display: "flex",
  flexDirection: "row",

  // change background colour if dragging
  background: isDragging ? "#DEEBFF" : "#FFF",

  boxShadow: isDragging
    ? "rgba(6, 24, 44, 0.4) 0px 0px 0px 2px, rgba(6, 24, 44, 0.65) 0px 4px 6px -1px, rgba(255, 255, 255, 0.08) 0px 1px 0px inset"
    : "rgba(149, 157, 165, 0.2) 0px 8px 24px",
  // styles we need to apply on draggables
  ...draggableStyle
});
const label = { inputProps: { "aria-label": "Checkbox demo" } };

const getListStyle = (isDraggingOver) => ({
  background: isDraggingOver ? "#ffebe6" : "rgb(235, 236, 240)",
  padding: grid,
  width: "auto",
  height: "65vh",
  overflowY: "scroll"
});

export default function EditarOrdemMenu({
                                          relatorios,
                                          buscarCadastrados,
                                          closeDialogMenu,
                                          idUsuarioAtualizacao,
                                          idPerfil,
                                          idEmpresa
                                        }) {
  const [checkoutSelect, setCheckoutSelect] = useState("");
  const [loading, setLoading] = useState(false);

  const [state, setState] = React.useState({
    listMenu: Utils.isArrayNotEmpty(relatorios)
      ? relatorios.sort((a, b) => a.ordemPerfil - b.ordemPerfil)
      : [],
    icons: [],
    order: orderOptions.none,
    editar: false
  });

  React.useEffect(() => {
    if (Utils.isArrayNotEmpty(relatorios)) {
      relatorios.forEach((item) => {
        if (item.paginaInicialPerfil) {
          setCheckoutSelect(item.idRelatorio);
        }
      });
    }
  }, [relatorios]);

  function putMenu(list) {
    if (!state.editar) {
      toast.error(
        "É necessário realizar uma alteração no menu antes de salvar",
        {
          theme: "colored"
        }
      );
    }
    if (!checkoutSelect) {
      toast.error("Escolha uma Pagina Inicial", {
        theme: "colored"
      });
    }

    if (state.editar) {
      let newObj = [];

      state.listMenu.forEach((item) => {
        newObj.push({
          idPerfil: idPerfil,
          idEmpresa: idEmpresa,
          idRelatorio: item.idRelatorio,
          ordemPerfil: item.ordemPerfil,
          paginaInicialPerfil: checkoutSelect === item.idRelatorio,
          idUsuarioAtualizacao: idUsuarioAtualizacao
        });
      });
      setLoading(true);
      api
        .put("/relatorio/atualizar/perfis/ordem", newObj)
        .then(({ data }) => {
          setLoading(false);
          if (data.status) {
            toast.success(data.mensagem, {
              theme: "colored"
            });
            closeDialogMenu();
            buscarCadastrados();
          } else {
            toast.error(data.mensagem, {
              theme: "colored"
            });
          }
        })
        .catch((error) => {
          setLoading(false);
          toast.error("Houve um problema ao sincronizar os Relatórios!", {
            theme: "colored"
          });
        });
    }
  }

  function onDragEnd(result) {
    // dropped outside the list
    if (!result.destination) {
      return;
    }

    const items = reorder(
      [...state.listMenu],
      result.source.index,
      result.destination.index
    );

    setState((state) => ({
      ...state,
      listMenu: [...items],
      editar: true
    }));
  }

  return (
    <Box padding={3}>
      <Grid container padding={1} spacing={2}>
        <Grid xs={12} md={4} item></Grid>
      </Grid>

      <Grid container padding={1} spacing={2}>
        <Grid xs={12} md={12} item>
          <Grid xs={12} padding={2} item>
            <DragDropContext onDragEnd={onDragEnd}>
              <Droppable droppableId="droppable">
                {(provided, snapshot) => (
                  <div
                    {...provided.droppableProps}
                    ref={provided.innerRef}
                    style={getListStyle(snapshot.isDraggingOver)}
                    className="scroll-custom"
                  >
                    {state.listMenu.map((item, index) => (
                      <Draggable
                        key={item.idRelatorio}
                        draggableId={item.idRelatorio}
                        index={index}
                      >
                        {(provided, snapshot) => (
                          <div
                            ref={provided.innerRef}
                            {...provided.draggableProps}
                            {...provided.dragHandleProps}
                            style={getItemStyle(
                              snapshot.isDragging,
                              provided.draggableProps.style
                            )}
                          >
                            {/* <div
                              style={{
                                width: 78,
                                marginRight: 10,
                              }}
                            >
                              icone
                            </div> */}
                            <div
                              style={{
                                display: "flex",
                                width: "100%",
                                flexDirection: "column"
                              }}
                            >
                              <span style={{ fontWeight: "bolder" }}>
                              {item.codigoRelatorio} - {item.nome}
                              </span>
                              <span
                                style={{
                                  width: "100%",
                                  display: "flex",
                                  flexDirection: "row",
                                  justifyContent: "space-between",
                                  fontSize: "14px",
                                  marginTop: "10px"
                                }}
                              >
                                <span>Ordem: {item.ordemPerfil}</span>
                              </span>
                            </div>
                            <div>
                              <FormControlLabel
                                value="start"
                                sx={{ width: "150px" }}
                                control={
                                  <Checkbox
                                    {...label}
                                    defaultChecked
                                    checked={
                                      item.idRelatorio === checkoutSelect
                                    }
                                    onChange={(e) => {
                                      setCheckoutSelect((state) =>
                                        e.target.checked
                                          ? item.idRelatorio
                                          : state
                                      );
                                      setState((state) => ({
                                        ...state,
                                        editar: true
                                      }));
                                    }
                                    }
                                  />
                                }
                                label="Página inicial"
                              />
                            </div>
                          </div>
                        )}
                      </Draggable>
                    ))}
                    {provided.placeholder}
                  </div>
                )}
              </Droppable>
            </DragDropContext>
          </Grid>
        </Grid>
      </Grid>

      <Stack
        direction="row"
        spacing={2}
        paddingRight={3}
        justifyContent="end"
        marginTop="26px"
      >
        <Button
          className="confirm-button"
          variant="contained"
          size="medium"
          //sx={{ height: '43px' }}
          onClick={() => {
            putMenu();
          }}
          disabled={loading}
          endIcon={loading && <CircularProgress color="inherit" size={25} />}
        >
          Salvar
        </Button>
      </Stack>
    </Box>
  );
}
