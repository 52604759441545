import React from 'react'
import { Box, Button, CircularProgress, Grid, Stack } from '@mui/material'
import {
  ComponentWithIcon,
  IconMaterial,
  Input,
  Item,
  ModalConfirmar,
  RadioButtonsGroup,
} from '../../components'
import { toast } from 'react-toastify'
import * as S from './styles'
import SearchOutlinedIcon from '@mui/icons-material/SearchOutlined'
import PerfisService from '../../service/PerfisService'

export default function EditarRelatorio({
  infoRelatorio = {},
  editar = false,
  idUsuarioAtualizacao = '',
  idEmpresa,
  idPerfil
}) {
  const [openModal, setOpenModal] = React.useState(false)
  const handleOpenModal = () => setOpenModal(true)
  const handleCloseModal = () => setOpenModal(false)

  const [openModalIcone, setOpenModalIcone] = React.useState(false)
  const handleOpenModalIcone = () => setOpenModalIcone(true)
  const handleCloseModalIcone = () => setOpenModalIcone(false)

  const [state, setState] = React.useState({
    idRelatorio: '',
    nome: '',
    nomePerfil: '',
    descricao: '',
    descricaoPerfil: '',
    menu: '',
    menuPerfil: '',
    menuIconePerfil: '',
    subMenu: '',
    subMenuPerfil: '',
    subMenuIconePerfil: '',
    ativoPerfil: true,
    ...infoRelatorio,
  })

  const [loading, setLoading] = React.useState(false)
  const [pesquisar, setPesquisar] = React.useState('')
  const [selectedMenu, setSelectedMenu] = React.useState('menuIcone')


  const optionsStatus = [
    { value: true, label: 'Ativo' },
    { value: false, label: 'Inativo' },
  ]

  function cadastrarEditar() {
    setLoading(true)

    let newObj = [
      {
        idPerfil: idPerfil,
        idEmpresa: idEmpresa,
        idRelatorio: state.idRelatorio,
        nomePerfil: state.nomePerfil,
        descricaoPerfil: state.descricaoPerfil,
        menuPerfil: state.menuPerfil,
        menuIconePerfil: state.menuIconePerfil,
        subMenuPerfil: state.subMenuPerfil,
        subMenuIconePerfil: state.subMenuIconePerfil,
        ativoPerfil: state.ativoPerfil,
        idUsuarioAtualizacao: idUsuarioAtualizacao,
      },
    ]

    PerfisService.sincronizarRelatorio(newObj)
      .then(({ status, mensagem }) => {
        setLoading(false)
        if (status) {
          toast.success(mensagem, {
            theme: 'colored',
          })
        } else {
          toast.error(mensagem, {
            theme: 'colored',
          })
        }
      })
      .catch((error) => {
        setLoading(false)
        toast.error('Houve um problema na edição do Relatório!', {
          theme: 'colored',
        })
      })

    handleCloseModal()
  }

  function handleSelectedIcone(icone) {
    setState((state) => ({
      ...state,
      [selectedMenu]: icone,
    }))
    handleCloseModalIcone()
  }

  return (
    <Box padding={3}>
      <ModalConfirmar
        open={openModal}
        handleConfirm={cadastrarEditar}
        handleClose={handleCloseModal}
        customStyle={{ width: 400 }}
      >
        <S.TitleModal>Salvar as informações do Relatório?</S.TitleModal>
      </ModalConfirmar>

      <ModalConfirmar
        open={openModalIcone}
        //  handleConfirm={cadastrarEditar}
        handleClose={handleCloseModalIcone}
        customStyle={{ width: '90vw', height: '90vh' }}
      >
        <S.TitleModal>Selecione um Ícone</S.TitleModal>

        <Grid container padding={1} spacing={2}>
          <Grid xs={5} item>
            <Input
              label="Pesquisar"
              id={'descricao'}
              // error={validacao(search)}
              // helperText={
              //   validacao(search) ? 'Essa informação é obrigatória' : ''
              // }
              value={pesquisar}
              onChange={(e) => {
                setPesquisar(e.target.value)
              }}
              placeholder="Escreva o nome do ícone "
              size="small"
            />
          </Grid>
          <Grid xs={2} item display={'flex'}>
            <Button
              className="confirm-button"
              variant="contained"
              size="large"
              startIcon={
                loading ? (
                  <CircularProgress color="inherit" size={25} />
                ) : (
                  <SearchOutlinedIcon />
                )
              }
              disabled={loading}
              sx={{ height: '39px' }}
              // onClick={buscarMobioh}
            >
              Buscar
            </Button>
          </Grid>
          <Grid xs={12}>
            <ComponentWithIcon
              handleSelectedIcone={handleSelectedIcone}
              pesquisar={pesquisar}
            />
          </Grid>
        </Grid>
      </ModalConfirmar>

      <Grid container padding={1} spacing={2}>
        <Grid xs={12} md={4} item>
          <Input
            label={`Nome Perfil (${state.nome})` }
            id={'nome'}

            value={state.nomePerfil}
            onChange={(e) => {
              setState((state) => ({
                ...state,
                nomePerfil: e.target.value,
              }))
            }}
            placeholder="Escreva o nome perfil para o Relatório"
            size="small"
          />
        </Grid>

        <Grid xs={12} md={8} item>
          <Input
            label={`Descrição Perfil (${state.descricao})`}
            id={'descricao'}

            value={state.descricaoPerfil}
            onChange={(e) => {
              setState((state) => ({
                ...state,
                descricaoPerfil: e.target.value,
              }))
            }}
            placeholder="Escreva uma descrição perfil para o Relatórios"
            size="small"
          />
        </Grid>

        <Grid xs={12} md={6} item>
          <Input
            label={`Menu Perfil (${state.menu})`}
            id={'menu'}
            value={state.menuPerfil}
            onChange={(e) => {
              setState((state) => ({
                ...state,
                menuPerfil: e.target.value,
              }))
            }}
            placeholder="Escreva um menu para o Relatório"
            size="small"
          />
        </Grid>

        <Grid xs={12} md={6} item display={'flex'} flexDirection={'row'}>
          <Button
            className="confirm-button"
            variant="outlined"
            size="large"
            color="info"
            disabled={loading}
            sx={{ height: '39px' }}
            onClick={() => {
              handleOpenModalIcone()
              setSelectedMenu('menuIconePerfil')
            }}
          >
            Selecionar Ícone para Menu
          </Button>
          {state.menuIconePerfil && (
            <Item
              style={{ height: 39, marginLeft: '15px', marginBottom: '8px' }}
            >
              <IconMaterial iconName={state.menuIconePerfil} />
            </Item>
          )}
        </Grid>

        <Grid xs={12} md={6} item>
          <Input
            label={`Submenu Perfil (${state.subMenu})`}
            id={'subMenu'}
            value={state.subMenuPerfil}
            onChange={(e) => {
              setState((state) => ({
                ...state,
                subMenuPerfil: e.target.value,
              }))
            }}
            placeholder="Escreva um Submenu para o Relatório"
            size="small"
          />
        </Grid>

        <Grid xs={12} md={6} item display={'flex'} flexDirection={'row'}>
          <Button
            className="confirm-button"
            variant="outlined"
            size="large"
            color="info"
            disabled={loading}
            sx={{ height: '39px' }}
            onClick={() => {
              handleOpenModalIcone()
              setSelectedMenu('subMenuIconePerfil')
            }}
          >
            Selecionar Ícone para Submenu
          </Button>
          {state.subMenuIconePerfil && (
            <Item
              style={{ height: 39, marginLeft: '15px', marginBottom: '8px' }}
            >
              <IconMaterial iconName={state.subMenuIconePerfil} />
            </Item>
          )}
        </Grid>

        {editar && (
          <Grid item xs={4}>
            <RadioButtonsGroup
              label="Status do Vínculo"
              options={optionsStatus}
              labelOptions="label"
              valueOptions="value"
              defaultValue={state.ativoPerfil}
              onChange={(e) => {
                setState((state) => ({
                  ...state,
                  ativoPerfil: e.target.value === 'true',
                }))
              }}
            />
          </Grid>
        )}
      </Grid>

      <Stack direction="row" spacing={2} justifyContent="end">
        <Button
          className="confirm-button"
          variant="contained"
          size="large"
          type="submit"
          onClick={handleOpenModal}
          startIcon={loading && <CircularProgress color="inherit" size={25} />}
          disabled={loading}
        >
          Salvar
        </Button>
      </Stack>
    </Box>
  )
}
