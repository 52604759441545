import React, { useState } from 'react'
import * as S from './styles'
import { Button, CircularProgress, Grid, Stack, Tooltip } from '@mui/material'
import {
  FullScreenDialog,
  Input,
  InputSelect,
  ModalConfirmar,
  RadioButtonsGroup,
  TableComponet,
  Titulo,
} from '../../components'
import {
  AddCircleOutlineOutlined,
  ArrowBack,
  FilterAltOff,
  SearchOutlined,
} from '@mui/icons-material'
import { api, Utils } from '../../utilities'
import { toast } from 'react-toastify'
import { connect } from 'react-redux'

function SincronizarRelatorios({ empresa, powerBiGroupId, ...props }) {
  const [open, setOpen] = React.useState(false)
  const handleOpen = () => setOpen(true)
  const handleClose = () => setOpen(false)

  const [openEditar, setOpenEditar] = React.useState(false)
  const handleOpenEditar = () => setOpenEditar(true)
  const handleCloseEditar = () => setOpenEditar(false)

  const [openModal, setOpenModal] = React.useState(false)
  const handleOpenModal = () => setOpenModal(true)
  const handleCloseModal = () => {
    setOpenModal(false)
    setRelatoriosSelecionado({
      nome: null,
      ativo: false,
      powerBiGroupId: '',
      powerBiReportId: '',
    })
  }

  const [openModalSincronizar, setOpenModalSincronizar] = React.useState(false)
  const handleOpenModalSincronizar = () => setOpenModalSincronizar(true)
  const handleCloseModalSincronizar = () => {
    setOpenModalSincronizar(false)
    setStateSincronizar({
      nome: null,
      ativo: false,
      powerBiGroupId: '',
      powerBiReportId: '',
    })
  }
  const [isSincronizar, setIsSincronizar] = useState(true)
  const [relatoriosCadastrados, setRelatoriosCadastrados] = useState([])
  const [relatorios, setRelatorios] = useState([])
  const [selected, setSelected] = useState([])
  const [loading, setLoading] = useState(false)
  const [relatoriosSelecionado, setRelatoriosSelecionado] = useState({
    idRelatorio: '',
    idEmpresa: '',
    powerBiGroupId: '',
    powerBiReportId: '',
    ativo: true,
  })

  const [stateRelatorio, setStateRelatorio] = React.useState({
    descricao: null,
    menu: null,
    subMenu: null,
  })

  const [menus, setMenus] = useState([])
  const [subMenus, setSubMenus] = useState([])
  const [stateSincronizar, setStateSincronizar] = useState({
    idRelatorio: '',
    idEmpresa: '',
    powerBiGroupId: powerBiGroupId,
    powerBiReportId: '',
    ativo: true,
    nome: '',
  })

  const [stateSincronizarEditar, setStateSincronizarEditar] = useState({
    idRelatorio: '',
    idEmpresa: '',
    powerBiGroupId: powerBiGroupId,
    powerBiReportId: '',
    ativo: true,
    ativoEmpresa: true,
    nome: '',
  })

  const optionsStatus = [
    { value: true, label: 'Ativo' },
    { value: false, label: 'Inativo' },
  ]

  const headers = [
    {
      id: 'codigoRelatorio',
      label: 'Código',
      props: {
        align: 'left',
      },
    },
    {
      id: 'nome',
      label: 'Nome',
      props: {
        align: 'left',
      },
    },
    {
      id: 'menu',
      label: 'menu',
      props: {
        align: 'left',
      },
    },
    {
      id: 'subMenu',
      label: 'Submenu',
      props: {
        align: 'left',
      },
    },
    {
      id: 'powerBiGroupId',
      label: 'Group Id',
      props: {
        align: 'left',
      },
    },
    {
      id: 'powerBiReportId',
      label: 'Report Id',
      props: {
        align: 'left',
      },
    },

    {
      id: 'statusRows',
      label: 'Situação',
      props: {
        align: 'right',
      },
    },
    {
      id: 'actionRows',
      label: 'Ações',
      props: {
        align: 'right',
      },
    },
  ]

  const headersRelatoriosSemCadastro = [
    {
      id: 'codigoRelatorio',
      label: 'Código',
      props: {
        align: 'left',
      },
    },
    {
      id: 'nome',
      label: 'Nome',
      props: {
        align: 'left',
      },
    },
    {
      id: 'menu',
      label: 'menu',
      props: {
        align: 'left',
      },
    },
    {
      id: 'subMenu',
      label: 'Submenu',
      props: {
        align: 'left',
      },
    },
    {
      id: 'statusRows',
      label: 'Ativo',
      props: {
        align: 'right',
      },
    },
    {
      id: 'actionRows',
      label: 'Sincronizar',
      props: {
        align: 'right',
      },
    },
  ]

  React.useEffect(() => {
    if (empresa) {
      setLoading(true)
      api
        .get(`/empresa/admin/mobioh/completa/${empresa.idEmpresa}`)
        .then(({ data }) => {
          if (Utils.isNotObjectEmpty(data)) {
            if (Utils.isNotObjectEmpty(data.relatorios)) {
              setRelatoriosCadastrados(data.relatorios)
            }
            setLoading(false)
          }
        })
    }
  }, [empresa])

  React.useEffect(() => {
    if (relatoriosSelecionado.nome) {
      handleOpenModal()
    }
  }, [relatoriosSelecionado])

  // React.useEffect(() => {
  //   if (stateSincronizar.idRelatorio) {
  //     handleOpenModalSincronizar()
  //   }
  // }, [stateSincronizar])

  React.useEffect(() => {
    if (stateSincronizarEditar.idRelatorio) {
      handleOpenEditar()
    }
  }, [stateSincronizarEditar])

  function buscarEmpresaCompleta() {
    setLoading(true)
    if (empresa) {
      api
        .get(`/empresa/admin/mobioh/completa/${empresa.idEmpresa}`)
        .then(({ data }) => {
          if (Utils.isNotObjectEmpty(data.relatorios)) {
            setRelatoriosCadastrados(data.relatorios)
            setLoading(false)
          }
        })
    }
  }

  function closeDialog() {
    handleClose()
  }

  function buscarMobioh() {
    setLoading(true)

    let obj = {
      idEmpresa: empresa.idEmpresa,
      ...stateRelatorio,
    }
    let newMenus = []
    let newSubmenus = []

    api
      .post('/relatorio/filtro/empresa/semcadastro', obj)
      .then(({ data }) => {
        setLoading(false)
        if (Utils.isNotObjectEmpty(data)) {
          if (data.totalRegistros > 0) {
            data.menus.forEach((element) => {
              newMenus.push({ id: element })
            })

            data.subMenus.forEach((element) => {
              newSubmenus.push({ id: element })
            })

            setSubMenus([...newSubmenus])
            setMenus([...newMenus])

            setRelatorios(data.relatorios)
          } else {
            setRelatorios([])
          }
        } else {
          setRelatorios([])
        }
      })
      .catch((error) => {
        setLoading(false)
        toast.error('Houve um problema ao buscar os Relatórios!', {
          theme: 'colored',
        })
      })
  }

  function desativarRelatorio(data) {
    setLoading(true)
    let newRelatorio = [
      {
        idRelatorio: data.idRelatorio,
        idEmpresa: empresa.idEmpresa,
        powerBiGroupId: data.powerBiGroupId,
        powerBiReportId: data.powerBiReportId,
        ativoEmpresa: Utils.isValueValid(data.ativoEmpresa)
          ? !data.ativoEmpresa
          : false,
        ativo: data.ativo,
        idUsuarioAtualizacao: props.user.idUsuario,
      },
    ]

    api
      .put('/relatorio/sincronizar/empresas', newRelatorio)
      .then(({ status, mensagem }) => {
        setLoading(false)
        if (status) {
          toast.success(mensagem, {
            theme: 'colored',
          })
          closeDialog()
          buscarEmpresaCompleta()
        } else {
          toast.error(mensagem, {
            theme: 'colored',
          })
        }
      })
      .catch((error) => {
        setLoading(false)
        toast.error('Houve um problema ao sincronizar o Relatório!', {
          theme: 'colored',
        })
      })

    handleCloseModal()
  }

  function sincronizarRelatorio(data) {
    setLoading(true)
    let newRelatorio = [
      {
        idRelatorio: data.idRelatorio,
        idEmpresa: empresa.idEmpresa,
        powerBiGroupId: data.powerBiGroupId,
        powerBiReportId: data.powerBiReportId,
        ativo: data.ativo,
        ativoEmpresa: Utils.isValueValid(data.ativoEmpresa)
          ? !data.ativoEmpresa
          : true,
        idUsuarioAtualizacao: props.user.idUsuario,
      },
    ]

    api
      .put('/relatorio/sincronizar/empresas', newRelatorio)
      .then(({ status, mensagem }) => {
        setLoading(false)
        if (status) {
          toast.success(mensagem, {
            theme: 'colored',
          })
          closeDialog()
          buscarEmpresaCompleta()
        } else {
          toast.error(mensagem, {
            theme: 'colored',
          })
        }
      })
      .catch((error) => {
        setLoading(false)
        toast.error('Houve um problema ao sincronizar o Relatório!', {
          theme: 'colored',
        })
      })

    handleCloseModalSincronizar()
  }

  return (
    <S.Container>
      <ModalConfirmar
        open={openModal}
        handleConfirm={() => desativarRelatorio(relatoriosSelecionado)}
        handleClose={handleCloseModal}
        customStyle={{ width: 400 }}
      >
        <S.TitleModal>
          {relatoriosSelecionado.ativoEmpresa
            ? 'Deseja DESATIVAR o relatório a essa empresa?'
            : 'Deseja ATIVAR o relatorio a essa empresa?'}
        </S.TitleModal>
      </ModalConfirmar>

      <ModalConfirmar
        open={openModalSincronizar}
        handleConfirm={() => sincronizarRelatorio(stateSincronizar)}
        handleClose={handleCloseModalSincronizar}
        customStyle={{ width: 600 }}
      >
        <S.TitleModalSincronizar>
          {'Relatório - ' + stateSincronizar.nome}
        </S.TitleModalSincronizar>
        <div style={{ padding: '5px' }}>
          <Input
            label="Group ID"
            id={'descricao'}
            value={stateSincronizar.powerBiGroupId}
            onChange={(e) => {
              setStateSincronizar((state) => ({
                ...state,
                powerBiGroupId: e.target.value,
              }))
            }}
            placeholder="Escreva o Group ID do Relatório"
            size="small"
          />
        </div>
        <div style={{ padding: '5px' }}>
          <Input
            label="Report ID"
            id={'descricao'}
            value={stateSincronizar.powerBiReportId}
            onChange={(e) => {
              setStateSincronizar((state) => ({
                ...state,
                powerBiReportId: e.target.value,
              }))
            }}
            placeholder="Escreva o Report ID do Relatório"
            size="small"
          />
        </div>
      </ModalConfirmar>
      <Titulo>
        <div>Relatórios</div>
        <Stack
          direction="row"
          spacing={2}
          justifyContent="end"
          marginTop="-26px"
        >
          <Button
            className="confirm-button"
            variant="contained"
            size="medium"
            endIcon={<AddCircleOutlineOutlined />}
            //sx={{ height: '43px' }}
            onClick={() => {
              buscarMobioh()
              handleOpen()
            }}
          >
            Vincular Relatórios
          </Button>
        </Stack>
      </Titulo>
      <S.ContainerForm>
        <Grid container padding={1}>
          <Grid xs={12}>
            <TableComponet
              headers={headers}
              data={relatoriosCadastrados}
              labelCaption="Nenhum Relatório Encontrado"
              labelTable="Lista de Usuários"
              status="ativoEmpresa"
              statusLabelTrue="Ativo"
              statusLabelFalse="Inativo"
              loading={loading}
              handlerEditarAction={(data) => {
                setStateSincronizarEditar(data)
              }}
              handlerDeletarAction={(data) => {
                setRelatoriosSelecionado(data)
              }}
            />
          </Grid>
        </Grid>
      </S.ContainerForm>
      <FullScreenDialog
        label={'Editar Relatório'}
        open={openEditar}
        handleClose={handleCloseEditar}
      >
        <S.Container>
          <Grid
            container
            padding={1}
            marginTop={2}
            spacing={2}
            marginBottom={1}
          >
            <Grid xs={5} item>
              <Input
                label="Group ID"
                id={'descricao'}
                value={stateSincronizarEditar.powerBiGroupId}
                onChange={(e) => {
                  setStateSincronizarEditar((state) => ({
                    ...state,
                    powerBiGroupId: e.target.value,
                  }))
                }}
                placeholder="Escreva o Group ID do Relatório"
                size="small"
              />
            </Grid>

            <Grid xs={5} item>
              <Input
                label="Report ID"
                id={'descricao'}
                value={stateSincronizarEditar.powerBiReportId}
                onChange={(e) => {
                  setStateSincronizarEditar((state) => ({
                    ...state,
                    powerBiReportId: e.target.value,
                  }))
                }}
                placeholder="Escreva o Report ID do Relatório"
                size="small"
              />
            </Grid>

            <Grid item xs={4}>
              <RadioButtonsGroup
                label="Situação do Vínculo"
                options={optionsStatus}
                labelOptions="label"
                valueOptions="value"
                defaultValue={stateSincronizarEditar.ativoEmpresa}
                onChange={(e) => {
                  setStateSincronizarEditar((state) => ({
                    ...state,
                    ativoEmpresa: e.target.value === 'true',
                  }))
                }}
              />
            </Grid>
          </Grid>
          <Stack direction="row" spacing={2} justifyContent="end">
            <Button
              className="confirm-button"
              variant="contained"
              size="large"
              type="submit"
              onClick={() => {
                sincronizarRelatorio(stateSincronizarEditar)
                handleCloseEditar()
              }}
              startIcon={
                loading && <CircularProgress color="inherit" size={25} />
              }
              disabled={loading}
            >
              Salvar
            </Button>
          </Stack>
        </S.Container>
      </FullScreenDialog>

      <FullScreenDialog
        label={'Relatórios Disponíveis'}
        open={open}
        handleClose={closeDialog}
      >
        <S.Container>
          {isSincronizar && (
            <>
              <Grid
                container
                padding={1}
                marginTop={2}
                spacing={2}
                marginBottom={1}
              >
                <Grid xs={5} item>
                  <Input
                    label="Pesquisar"
                    id={'descricao'}
                    value={stateRelatorio.descricao}
                    onChange={(e) => {
                      setStateRelatorio((state) => ({
                        ...state,
                        descricao: e.target.value,
                      }))
                    }}
                    placeholder="Escreva o Nome do Relatório"
                    size="small"
                  />
                </Grid>
                <Grid xs={3} item>
                  <InputSelect
                    value={stateRelatorio.menu}
                    setValue={(value) => {
                      setStateRelatorio((state) => ({
                        ...state,
                        menu: value,
                      }))
                    }}
                    shrink={true}
                    options={menus}
                    label="Menus"
                    id="id"
                    descricao="id"
                    size="small"
                    sx={{ padding: '5px 0px 0px', marginTop: '-5px' }}
                  />
                </Grid>

                <Grid xs={2} item>
                  <InputSelect
                    shrink={true}
                    value={stateRelatorio.subMenu}
                    setValue={(value) => {
                      setStateRelatorio((state) => ({
                        ...state,
                        subMenu: value,
                      }))
                    }}
                    options={subMenus}
                    label="Submenus"
                    id="id"
                    descricao="id"
                    size="small"
                    sx={{ padding: '5px 0px 0px', marginTop: '-5px' }}
                  />
                </Grid>

                <Grid xs={2} item display={'flex'}>
                  <Button
                    className="confirm-button"
                    variant="contained"
                    size="large"
                    startIcon={
                      loading ? (
                        <CircularProgress color="inherit" size={25} />
                      ) : (
                        <SearchOutlined />
                      )
                    }
                    disabled={loading}
                    sx={{ height: '39px' }}
                    onClick={buscarMobioh}
                  >
                    Buscar
                  </Button>

                  <Tooltip
                    title={'Limpar Filtros'}
                    placement="top"
                    style={{ fontSize: '20px !important' }}
                  >
                    <Button
                      className="confirm-button"
                      variant="text"
                      size="large"
                      startIcon={
                        loading ? (
                          <CircularProgress color="inherit" size={25} />
                        ) : (
                          <FilterAltOff />
                        )
                      }
                      disabled={loading}
                      sx={{ height: '39px', color: '#C4C4C4 !important' }}
                      onClick={() =>
                        setStateRelatorio((state) => ({
                          ...state,
                          descricao: '',
                          menu: null,
                          subMenu: null,
                        }))
                      }
                    ></Button>
                  </Tooltip>
                </Grid>
              </Grid>
              <Grid container padding={1}>
                <Grid xs={12}>
                  <TableComponet
                    headers={headersRelatoriosSemCadastro}
                    data={relatorios}
                    labelCaption="Nenhum Relatório Encontrado"
                    labelTable="Lista de Relatórios"
                    status="ativo"
                    statusLabelTrue="Ativo"
                    statusLabelFalse="Inativo"
                    loading={loading}
                    handlerDetalhesAction={(data) => {
                      setStateSincronizar({
                        ...data,
                        powerBiGroupId: powerBiGroupId,
                      })
                      setIsSincronizar(false)
                    }}
                    //selected={selected}
                    // selectedName="idRelatorio"
                    // handleClickSelected={handleClickSelected}
                    // handleSelectAllClick={handleSelectAllClick}
                    // ActionSelect={ButtonAction}
                  />
                </Grid>
              </Grid>
            </>
          )}

          {!isSincronizar && (
            <>
              <S.TitleModalSincronizar>
                {'Relatório - ' + stateSincronizar.nome}
              </S.TitleModalSincronizar>
              <Grid container padding={2} spacing={2}>
                <Grid xs={12} md={6} item>
                  <Input
                    label="Group ID"
                    id={'descricao'}
                    value={stateSincronizar.powerBiGroupId}
                    onChange={(e) => {
                      setStateSincronizar((state) => ({
                        ...state,
                        powerBiGroupId: e.target.value,
                      }))
                    }}
                    placeholder="Escreva o Group ID do Relatório"
                    size="small"
                  />
                </Grid>
                <Grid xs={12} md={6} item>
                  <Input
                    label="Report ID"
                    id={'descricao'}
                    value={stateSincronizar.powerBiReportId}
                    onChange={(e) => {
                      setStateSincronizar((state) => ({
                        ...state,
                        powerBiReportId: e.target.value,
                      }))
                    }}
                    placeholder="Escreva o Report ID do Relatório"
                    size="small"
                  />
                </Grid>
              </Grid>
              <Stack
                direction="row"
                spacing={2}
                justifyContent="end"
                marginTop={2}
              >
                <Button
                  className="confirm-button"
                  variant="contained"
                  size="medium"
                  color="warning"
                  startIcon={<ArrowBack />}
                  //sx={{ height: '43px' }}
                  onClick={() => setIsSincronizar(true)}
                >
                  Voltar
                </Button>
                <Button
                  className="confirm-button"
                  variant="contained"
                  size="large"
                  type="submit"
                  onClick={() => {
                    sincronizarRelatorio(stateSincronizar)
                    setIsSincronizar(true)
                  }}
                  startIcon={
                    loading && <CircularProgress color="inherit" size={25} />
                  }
                  disabled={loading}
                >
                  Salvar
                </Button>
              </Stack>
            </>
          )}
        </S.Container>
      </FullScreenDialog>
    </S.Container>
  )
}

const mapStateToProps = (store) => ({
  user: store.loginState.user.usuario,
})

export default connect(mapStateToProps)(SincronizarRelatorios)
