const primary = '#13294B'
const secondary = '#112443'
const theme = {
  title: 'light',
  font: {
    family: "'Roboto', sans-serif",
    light: 300,
    normal: 400,
    bold: 600,
    bolder: 700,
    color: '#000000BF',
    colorMenu: '#FFFF',
    sizes: {
      xsmall: '1.2rem',
      small: '1.4rem',
      medium: '1.6rem',
      large: '1.8rem',
      xlarge: '2.0rem',
      modalTitle: '2.4rem',
      xxlarge: '2.8rem',
    },
  },
  border: {
    radius: '0.4rem',
    color: '#DFE3E8',
  },
  colors: {
    primary: primary,
    secondary: secondary,
    green: primary,
    button: primary,
    buttonHover: secondary,
    buttonCancel: '#d32f2f',
    buttonCancelHover: '#e83535',
    transparentGreen: '#3e5b4f',
    listText: '#6A737D',
    darkGray: '#7D7D7D',
    yellow: '#FFAB00',
    white: '#ffffff',
    black: '#030517',
    blackReverse: '#000000',
    lightGray: '#DFE3E8',
    gray: '#D3D4D5',
    darkGrayPlaceholder: '#6A737D',
    label: '#000723',
    loginLabel: '#030517',
    borderGray: '#DFE3E8',
  },
  mobioh: {
    primary: primary,
    secundary: secondary,
  },
  menuLeft: {
    hover: primary,
    color: '#83AEBD',
    bg: primary,
    active: '#FFF',
  },
  bg: {
    primary: primary,
    secundary: '#ffffff',
    tertiary: '#FFFFFF',

    custom: '#F5F5F5',
  },
  hover: {
    backgroundColor: '#DFE3E8',
  },
}

export default theme
