import * as React from "react";
import { useContext } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { setPowerbi } from "../../redux/actions";
import { Box, Drawer, Grid, Toolbar } from "@mui/material";
import Color from "../../utilities/enums/Color";
import Typography from "@mui/material/Typography";
import { api, Utils } from "../../utilities";
import AddchartIcon from "@mui/icons-material/Addchart";
import { useHistory } from "react-router-dom";
import CloseIcon from "@mui/icons-material/Close";
import { IPInfoContext } from "ip-info-react";

const styles = {
  menus: {
    fontSize: "16px",
    paddingBottom: "5px",
    textAlign: "center",
    cursor: "pointer",
    "&:hover": { borderBottom: `3px solid #FFF` }
  }
};

function SubMenusEmpresa(props) {
  const [conteudoSubMenu, setConteudoSubMenu] = React.useState([]);
  const [mobileSubMenu, setMobileSubMenu] = React.useState(false);
  const history = useHistory();
  const userInfo = useContext(IPInfoContext);

  function handlerSetPowerBi(rota, menu, powerbi) {
    props.setPowerbi(menu, powerbi);
    history.push(rota);
  }

  function logAcessouRelatorio(dados) {
    let newObj = {

      idUsuario: usuario.idUsuario,
      idLogLogin: usuario.idLogLogin, //retornado no login do usuario
      idEmpresa: empresa.idEmpresa, //enviar atual
      powerBiGroupId: dados.powerBiGroupId, //dados relatorio
      powerBiReportId: dados.powerBiReportId, //dados relatorio
      tipo: "ACESSOU_RELATORIO",
      ip: userInfo.ip,
      localizacao: userInfo.city + " - " + userInfo.country_name,
      aparelho: "",
      navegador: ""
    };
    api
      .post("/usuario/log", newObj)
      .then(({ data }) => {
        if (data.status) {
          console.log(data.mensagem);
        } else {
          console.log(data.mensagem);
        }
      })
      .catch((error) => {
        console.log("Houve um problema ao enviar os logs do usuário!");
      });
  }

  function handlerClickSubMenus(sub) {
    logAcessouRelatorio(sub);
    handlerSetPowerBi(`/empresas/${sub.menu}`, true, {
      accessToken: props.accessToken,
      powerBiGroupId: sub.powerBiGroupId,
      powerBiReportId: sub.powerBiReportId,
      nomeMenu: sub.descricao
    });

    handlerClose();
    setMobileSubMenu(false);
    setConteudoSubMenu(false);
    setOpenSubMenu(false);
  }

  function rotaPadraoSemMenuBI(rota, nome) {
    props.setPowerbi(false, {
      accessToken: '',
      powerBiGroupId: '',
      powerBiReportId: '',
      nomeMenu: nome,
    })
    history.push(rota)
  }

  const {
    open,
    handlerClose,
    handleOpen,
    empresa,
    openSubMenu,
    setOpenSubMenu,
    tipo,
    usuario
  } = props;

  function handlerCloseSubMenu() {
    setOpenSubMenu(false);
  }

  React.useEffect(() => {
    if (!open) {
      setConteudoSubMenu(false);
      setOpenSubMenu(false);
    }
  }, [open]);

  function groupByKey(array, key) {
    return array.reduce((hash, obj) => {
      if (obj[key] === undefined) return hash;
      return Object.assign(hash, {
        [obj[key]]: (hash[obj[key]] || []).concat(obj)
      });
    }, {});
  }

  function handleMenuMobile(mobile, menu) {
    let isMobile = window.innerWidth;

    if (menu.length === 1) {
      handlerClickSubMenus(menu[0]);
      setMobileSubMenu(false);
      return;
    }

    if (isMobile <= 750) {
      setMobileSubMenu(true);
    } else {
      setMobileSubMenu(false);
    }
  }

  function createListaMenu(empresa) {
    const menu = groupByKey(empresa, "menu");

    return Object.keys(menu).map((item) => {
      return (
        <Grid
          item
          xs={4}
          sm={2}
          md={2}
          onMouseEnter={() => {
            if (menu[item].length > 1) {
              setOpenSubMenu(true);
              setConteudoSubMenu(menu[item]);
            } else {
              handlerCloseSubMenu();
            }
          }}
        >
          <Typography
            onClick={() => handleMenuMobile(true, menu[item])}
            sx={styles.menus}
          >
            {item}
          </Typography>
        </Grid>
      );
    });
  }

  function createSubMenu(conteudo) {
    if (conteudo.length > 1) {
      return conteudo.map((sub) => (
        <Grid
          item
          sx={{
            width: 330,
            maxHeight: 100,
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            padding: "0 15px !important",
            marginTop: { xs: "25px" },
            "&:hover": {
              backgroundColor: Color.EMPRESA.SECUNDARIA,
              borderRadius: "7px",
              cursor: "pointer"
            }
          }}
          onClick={() => handlerClickSubMenus(sub)}
        >
          <AddchartIcon sx={{ fontSize: 35, marginRight: "10px" }} />
          <div>
            <Typography
              variant="h5"
              component="h5"
              marginBottom={1}
              marginLeft={1}
            >
              {sub.subMenu}
            </Typography>
            <Typography marginLeft={1}>{sub.descricao}</Typography>
          </div>
        </Grid>
      ));
    }
  }

  function MenuAdmin() {
    if (tipo === "ADMIN") {
      return (
        <>
          <Grid item xs={4} sm={2} md={2}>
            <Typography
              sx={styles.menus}
              onClick={() => {
                handlerClose();
                rotaPadraoSemMenuBI("/admin/logs", "Logs")
              }}
            >
              Logs
            </Typography>
          </Grid>
        </>
      );
    }

    if (tipo === "ADMIN_MOBIOH") {
      return (
        <>
          <Grid item xs={4} sm={2} md={2}>
            <Typography
              sx={styles.menus}
              onClick={() => {
                handlerClose();
                rotaPadraoSemMenuBI("/relatorios", "Relatórios");
              }}
            >
              Relatórios
            </Typography>
          </Grid>

          <Grid item xs={4} sm={2} md={2}>
            <Typography
              sx={styles.menus}
              onClick={() => {
                handlerClose();
                rotaPadraoSemMenuBI("/usuarios", "Usuários");
              }}
            >
              Usuários
            </Typography>
          </Grid>
          <Grid item xs={4} sm={2} md={2}>
            <Typography
              sx={styles.menus}
              onClick={() => {
                handlerClose();
                rotaPadraoSemMenuBI("/admin/empresas", "Empresas");
              }}
            >
              Empresas
            </Typography>
          </Grid>
          <Grid item xs={4} sm={2} md={2}>
            <Typography
              sx={styles.menus}
              onClick={() => {
                handlerClose();
                rotaPadraoSemMenuBI("/admin/perfis", "Perfis");
              }}
            >
              Perfis
            </Typography>
          </Grid>
          <Grid item xs={4} sm={2} md={2}>
            <Typography
              sx={styles.menus}
              onClick={() => {
                handlerClose();
                rotaPadraoSemMenuBI("/admin/logs", "Logs");
              }}
            >
              Logs
            </Typography>
          </Grid>
        </>
      );
    }

    if (tipo === "USUARIO") {
      return null;
    }
  }

  window.addEventListener(
    "orientationchange",
    function() {
      let isMobile = this.window.innerWidth;

      if (isMobile <= 750) {
        setMobileSubMenu(true);
      } else {
        setMobileSubMenu(false);
      }

      handlerClose();
      handlerCloseSubMenu();
    },
    false
  );

  return (
    <>
      <Drawer
        anchor="top"
        open={open}
        onClose={handlerClose}
        variant="persistent"
        sx={{
          paddingTop: 10,
          zIndex: 999
        }}
      >
        <Toolbar />
        <Box
          sx={{
            backgroundColor: Color.EMPRESA.PRIMARIA,
            padding: "5px 0 0px",
            color: "#FFF",
            width: "100%",
            display: "flex"
          }}
          justifyContent="center"
        >
          <Box
            sx={{
              display: {
                xs: mobileSubMenu && openSubMenu ? "none" : "flex",
                sm: "flex",
                md: "flex"
              },
              width: "100%",
              minHeight: "70px",
              paddingTop: 3,
              border: "1px solid black"
            }}
            justifyContent="center"
          >
            <Grid
              container
              spacing={{ xs: 2, md: 3 }}
              columns={{ xs: 4, sm: 10, md: 12 }}
              justifyContent="center"
              maxWidth="1000px"
            >
              <Grid item xs={4} sm={2} md={2}>
                <Typography
                  sx={styles.menus}
                  onClick={() => {
                    handlerClose();
                    rotaPadraoSemMenuBI("/empresas", "Minhas Empresas");
                  }}
                >
                  Minhas Empresas
                </Typography>
              </Grid>
              {MenuAdmin()}

              {Utils.isNotObjectEmpty(empresa) && createListaMenu(empresa)}
            </Grid>
          </Box>
        </Box>
      </Drawer>
      {open && (
        <Drawer
          anchor="top"
          open={openSubMenu}
          onClose={handlerCloseSubMenu}
          sx={{ paddingTop: 10, zIndex: 998 }}
        >
          {!mobileSubMenu && <Toolbar />}
          <Toolbar />
          <Box
            sx={{
              background: Color.EMPRESA.PRIMARIA,
              padding: "20px 0px 15px",
              color: "#FFF",
              width: "100%",
              display: "flex",
              marginTop: {
                xs: 0,
                sm: 0,
                md: 0
              }
            }}
            minHeight={"300px"}
            justifyContent="center"
            onMouseLeave={() => (!mobileSubMenu ? handlerCloseSubMenu : {})}
          >
            {mobileSubMenu && (
              <CloseIcon
                sx={{
                  color: "#FF",
                  position: "absolute",
                  fontSize: "32px",
                  zIndex: 9999,
                  right: "10px",
                  cursor: "pointer"
                }}
                onClick={() => {
                  setMobileSubMenu(false);
                  handleOpen();
                  handlerCloseSubMenu();
                }}
              />
            )}
            <Grid
              container
              spacing={2}
              columns={{ xs: 4, sm: 10, md: 12 }}
              sx={{ padding: 3 }}
              justifyContent={mobileSubMenu ? "center" : "initial"}
            >
              {conteudoSubMenu && createSubMenu(conteudoSubMenu)}
            </Grid>
          </Box>
        </Drawer>
      )}
    </>
  );
}

const mapStateToProps = (store) => ({
  user: store.loginState.user,
  empresa: store.empresaReducer.empresa,
  accessToken: store.loginState.user.accessToken,
  usuario: store.loginState.user.usuario
});
const mapDispatchToProps = (dispatch) =>
  bindActionCreators({ setPowerbi }, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(SubMenusEmpresa);
