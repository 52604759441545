import { Utils } from "../../utilities";
import { POWERBI } from "../actions";
import { TOKEN_POWERBI, MOBILE_POWERBI } from "../actions/ActionTypes";

const initialState = sessionStorage.getItem("powerbi") ? JSON.parse(sessionStorage.getItem("powerbi")) : {
  menu: false,
  powerbi: {
    accessToken: "",
    powerBiGroupId: "",
    powerBiReportId: "",
    nomeMenu: "",
  },
  mobile: Utils.isViewMobile()
};

export const powerbiReducer = (state = initialState, action) => {
  switch (action.type) {
    case POWERBI:
      sessionStorage.setItem("powerbi", JSON.stringify({
        ...state,
        menu: action.menu,
        powerbi: action.powerbi,
      }));
      return {
        ...state,
        menu: action.menu,
        powerbi: action.powerbi,
      };
    case TOKEN_POWERBI:
      sessionStorage.setItem("powerbi", JSON.stringify({
        ...state,
        menu: true,
        powerbi: {
          ...state.powerbi,
          accessToken: action.accessToken,
        },
      }));
      return {
        ...state,
        menu: true,
        powerbi: {
          ...state.powerbi,
          accessToken: action.accessToken,
        },
      };
    case MOBILE_POWERBI:
      sessionStorage.setItem("powerbi", JSON.stringify({
        ...state,
        menu: true,
        mobile: action.mobile
      }));
      return {
        ...state,
        menu: true,
        mobile: action.mobile
      };
    default:
      return state;
  }
};
