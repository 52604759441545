import React from 'react'
import { Switch, withRouter, Route, Redirect } from 'react-router-dom'

import PrivateRoute from './PrivateRoute'
import Login from '../view/login/Login'
import Empresas from '../view/empresas/Empresas'
import PowerBi from '../view/powerbi/PowerBi'
import Perfil from '../view/Perfil/Perfil'
import UsuariosAdmin from '../view/UsuariosAdmin/UsuariosAdmin'
import RelatorioAdmin from '../view/RelatorioAdmin/RelatorioAdmin'
import EmpresasAdmin from '../view/EmpresasAdmin/EmpresasAdmin'
import Sincronizar from '../view/EmpresasAdmin/Sincronizar'
import PerfisAdmin from '../view/PerfisAdmin/PerfisAdmin'
import SincronizarPerfil from '../view/PerfisAdmin/SincronizarPerfil'
import LogsAdmin from '../view/Logs/LogsAdmin'
import MudarSenhar from '../view/login/MudarSenhar'

function Routes() {
  return (
    <Switch>
      <Redirect from="/" exact to="/inicio" />

      <Route component={Login} path="/login" exact />
      <Route component={MudarSenhar} path="/alterarsenha/:tokenUser" exact />
      <PrivateRoute Component={UsuariosAdmin} path="/usuarios" exact />
      <PrivateRoute Component={RelatorioAdmin} path="/relatorios" exact />
      <PrivateRoute Component={EmpresasAdmin} path="/admin/empresas" exact />
      <PrivateRoute Component={PerfisAdmin} path="/admin/perfis" exact />
      <PrivateRoute Component={LogsAdmin} path="/admin/logs" exact />
      <PrivateRoute
        Component={SincronizarPerfil}
        path="/admin/perfil/sincronizar"
        exact
      />
      <PrivateRoute
        Component={Sincronizar}
        path="/admin/empresas/sincronizar"
        exact
      />
      <PrivateRoute Component={Empresas} path="/empresas" exact />
      <PrivateRoute Component={Perfil} path="/perfil" exact />
      <PrivateRoute Component={PowerBi} path="/empresas/:reportId" exact />
      <PrivateRoute Component={Empresas} path="/*" />
    </Switch>
  )
}

export default withRouter(Routes)
