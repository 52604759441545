import BreadParam from '../../components/breadcrumb/BreadParam'

export default class PathRoute {
  static PATH_DEFAULT = '/producao/'

  static PATH = {
    LOGIN: '/login',
    INICIO: '/producao',

    PRODUTOS: PathRoute.getPath('produtos'),
    PRODUTOS_LISTAR: PathRoute.getPath('produtos-listar'),
    PRODUTOS_GRUPOS: PathRoute.getPath('grupos'),
    PRODUTOS_SINCRONIZAR: PathRoute.getPath('produtos-sincronizar'),

    FILIAL: PathRoute.getPath('filial'),
    CONFIG: PathRoute.getPath('configuracao'),
    REQUISICOES: PathRoute.getPath('requisicoes'),
    GESTAO_REQUISICOES: PathRoute.getPath('gestao-requisicao'),
    SEPARAR_REQUISICOES: PathRoute.getPath('separar-requisicao'),

    CONTA: PathRoute.getPath('perfil'),

    USER: PathRoute.getPath('usuario'),
  }

  static getPath(path) {
    return PathRoute.PATH_DEFAULT.concat(path)
  }

  static valueBreadcrumbs(value) {
    switch (value) {
      case 'producao':
        return 'Início'

      case 'produtos-listar':
        return 'Produtos'

      case 'produtos-sincronizar':
        return 'Sincronizar Produtos'

      case 'configuracao':
        return 'Configurações'

      case 'filial':
        return 'Filiais'

      case 'usuario':
        return 'Usuários'

      case 'grupos':
        return 'Grupos'

      case 'requisicoes':
        return 'Requisições'

      case 'gestao-requisicao':
        return 'Gestão de Requisição'

      case 'separar-requisicao':
        return 'Separação'

        case 'perfil':
          return 'Perfil'

      default:
        return value
    }
  }

  static getBreads(route) {
    let paths = String(route).split('/').slice(1)
    let breads = []
    let pathConcat = ''
    paths.forEach((path, index) => {
      pathConcat = pathConcat.concat('/' + path)

      let pathName = this.valueBreadcrumbs(path)

      breads.push(new BreadParam(pathConcat, pathName, false))
    })
    return breads
  }
}
