export default class Color {
  static TEXT = {
    SUCCESS: "text-success",
    DANGER: "text-danger",
    INFO: "text-info",
    PRIMARY: "text-primary",
    WARNING: "text-warning",
    WHITE: "text-white",
    DEFAULT: "text-default",
    BEGE: "text-bege",
    SECONDARY: "text-secondary"
  };

  static EMPRESA = {
    PRIMARIA: "var(--cor-primaria)",
    SECUNDARIA: "var(--cor-secundaria)",
    TERCIARIA: "var(--cor-terciaria)",
    MENU_SECUNDARIO: "var(--cor-menu-secundario)",
    BG_BANNER_LOGIN: "var(--cor-bg-banner-login)",
  }

  static NODE = {
    SUCCESS: "success",
    DANGER: "danger",
    INFO: "info",
    PRIMARY: "primary",
    WARNING: "warning",
    WHITE: "white",
    DEFAULT: "default",
    BEGE: "bege",
    SECONDARY: "secondary",
    LIGHT: "light"
  };

  static BACKGROUND = {
    DANGER: "bg-danger",
    INFO: "bg-info",
    PRIMARY: "bg-primary",
    WARNING: "bg-warning",
    SUCCESS: "bg-success",
    DEFAULT: "bg-default",
    BEGE: "bg-bege",
    SECONDARY: "bg-secondary"
  };

  static RGBA = {
    WHITE: "rgba(0, 0, 0, 0)",
    ROSA_CLARO: "rgba(255, 99, 132, 0.2)",
    ROSA_MEDIO: "rgba(255, 99, 132, 0.5)",
    AZUL_CLARO: "rgba(54, 162, 235, 0.2)",
    AZUL_MEDIO: "rgba(54, 162, 235, 0.5)",
    ROSA: "rgba(255, 99, 132, 1)",
    AZUL: "rgba(54, 162, 235, 1)",
    LARANJA: "rgba(255, 159, 64, 1)",
    LARANJA_CLARO: "rgba(255, 159, 64, 0.2)",
    LARANJA_MEDIO: "rgba(255, 159, 64, 0.5)",
    AMARELO_CLARO: "rgba(255, 205, 86, 0.2)",
    AMARELO_MEDIO: "rgba(255, 205, 86, 0.5)",
    AMARELO: "rgba(255, 205, 86, 1)",
    ROXO_CLARO: "rgba(153, 102, 255, 0.2)",
    ROXO_MEDIO: "rgba(153, 102, 255, 0.5)",
    ROXO: "rgba(153, 102, 255, 1)",
    CINZA_CLARO: "rgba(201, 203, 207, 0.2)",
    CINZA_MEDIO: "rgba(201, 203, 207, 0.5)",
    CINZA: "rgba(201, 203, 207, 1)",
    VERDE: "rgba(60, 186, 159, 1)",
    VERDE_CLARO: "rgba(60, 186, 159, 0.2)",
    VERDE_MEDIO: "rgba(60, 186, 159, 0.5)",
    AZUL_PISCINA_MEDIO: "rgba(80, 216, 207, 0.5)",
    VERMELHO_MEDIO: "rgba(216, 80, 80, 0.5)"

  };

  static CHART_COLOR = [
    Color.RGBA.AZUL_MEDIO,
    Color.RGBA.VERDE_MEDIO,
    Color.RGBA.ROXO_MEDIO,
    Color.RGBA.AMARELO_MEDIO,
    Color.RGBA.ROSA_MEDIO,
    Color.RGBA.LARANJA_MEDIO,
    Color.RGBA.VERMELHO_MEDIO,
    Color.RGBA.AZUL_PISCINA_MEDIO,
    Color.RGBA.AZUL_MEDIO,
    Color.RGBA.VERDE_MEDIO,
    Color.RGBA.ROXO_MEDIO,
    Color.RGBA.AMARELO_MEDIO,
    Color.RGBA.ROSA_MEDIO,
    Color.RGBA.LARANJA_MEDIO,
    Color.RGBA.VERMELHO_MEDIO,
    Color.RGBA.AZUL_PISCINA_MEDIO,
    Color.RGBA.AZUL_MEDIO,
    Color.RGBA.VERDE_MEDIO,
    Color.RGBA.ROXO_MEDIO,
    Color.RGBA.AMARELO_MEDIO,
    Color.RGBA.ROSA_MEDIO,
    Color.RGBA.LARANJA_MEDIO,
    Color.RGBA.VERMELHO_MEDIO,
    Color.RGBA.AZUL_PISCINA_MEDIO
  ];
}
