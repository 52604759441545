import React from 'react'
import styled from 'styled-components'

const Title = styled.h3`
  font-size: 22px;
  font-weight: bolder;

  width: 100%;
  margin: 10px 5px 0;

  border: 1px solid #e3e6ed;
  border-top-right-radius: 8px;
  border-top-left-radius: 8px;

  background-color: ${(props) => props.theme.bg.custom};

  padding: 15px;
`

export default function Titulo({ label, children, ...props }) {
  return (
    <Title {...props}>
      {label}
      {children}
    </Title>
  )
}
