import { LOGIN, TOKEN_POWERBI } from "../actions";

const initialState = {
  loggedIn: false,
  user: JSON.parse(sessionStorage.getItem("auth")),
};

export const loginReducer = (state = initialState, action) => {
  switch (action.type) {
    case LOGIN:
      return {
        ...state,
        loggedIn: action.loggedIn,
        user: action.user,
      };
    case TOKEN_POWERBI:
      sessionStorage.setItem(
        "auth",
        JSON.stringify({
          ...state.user,
          accessToken: action.accessToken,
        })
      );
      return {
        loggedIn: true,
        user: {
          ...state.user,
          accessToken: action.accessToken,
        }
      };
    default:
      return state;
  }
};
