import { api } from '../utilities'

class UserService {
  static async authentication(obj) {
    let res = null

    await api.post('/usuario/login', obj).then((response) => {
      res = response.data
    })

    return res
  }

  static async atualizarUsuarioPerfil(obj) {
    let res = null

    await api.put('/usuario', obj).then((response) => {
      res = response.data
    })

    return res
  }

  static async criarUsuario(obj) {
    let res = null

    await api.post('/usuario', obj).then((response) => {
      res = response.data
    })

    return res
  }

  static async resetarSenha(obj) {
    let res = null

    await api.put('/usuario/resetsenha', obj).then((response) => {
      res = response.data
    })

    return res
  }

  static async decode(code) {
    let res = null

    await api.get(`/usuario/resetsenha/decode/${code}`).then((response) => {
      res = response.data
    })

    return res
  }

  static async buscarMobioh(obj) {
    let res = null

    await api.post('/usuario/admin/mobioh/filtro', obj).then((response) => {
      res = response.data
    })

    return res
  }

  static async inativarUsuario(obj) {
    let res = null

    await api.delete('/usuario', obj).then((response) => {
      res = response.data
    })

    return res
  }

  static async sincronizarEmpresa(obj) {
    let res = null

    await api.put('/usuario/sincronizar/empresas', obj).then((response) => {
      res = response.data
    })

    return res
  }
}

export default UserService
