import React from 'react'
import * as MuiIcons from '@mui/icons-material'
import { Box, Grid } from '@mui/material'
import Item from '../Item/Item'

export default ({ pesquisar='', handleSelectedIcone }) => {
  const iconNames = Object.keys(MuiIcons).filter((iconName) =>
    iconName.endsWith('Rounded'),
  )

  const [listaIcones, setListaIcones] = React.useState([...iconNames])
  const [listaCompleta] = React.useState([...iconNames])

  React.useEffect(() => {
    if(pesquisar === '') {
      setListaIcones([...iconNames])
    } else {
      let newList = listaCompleta.filter( item => item.toLocaleLowerCase().indexOf(pesquisar.toLowerCase())  > -1)

      setListaIcones(newList)
    }
  }, [pesquisar])

  return (
    <Box>
      <Grid container padding={1} spacing={2}>
        <Grid
          padding={2}
          item
          xs={12}
          display={'flex'}
          flexDirection={'row'}
          flexWrap={'wrap'}
       //   justifyContent={'space-around'}
          maxHeight={'50vh'}
          //   height={'100%'}
          overflow="auto"
          marginTop={'30px'}
        >
          {listaIcones.map((iconName, index) => (
            <div
              style={{
                display: 'flex',
                padding: '10px',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center',
                marginBottom: '15px',
              }}
            >
              <Item key={index} onClick={() => handleSelectedIcone(iconName)}>
                {React.createElement(MuiIcons[iconName], {
                  sx: { fontSize: '35px' },
                })}
              </Item>
              <span style={{ fontSize: '11px', marginTop: '5px' }}>
                {iconName.substring(0, 10)}
              </span>
            </div>
          ))}
        </Grid>
      </Grid>
    </Box>
  )
}
