import styled, { css } from 'styled-components'
import { Avatar } from '../../Avatar'

export const Container = styled.div`
  display: flex;
  header {
   // background-color: ${(props) => props.theme.bg.primary} !important;
  }

  //SIDER BAR STYLES
  ${({ theme }) => css`
    .MuiDrawer-paper {
      background-color: ${'var(--cor-primaria)'};
    }

    //ICONE DO MENU
    .MuiDrawer-paper svg,
    .MuiDrawer-paper {
      color: ${'var(--cor-secundaria)'};
      font-size: 16px;
    }

    .MuiDivider-root {
      background-color: ${'var(--cor-secundaria)'};
      width: 90%;
      margin-left: 5%;
      margin-top: 10px;
      margin-bottom: 10px;
    }

    .MuiTypography-root {
      font-size: 16px;
    }

    .MuiListItemIcon-root {
      min-width: 32px;
      margin-left: 15px;
    }

    //SIDE BAR LISTA LI STYLES
    .MuiList-root li {
      display: flex;
      flex-direction: column;
      padding: 0 8px;
    }
    .MuiCollapse-root .MuiListItemText-root {
      padding-left: 48px;
    }
    .MuiList-root li a,
    .MuiListItemButton-root,
    .MuiCollapse-root {
      width: 95%;
      padding: 4px 0px;
      border-radius: 9px;
      font-size: 12px;
    }

    .MuiList-root li a:hover,
    .MuiListItemButton-root:hover,
    .MuiCollapse-root:hover, .MuiListItemButton-root:hover > .MuiDrawer-paper svg  {
      background-color: var(--cor-secundaria) !important;
      color: var(--cor-primaria) !important;
    }
 
    .Mui-selected {
   //   background-color: #474d5b  !important;
      width: auto;
      border-radius: 3px;
    }

    a.links {
      //color: ${theme.menuLeft.color};
      color: #FFF
    }
    .icon-menu {
      color: red !important;
    }

    a.linksPrimeiro {
      color: ${'var(--cor-secundaria)'};
    }

    a.links:hover {
      color: ${'var(--cor-primaria)'};
      background-color: ${'var(--cor-secundaria)'};
    }

    .MuiTabs-flexContainer .Mui-selected {
   //   background-color: transparent !important;
      z-index: 999;
      color: #000;
    }

    .MuiTabs-flexContainer {
      display: flex;
      border-bottom: 1px solid #e3e6ed;
      border-right: none;
      border-left: none;
    }

    .MuiTab-root {
      font-size: 18px;
      padding: 25px;
    }

    .MuiTabs-indicator {
      height: 4px;
    }
  `}
`

export const BoxAvatar = styled.div`
  display: flex;
  height: 100%;
  align-items: center;
  justify-content: center;
  font-size: 16px;
`

export const UserAvatar = styled(Avatar)`
  border: 1px solid ${(props) => props.theme.border.color};

  color: ${(props) => props.theme.border.color};
`
