import * as React from 'react'
import InputLabel from '@mui/material/InputLabel'
import MenuItem from '@mui/material/MenuItem'
import FormControl from '@mui/material/FormControl'
import Select from '@mui/material/Select'
import styled from 'styled-components'

const Container = styled.h3`
  width: 100%;
`

const sxSelect = { width: '100%' }

function InputSelect({
  value,
  setValue,
  options,
  label,
  sx,
  handleSelect,
  id,
  descricao,
  codigo,
  placeholder,
  shrink = false,
  ...props
}) {
  const handleChange = (event) => {
    if (handleSelect) {
      handleSelect(event.target.value)
    }
    setValue(event.target.value)
  }

  return (
    <Container>
      <FormControl sx={{ ...sxSelect, ...sx }}>
        <InputLabel id="select-label">
          {label}
        </InputLabel>
        <Select
          labelId="select-label"
          id="select"
          value={value}
          label={label}
          onChange={handleChange}
          placeholder={placeholder}
          {...props}
        >
          <MenuItem value={null}>
            <em>Selecione</em>
          </MenuItem>
          {options.map((item, index) => {
            return (
              <MenuItem id={`${index}`} value={item[id]}>
                {codigo && item[codigo] + ' - '}
                {item[descricao]}
              </MenuItem>
            )
          })}
        </Select>
      </FormControl>
    </Container>
  )
}

export default InputSelect
