import React, { useState } from 'react'
import { Avatar, Box, Button, CircularProgress, Grid } from '@mui/material'
import Input from '../Input/Input'
//import Button from '@material-ui/core/Button';

function ImageUploader({
  atualizarImagem,
  setImage,
  isConfirm = true,
  img = null,
  loading = false,
}) {
  const [imageBase64, setImageBase64] = useState(img)

  const handleImageUpload = (event) => {
    const file = event.target.files[0]

    if (file) {
      const reader = new FileReader()

      reader.onload = (e) => {
        const base64 = e.target.result
        setImageBase64(base64)
        if (setImage) {
          setImage(base64)
        }
      }

      reader.readAsDataURL(file)
    }
  }

  const handleImageSubmit = () => {
    if (atualizarImagem) {
      atualizarImagem(imageBase64)
    }
  }

  return (
    <Box>
      <Grid container spacing={2} flexWrap={'wrap'} display={'flex'}>
        <Grid item xs={12} md={6}>
          <Input
            accept="image/*"
            type="file"
            id="image-upload"
            onChange={handleImageUpload}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          {imageBase64 && (
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <Avatar
                alt={'Imagem carregada'}
                src={imageBase64}
                sx={{ width: 55, height: 55, marginRight: '10px' }}
                className="border avatar-card"
              />

              {isConfirm && (
                <Button
                  variant="contained"
                  color="primary"
                  onClick={handleImageSubmit}
                  sx={{ marginLeft: 2 }}
                  startIcon={
                    loading && <CircularProgress color="inherit" size={25} />
                  }
                  disabled={loading}
                >
                  Enviar Imagem
                </Button>
              )}
            </div>
          )}
        </Grid>
      </Grid>
    </Box>
  )
}

export default ImageUploader
