import React from 'react'
import { useHistory, useParams } from 'react-router-dom'
import {
  Button,
  CircularProgress,
  Grid,
  IconButton,
  InputAdornment,
} from '@mui/material'
import logo from '../../assets/images/logomobioh.png'
import logoInovata from '../../assets/images/INOVATTA_LOGO.png'
import UserService from '../../service/UserService'
import { toast, ToastContainer } from 'react-toastify'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { logar } from '../../redux/actions'
import * as S from './styles'
import Color from '../../utilities/enums/Color'
import { Lock, Visibility, VisibilityOff } from '@mui/icons-material'
import { BannerLogin } from '../../components'
import CryptoJS from 'crypto-js'

function MudarSenha(props) {
  const [showPassword, setShowPassword] = React.useState(false)
  const [loading, setLoading] = React.useState(false)
  const [isPassword, setIsPassword] = React.useState(false)
  const [password, setPassword] = React.useState('')
  const [confirmarPassword, setConfirmarPassword] = React.useState('')
  const [idUser, setIdUser] = React.useState('')
  const [isErrorToken, setIsErrorToken] = React.useState(false)

  const history = useHistory()

  const { tokenUser } = useParams()

  React.useEffect(() => {
    let isMounted = true // Sinalizador

    const decodeUser = async () => {
      setLoading(true)
      try {
        const data = await UserService.decode(tokenUser)
        if (isMounted) {
          setLoading(false)
          console.log(data)
          setIdUser(data.idUsuario)
          // Processar a resposta aqui
        }
      } catch (error) {
        if (isMounted) {
          setLoading(false)
          setIsErrorToken(true)

          if (error.message === 'Network Error') {
            toast.error('Falha na conexão com o servidor: ' + error.message, {
              theme: 'colored',
            })
            console.log('Falha na conexão com o servidor')
          } else {
            toast.error(
              'Houve um problema com a URL do usuário! contate o suporte!',
              {
                theme: 'colored',
              },
            )
          }
        }
      }
    }

    decodeUser()

    return () => {
      isMounted = false // Limpar sinalizador ao desmontar
    }
  }, [tokenUser])

  const handleClickShowPassword = () => setShowPassword((show) => !show)
  const handleMouseDownPassword = (event) => {
    event.preventDefault()
  }

  async function handleLogin() {
    let senha = password != null ? CryptoJS.MD5(password).toString() : null

    if (password.trim() === '') {
      setIsPassword(true)
      return
    }

    if (confirmarPassword !== password) {
      return
    }

    let usuario = {
      idUsuario: idUser,
      senha,
      idUsuarioAtualizacao: idUser,
    }

    setLoading(true)
    await UserService.atualizarUsuarioPerfil(usuario)
      .then(({ status, mensagem }) => {
        setLoading(false)
        if (status) {
          toast.success(mensagem, {
            theme: 'colored',
          })
          history.push('/login')
        } else {
          toast.error(mensagem, {
            theme: 'colored',
          })
        }
      })
      .catch((error) => {
        setLoading(false)
        if (error.message === 'Network Error') {
          toast.error('Falha na conexão com o servidor' + error.message, {
            theme: 'colored',
          })
          console.log('Falha na conexão com o servidor')
        } else {
          setLoading(false)
          toast.error('Houve um problema na atualização da senha do Usuário!', {
            theme: 'colored',
          })
        }
      })
  }

  function isEmpresaInovatta() {
    let isEmpresaInovatta = false
    let origin = window.location.origin
    if (origin === 'https://bi.inovattaconsulting.com') {
      isEmpresaInovatta = true
    }
    return isEmpresaInovatta
  }

  function logoInovataImg() {
    if (isEmpresaInovatta()) {
      document.title = 'Inovatta - BI'
      return logoInovata
    }
    return logo
  }

  function descricaoProduto() {
    let descricaoProduto = 'Mobioh | Bi'
    if (isEmpresaInovatta()) {
      descricaoProduto = 'Inovatta - BI'
    }
    return descricaoProduto
  }

  function descricaoRodape() {
    let descricaoRodape = 'Copyright © 2023 Mobioh Sistemas. v.5.1.3'
    if (isEmpresaInovatta()) {
      descricaoRodape = 'Copyright © 2023 Inovatta Consulting. v.5.1.3'
    }
    return descricaoRodape
  }

  return (
    <S.Container>
      <ToastContainer />
      <Grid container minWidth={'100vw'} minHeight={'100vh'} bgcolor={'#FFF'}>
        <Grid
          xs={12}
          md={6}
          display={'flex'}
          justifyContent={'center'}
          alignItems={'center'}
        >
          <S.BoxLogin>
            <S.Image src={logoInovataImg()} />

            <S.Title>{descricaoProduto()}</S.Title>
            <S.Divider />

            {isErrorToken && (
              <Button
                className="confirm-button"
                variant="text"
                size="large"
                color="warning"
                // endIcon={
                //   loading && <CircularProgress color="inherit" size={25} />
                // }
                disabled={loading}
                sx={{
                  height: '20px',
                  fontWeight: 'bold',
                  width: '95%',
                  marginTop: '45px',
                  marginBottom: '10px',
                  textTransform: 'initial',
                  cursor: 'default',
                }}
                disable
                //  onClick={() => props.setIsRecuperarSenha(false)}
              >
                Houve um problema com a URL do usuário! contate o suporte!
              </Button>
            )}

            <S.StyledTextField
              placeholder="Inserir nova senha"
              type={showPassword ? 'text' : 'password'}
              onChange={(e) => {
                setIsPassword(false)
                setPassword(e.target.value)
              }}
              error={isPassword}
              helperText={isPassword ? 'Essa informação é obrigatória' : ''}
              size="medium"
              disabled={isErrorToken}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <Lock fontSize="small" />
                  </InputAdornment>
                ),

                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleClickShowPassword}
                      onMouseDown={handleMouseDownPassword}
                      edge="end"
                    >
                      {showPassword ? (
                        <VisibilityOff fontSize="small" />
                      ) : (
                        <Visibility fontSize="small" />
                      )}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />

            <S.StyledTextField
              placeholder="Confirmar nova senha"
              type={showPassword ? 'text' : 'password'}
              onChange={(e) => {
                setIsPassword(false)
                setConfirmarPassword(e.target.value)
              }}
              disabled={isErrorToken}
              error={confirmarPassword !== password}
              helperText={
                confirmarPassword !== password
                  ? 'As Senhas precisam ser iguais'
                  : ''
              }
              size="medium"
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <Lock fontSize="small" />
                  </InputAdornment>
                ),

                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleClickShowPassword}
                      onMouseDown={handleMouseDownPassword}
                      edge="end"
                    >
                      {showPassword ? (
                        <VisibilityOff fontSize="small" />
                      ) : (
                        <Visibility fontSize="small" />
                      )}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />

            <Button
              className="confirm-button"
              variant="contained"
              size="small"
              endIcon={
                loading && <CircularProgress color="inherit" size={25} />
              }
              disabled={loading || isErrorToken}
              sx={{ height: '43px', width: '85%', marginTop: '25px' }}
              onClick={handleLogin}
            >
              Confirmar Nova Senha
            </Button>

            <Button
              className="confirm-button"
              variant="text"
              size="small"
              // endIcon={
              //   loading && <CircularProgress color="inherit" size={25} />
              // }
              disabled={loading}
              sx={{
                height: '20px',
                fontWeight: 'bold',
                width: '85%',
                marginTop: '15px',
                textTransform: 'initial',
              }}
              onClick={() => history.push('/login')}
            >
              voltar para pagina inicial
            </Button>
          </S.BoxLogin>

          <caption
            style={{
              fontSize: '12px',
              position: 'absolute',
              bottom: '30px',
            }}
          >
            {descricaoRodape()}
          </caption>
        </Grid>
        <Grid
          xs={12}
          md={6}
          bgcolor={Color.EMPRESA.PRIMARIA}
          display={'flex'}
          justifyContent={'center'}
          alignItems={'center'}
        >
          <BannerLogin />
        </Grid>
      </Grid>
    </S.Container>
  )
}

const mapStateToProps = (store) => ({
  loggedIn: store.loginState.loggedIn,
  idUser: store.loginState.idUser,
  idFilial: store.loginState.idFilial,
})

const mapDispatchToProps = (dispatch) => bindActionCreators({ logar }, dispatch)

export default connect(mapStateToProps, mapDispatchToProps)(MudarSenha)
