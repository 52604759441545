import { styled } from '@mui/material/styles'
import Paper from '@mui/material/Paper'


 const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: 'center',
  marginBottom: '10px',

  '&:hover': {
    cursor: 'pointer',
    border: '1px solid #1A2027',
    marginBottom: '8px',
  }
}))


export default Item
