import * as React from 'react'
import Card from '@mui/material/Card'
import CardActions from '@mui/material/CardActions'
import Button from '@mui/material/Button'
import { Avatar, CardHeader } from '@mui/material'
import "./cardstyles.css"

export default function CardEmpresa({ empresa, empresaSelecionada, handlerEmpresa }) {
  const { imagemLogo, fantasia } = empresa
  return (
    <Card sx={{ maxWidth: 345, padding: "0 18px 10px" }} >
      <CardHeader
        avatar={
          <Avatar
            alt={"Logo " + fantasia }
            src={imagemLogo}
            sx={{ width: 80, height: 80 }}
            className='border avatar-card'
          />
        }
        title="Empresa"
        subheader={fantasia}
      />
      <CardActions>
        <Button
          className={empresaSelecionada === fantasia ? 'btn-card-light' : 'btn-card'}
          sx={{ width: '100%' }} 
          type='button' 
          variant={ empresaSelecionada === fantasia ? 'outlined' : 'contained'} 
          size="large"
          onClick={() => handlerEmpresa(empresa)}
          disabled={empresaSelecionada === fantasia}
        >
          {empresaSelecionada === fantasia ? 'Selecionada' : 'Selecionar'}
        </Button>
      </CardActions>
    </Card>
  )
}
