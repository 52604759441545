import React from "react";
import { Box, Button, CircularProgress, Grid, InputAdornment, Stack, Typography } from "@mui/material";
import { ColorPicker, Input, InputSelect, ModalConfirmar, RadioButtonsGroup, Titulo } from "../../components";
import { api, Mask, Utils } from "../../utilities";
import { toast } from "react-toastify";
import * as S from "./styles";
import ImageUploader from "../../components/ImageUploader/ImageUploader";
import EmpresaService from "../../service/EmpresaService";

export default function EditarEmpresa({
                                        estados = [],
                                        infoEmpresas = {},
                                        editar = false,
                                        idUsuarioAtualizacao = "",
                                        closeDialog
                                      }) {
  const [openModal, setOpenModal] = React.useState(false);
  const handleOpenModal = () => setOpenModal(true);
  const handleCloseModal = () => setOpenModal(false);

  const [state, setState] = React.useState({
    razaoSocial: null,
    fantasia: null,
    cpfCnpj: null,
    logradouro: null,
    numero: null,
    complemento: null,
    bairro: null,
    idEstadoIbge: null,
    idCidadeIbge: null,
    cep: null,
    telefone: null,
    corPrimaria: null,
    corSecundaria: null,
    corTerciaria: null,
    idUsuarioAtualizacao: null,
    imagemLogo: null,
    tipoMenu: null,
    ...infoEmpresas
  });

  const [cidades, setCidades] = React.useState([]);

  const [loading, setLoading] = React.useState(false);

  function validacao(value) {
    return modificado ? !Utils.isNotNull(value) : false;
  }

  const [modificado, setModificado] = React.useState(false);
  const [bloqueio, setBloqueio] = React.useState(false);

  const optionsStatus = [
    { value: true, label: "Ativo" },
    { value: false, label: "Inativo" }
  ];

  const optionsBloqueio = [
    { value: true, label: "Sim" },
    { value: false, label: "Não" }
  ];

  const [tipoMenu, setTipoMenu] = React.useState([
    {
      tipoMenu: "HORIZONTAL",
      descricao: "Horizontal"
    },
    {
      tipoMenu: "VERTICAL",
      descricao: "Vertical"
    }
  ]);

  React.useEffect(() => {
    if (editar) {
      buscarCidade(infoEmpresas.idEstadoIbge);
    }
  }, [infoEmpresas]);

  function cadastrarEditar() {
    setLoading(true);
    setModificado(true);

    if (
      !Utils.isNotNull(state.razaoSocial) ||
      !Utils.isNotNull(state.fantasia) ||
      !Utils.isNotNull(state.cpfCnpj) ||
      !Utils.isNotNull(state.telefone) ||
      !Utils.isNotNull(state.cep) ||
      !Utils.isNotNull(state.idEstadoIbge) ||
      !Utils.isNotNull(state.idCidadeIbge) ||
      !Utils.isNotNull(state.tipoMenu)
    ) {
      toast.error("Preencha as informações obrigatórias!", {
        theme: "colored"
      });
      setLoading(false);
      handleCloseModal();
      return;
    }

    if (!editar) {
      EmpresaService.cadastrar({
        ...state,
        idUsuarioAtualizacao: idUsuarioAtualizacao
      })
        .then(({ status, mensagem }) => {
          setLoading(false);
          if (status) {
            toast.success(mensagem, {
              theme: "colored"
            });
            closeDialog();
          } else {
            toast.error(mensagem, {
              theme: "colored"
            });
          }
        })
        .catch((error) => {
          setLoading(false);
          toast.error("Houve um problema ao tentar cadastrar a Empresa!", {
            theme: "colored"
          });
        });
    } else {
      EmpresaService.atualizar({
        ...state,
        idUsuarioAtualizacao: idUsuarioAtualizacao
      })
        .then(({ status, mensagem }) => {
          setLoading(false);
          if (status) {
            toast.success(mensagem, {
              theme: "colored"
            });
            closeDialog();
          } else {
            toast.error(mensagem, {
              theme: "colored"
            });
          }
        });
      // .catch((error) => {
      //   setLoading(false)
      //   toast.error('Houve um problema na edição da Empresa!', {
      //     theme: 'colored',
      //   })
      // })
    }

    handleCloseModal();
  }

  function setImage(base64) {
    setState((state) => ({
      ...state,
      imagemLogo: base64
    }));
  }

  function setColor(colorName, color) {
    setState((state) => ({
      ...state,
      [colorName]: color
    }));
  }

  async function buscarCnpj(cnpj) {
    setLoading(true);
    setState((state) => ({
      ...state,
      cpfCnpj: Mask.maskValueCpfCnpj(cnpj)
    }));

    EmpresaService.buscarCNPJ(cnpj).then((data) => {
      setLoading(false);
      if (Utils.isNotObjectEmpty(data)) {
        setState((state) => ({
          ...state,
          ...data,
          cpfCnpj: Mask.maskValueCpfCnpj(cnpj)
        }));
        buscarCidade(data.idEstadoIbge);
      } else {
        setState((state) => ({
          ...state,
          cpfCnpj: Mask.maskValueCpfCnpj(cnpj)
        }));
      }
    });
  }

  async function buscarCidade(estado) {
    setLoading(true);

    api.get("empresa/admin/mobioh/cidades/" + estado).then(({ data }) => {
      setLoading(false);
      if (Utils.isNotArrayEmpty(data)) {
        setCidades(data);
      }
    });
  }

  return (
    <Box padding={3}>
      <ModalConfirmar
        open={openModal}
        handleConfirm={cadastrarEditar}
        handleClose={handleCloseModal}
        customStyle={{ width: 400 }}
      >
        <S.TitleModal>Salvar as informações da Empresa?</S.TitleModal>
      </ModalConfirmar>

      <Grid container padding={1} spacing={2}>
        <Grid xs={12} md={4} item>
          <Input
            label="Cnpj"
            InputLabelProps={{ shrink: true }}
            id={"descricao"}
            error={validacao(state.cpfCnpj)}
            helperText={
              validacao(state.cpfCnpj) ? "Essa informação é obrigatória" : ""
            }
            value={state.cpfCnpj}
            onChange={(e) => {
              if (Mask.clearMask(e.target.value).length === 14) {
                buscarCnpj(Mask.clearMask(e.target.value));
              } else {
                setState((state) => ({
                  ...state,
                  cpfCnpj: Mask.maskValueCpfCnpj(e.target.value)
                }));
              }
            }}
            placeholder="Escreva o Cnpj ou Cpf para a Empresa"
            size="small"
            InputProps={{
              endAdornment: loading && (
                <InputAdornment position="start">
                  <CircularProgress color="inherit" size={25} />
                </InputAdornment>
              )
            }}
          />
        </Grid>

        <Grid xs={12} md={4} item>
          <Input
            label="Razão Social"
            id={"nome"}
            error={validacao(state.razaoSocial)}
            helperText={
              validacao(state.razaoSocial)
                ? "Essa informação é obrigatória"
                : ""
            }
            value={state.razaoSocial}
            InputLabelProps={{ shrink: true }}
            onChange={(e) => {
              setState((state) => ({
                ...state,
                razaoSocial: e.target.value
              }));
            }}
            placeholder="Escreva a Razão Social da Empresa"
            size="small"
            InputProps={{
              endAdornment: loading && (
                <InputAdornment position="start">
                  <CircularProgress color="inherit" size={25} />
                </InputAdornment>
              )
            }}
          />
        </Grid>

        <Grid xs={12} md={4} item>
          <Input
            label="Fantasia"
            InputLabelProps={{ shrink: true }}
            id={"descricao"}
            error={validacao(state.fantasia)}
            helperText={
              validacao(state.fantasia) ? "Essa informação é obrigatória" : ""
            }
            value={state.fantasia}
            onChange={(e) => {
              setState((state) => ({
                ...state,
                fantasia: e.target.value
              }));
            }}
            placeholder="Escreva o nome fantasia da Empresa"
            size="small"
            InputProps={{
              endAdornment: loading && (
                <InputAdornment position="start">
                  <CircularProgress color="inherit" size={25} />
                </InputAdornment>
              )
            }}
          />
        </Grid>

        <Grid xs={12} md={4} item>
          <Input
            label="Telefone"
            InputLabelProps={{ shrink: true }}
            id={"descricao"}
            error={validacao(state.telefone)}
            helperText={
              validacao(state.telefone) ? "Essa informação é obrigatória" : ""
            }
            value={state.telefone}
            onChange={(e) => {
              setState((state) => ({
                ...state,
                telefone: Mask.getValueMaskPhone(e.target.value)
              }));
            }}
            placeholder="Escreva o telefone com DDD da Empresa"
            size="small"
            InputProps={{
              endAdornment: loading && (
                <InputAdornment position="start">
                  <CircularProgress color="inherit" size={25} />
                </InputAdornment>
              )
            }}
          />
        </Grid>

        <Grid xs={12} md={2} item>
          <Input
            label="Cep"
            InputLabelProps={{ shrink: true }}
            id={"descricao"}
            error={validacao(state.cep)}
            helperText={
              validacao(state.cep) ? "Essa informação é obrigatória" : ""
            }
            value={state.cep}
            onChange={(e) => {
              setState((state) => ({
                ...state,
                cep: Mask.maskValueCep(e.target.value)
              }));
            }}
            placeholder="Escreva o Cep do endereço da Empresa"
            size="small"
            InputProps={{
              endAdornment: loading && (
                <InputAdornment position="start">
                  <CircularProgress color="inherit" size={25} />
                </InputAdornment>
              )
            }}
          />
        </Grid>

        <Grid xs={12} md={2} item>
          <Input
            label="Número"
            InputLabelProps={{ shrink: true }}
            id={"descricao"}
            error={validacao(state.numero)}
            helperText={
              validacao(state.numero) ? "Essa informação é obrigatória" : ""
            }
            value={state.numero}
            onChange={(e) => {
              setState((state) => ({
                ...state,
                numero: e.target.value
              }));
            }}
            placeholder="Escreva um número"
            size="small"
            InputProps={{
              endAdornment: loading && (
                <InputAdornment position="start">
                  <CircularProgress color="inherit" size={25} />
                </InputAdornment>
              )
            }}
          />
        </Grid>

        <Grid xs={12} md={4} item>
          <Input
            label="Complemento"
            InputLabelProps={{ shrink: true }}
            id={"descricao"}
            // error={validacao(state.complemento)}
            // helperText={
            //   validacao(state.complemento) ? 'Essa informação é obrigatória' : ''
            // }
            value={state.complemento}
            onChange={(e) => {

              setState((state) => ({
                ...state,
                complemento: e.target.value
              }));


            }}
            placeholder="Escreva um complemento para o endereço"
            size="small"
            InputProps={{
              endAdornment: loading && (
                <InputAdornment position="start">
                  <CircularProgress color="inherit" size={25} />
                </InputAdornment>
              )
            }}
          />
        </Grid>

        <Grid xs={12} md={3} item>
          <InputSelect
            value={state.idCidadeIbge}
            setValue={(value) => {
              setState((state) => ({
                ...state,
                idCidadeIbge: value
              }));
            }}
            options={cidades}
            label="Cidade"
            id="idCidadeIbge"
            descricao="nomeCidade"
            size="small"
            sx={{ padding: "5px 0px 0px", marginTop: "-5px" }}
            error={validacao(state.idCidadeIbge)}
            helperText={
              validacao(state.idCidadeIbge)
                ? "Essa informação é obrigatória"
                : ""
            }
            InputProps={{
              endAdornment: loading && (
                <InputAdornment position="start">
                  <CircularProgress color="inherit" size={25} />
                </InputAdornment>
              )
            }}
          />
        </Grid>

        <Grid xs={12} md={3} item>
          <InputSelect
            value={state.idEstadoIbge}
            setValue={(value) => {
              buscarCidade(value);
              setState((state) => ({
                ...state,
                idEstadoIbge: value,
                idCidadeIbge: null
              }));
            }}
            //  shrink={!!state.idEstadoIbge}
            options={estados}
            label="Estado"
            id="idEstadoIbge"
            descricao="nomeEstado"
            codigo="sigla"
            size="small"
            sx={{ padding: "5px 0px 0px", marginTop: "-5px" }}
            error={validacao(state.idEstadoIbge)}
            helperText={
              validacao(state.idEstadoIbge)
                ? "Essa informação é obrigatória"
                : ""
            }
            InputProps={{
              endAdornment: loading && (
                <InputAdornment position="start">
                  <CircularProgress color="inherit" size={25} />
                </InputAdornment>
              )
            }}
          />
        </Grid>

        <Grid xs={12} md={3} item>
          <Input
            label="Bairro"
            id={"descricao"}
            InputLabelProps={{ shrink: true }}
            error={validacao(state.bairro)}
            helperText={
              validacao(state.bairro) ? "Essa informação é obrigatória" : ""
            }
            value={state.bairro}
            onChange={(e) => {
              setState((state) => ({
                ...state,
                bairro: e.target.value
              }));
            }}
            placeholder="Escreva o bairro"
            size="small"
            InputProps={{
              endAdornment: loading && (
                <InputAdornment position="start">
                  <CircularProgress color="inherit" size={25} />
                </InputAdornment>
              )
            }}
          />
        </Grid>

        <Grid xs={12} md={3} item>
          <InputSelect
            value={state.tipoMenu}
            setValue={(value) => {
              setState((state) => ({
                ...state,
                tipoMenu: value
              }));
            }}
            error={validacao(state.tipoMenu)}
            helperText={
              validacao(state.tipoMenu) ? "Essa informação é obrigatória" : ""
            }
            options={tipoMenu}
            label="Tipo de Menu"
            id="tipoMenu"
            descricao="descricao"
            size="small"
            sx={{ padding: "5px 0px 0px", margin: "-5px 0px 5px" }}
          />
        </Grid>

        {editar && (
          <Grid container spacing={2} item>
            <Grid xs={12} md={3} item>
              <RadioButtonsGroup
                label="Status"
                options={optionsStatus}
                labelOptions="label"
                valueOptions="value"
                defaultValue={state.ativo}
                onChange={(e) => {
                  setModificado(true);
                  setState((state) => ({
                    ...state,
                    ativo: e.target.value === "true"
                  }));
                }}
              />
            </Grid>
            <Grid xs={12} md={2} item>
              <RadioButtonsGroup
                label="Bloqueio"
                options={optionsBloqueio}
                labelOptions="label"
                valueOptions="value"
                defaultValue={state.bloqueio}
                onChange={(e) => {
                  setModificado(true);
                  setBloqueio(e.target.value);
                  setState((state) => ({
                    ...state,
                    bloqueio: e.target.value === "true"
                  }));

                }}
              />
            </Grid>
            {bloqueio && (
              <Grid xs={12} md={7} marginTop={2} item>
                <Input
                  label="Motivo Bloqueio"
                  InputLabelProps={{ shrink: true }}
                  id={"motivoBloqueio"}
                  value={state.motivoBloqueio}
                  onChange={(e) => {
                    setState((state) => ({
                      ...state,
                      motivoBloqueio: e.target.value
                    }));
                  }}
                  placeholder="Escreva o motivo do Bloqueio da Empresa"
                  size="small"
                  InputProps={{
                    endAdornment: loading && (
                      <InputAdornment position="start">
                        <CircularProgress color="inherit" size={25} />
                      </InputAdornment>
                    )
                  }}
                />
              </Grid>
            )}
          </Grid>
        )}
      </Grid>

      <Titulo>Cores da Empresa</Titulo>
      <S.ContainerForm>
        <Grid container spacing={2}>
          <Grid xs={12} md={3} item>
            <ColorPicker
              setColor={(color) => setColor("corPrimaria", color)}
              color={state.corPrimaria}
            />
            <Typography
              variant="h6"
              component="span"
              sx={{
                fontSize: "16px !important",
                fontWeight: "bolder",
                marginBottom: 1,
                color: "#131022 !important"
              }}
            >
              Primária
            </Typography>
          </Grid>

          <Grid xs={12} md={3} item>
            <ColorPicker
              setColor={(color) => setColor("corSecundaria", color)}
              color={state.corSecundaria}
            />
            <Typography
              variant="h6"
              component="span"
              sx={{
                fontSize: "16px !important",
                fontWeight: "bolder",
                marginBottom: 1,
                color: "#131022 !important"
              }}
            >
              Secundária
            </Typography>
          </Grid>

          <Grid xs={12} md={3} item>
            <ColorPicker
              setColor={(color) => setColor("corTerciaria", color)}
              color={state.corTerciaria}
            />
            <Typography
              variant="h6"
              component="span"
              sx={{
                fontSize: "16px !important",
                fontWeight: "bolder",
                marginBottom: 1,
                color: "#131022 !important"
              }}
            >
              Terciária
            </Typography>
          </Grid>
        </Grid>
      </S.ContainerForm>

      <Titulo>Logo Empresa</Titulo>
      <S.ContainerForm>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <ImageUploader
              isConfirm={false}
              setImage={setImage}
              img={state.imagemLogo}
            />
          </Grid>
        </Grid>
      </S.ContainerForm>

      <Stack direction="row" spacing={2} justifyContent="end">
        <Button
          className="confirm-button"
          variant="contained"
          size="large"
          type="submit"
          onClick={handleOpenModal}
          startIcon={loading && <CircularProgress color="inherit" size={25} />}
          disabled={loading}
        >
          Salvar
        </Button>
      </Stack>
    </Box>
  );
}
