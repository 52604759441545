import React from 'react'
import { Button, CircularProgress, InputAdornment } from '@mui/material'

import UserService from '../../service/UserService'
import { toast } from 'react-toastify'
import * as S from './styles'
import { PersonOutline } from '@mui/icons-material'

function RecuperarSenha(props) {
  const [loading, setLoading] = React.useState(false)
  const [login, setLogin] = React.useState('')
  const [isLogin, setIsLogin] = React.useState(false)

  async function handleLogin() {
    if (login.trim() === '') {
      setIsLogin(true)
      return
    }

    let newUser = {
      login: login,
      urlSite: window.location.hostname,
    }

    setLoading(true)
    await UserService.resetarSenha(newUser)
      .then(({ status, mensagem }) => {
        setLoading(false)
        if (status) {
          toast.success(mensagem, {
            theme: 'colored',
          })
          props.setIsRecuperarSenha(false)
        } else {
          toast.error(mensagem, {
            theme: 'colored',
          })
        }
      })
      .catch((error) => {
        setLoading(false)
        if (error.message === 'Network Error') {
          toast.error('Falha na conexão com o servidor' + error.message, {
            theme: 'colored',
          })
          console.log('Falha na conexão com o servidor')
        } else {
          toast.error('Erro no envio! ' + error.message, {
            theme: 'colored',
          })
        }
      })
  }

  return (
    <>
      <Button
        className="confirm-button"
        variant="text"
        size="small"
        color="warning"
        // endIcon={
        //   loading && <CircularProgress color="inherit" size={25} />
        // }
        disabled={loading}
        sx={{
          height: '20px',
          fontWeight: 'bold',
          width: '95%',
          marginTop: '45px',
          marginBottom: '10px',
          textTransform: 'initial',
          cursor: 'default',
        }}
        disable
        //  onClick={() => props.setIsRecuperarSenha(false)}
      >
        Insira seu e-mail e enviaremos uma solicitação para mudar sua senha!
      </Button>

      <S.StyledTextField
        placeholder="Informe seu Email"
        size="medium"
        onChange={(e) => {
          setIsLogin(false)
          setLogin(e.target.value)
        }}
        error={isLogin}
        helperText={isLogin ? 'Essa informação é obrigatória' : ''}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <PersonOutline fontSize="small" />
            </InputAdornment>
          ),
        }}
      />
      <Button
        className="confirm-button"
        variant="contained"
        size="small"
        endIcon={loading && <CircularProgress color="inherit" size={25} />}
        disabled={loading}
        sx={{ height: '43px', width: '85%', marginTop: '25px' }}
        onClick={handleLogin}
      >
        Confirmar
      </Button>

      <Button
        className="confirm-button"
        variant="text"
        size="small"
        // endIcon={
        //   loading && <CircularProgress color="inherit" size={25} />
        // }
        disabled={loading}
        sx={{
          height: '20px',
          fontWeight: 'bold',
          width: '85%',
          marginTop: '15px',
          textTransform: 'initial',
        }}
        onClick={() => props.setIsRecuperarSenha(false)}
      >
        Voltar para Inicio
      </Button>
    </>
  )
}

export default RecuperarSenha
