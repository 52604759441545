import * as React from 'react'
import Modal from '@mui/material/Modal'
import Box from '@mui/material/Box'
import styled from 'styled-components'
import CloseIcon from '@mui/icons-material/Close'
import { Button, Stack } from '@mui/material'

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  color: ${(props) => props.theme.font.color};
  padding: 5px 15px 15px 15px;
  background-color: #fff;

  border: 1px solid transparent;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 6px -1px,
    rgba(0, 0, 0, 0.06) 0px 2px 4px -1px;

  width: 100%;
  border-radius: 4px;  
`

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 600,
  height: 'auto',
  bgcolor: 'background.paper',
  boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.25)',
  borderRadius: '10px',
  p: 1,
}

export default function ModalConfirmar({
  open,
  handleClose,
  handleConfirm,
  children,
  customStyle
}) {
  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={{ ...style, ...customStyle}}>
        <Container>
          <Stack direction="row" spacing={2} justifyContent="end">
            <CloseIcon
              onClick={() => handleClose()}
              sx={{ cursor: 'pointer' }}
            />
          </Stack>

          {children}
        </Container>
        <Stack direction="row" spacing={2} sx={{ margin: "15px 5px 5px" }} justifyContent="end">
          <Button
            className="modal-cancel-button"
            variant="contained"
            color='error'
            size="large"
            type="submit"
            onClick={handleClose}
          >
            Cancelar
          </Button>

          <Button
            className="confirm-button"
            variant="contained"
            size="large"
            type="submit"
            onClick={handleConfirm}
          >
            Confirmar
          </Button>
        </Stack>
      </Box>
    </Modal>
  )
}
