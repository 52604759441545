import React, { useState } from 'react'
import * as S from './styles'
import {
  FullScreenDialog,
  Input,
  InputSelect,
  ModalConfirmar,
  TableComponet,
  Titulo,
} from '../../components'
import { Button, CircularProgress, Grid, Stack, Tooltip } from '@mui/material'
import AddCircleOutlineOutlinedIcon from '@mui/icons-material/AddCircleOutlineOutlined'
import EditNoteIcon from '@mui/icons-material/EditNote'
import SearchOutlinedIcon from '@mui/icons-material/SearchOutlined'
import { Utils, api } from '../../utilities'
import { connect } from 'react-redux'
import { toast } from 'react-toastify'
import RelatorioService from '../../service/RelatorioService'
import { FilterAltOff, GroupAddOutlined } from '@mui/icons-material'
import PerfisService from '../../service/PerfisService'
import EditarRelatorio from './EditarRelatorio'
import EditarOrdemMenu from './EditarOrdemMenu'

function RelatorioSincronizar(props) {
  const [open, setOpen] = useState(false)
  const handleOpen = () => setOpen(true)
  const handleClose = () => setOpen(false)

  const [openMenu, setOpenMenu] = useState(false)
  const handleOpenMenu = () => setOpenMenu(true)
  const handleCloseMenu = () => setOpenMenu(false)

  const [openSemCadastro, setOpenSemCadastro] = useState(false)
  const handleOpenSemCadastro = () => setOpenSemCadastro(true)
  const handleCloseSemCadastro = () => setOpenSemCadastro(false)

  const [loading, setLoading] = useState(false)
  const [menus, setMenus] = useState([])
  const [subMenus, setSubMenus] = useState([])

  const [relatorios, setRelatorios] = useState(props.relatorios)
  const [relatoriosSemCadastro, setRelatoriosSemCadastro] = useState([])
  const [infoRelatorio, setInfoRelatorio] = useState({})
  const [selected, setSelected] = useState([])

  const [stateRelatorio, setStateRelatorio] = useState({
    idPerfil: props.idPerfil,
    descricao: null,
    menu: null,
    subMenu: null,
  })

  const headers = [
    {
      id: 'codigoRelatorio',
      label: 'Código',
      props: {
        align: 'left',
      },
    },
    {
      id: 'ordemPerfil',
      label: 'Ordem',
      props: {
        align: 'left',
      },
    },
    {
      id: 'nome',
      label: 'Nome',
      props: {
        align: 'left',
      },
    },
    {
      id: 'menu',
      label: 'menu',
      props: {
        align: 'left',
      },
    },
    {
      id: 'subMenu',
      label: 'Submenu',
      props: {
        align: 'left',
      },
    },
    {
      id: 'statusRows',
      label: 'Vínculo',
      props: {
        align: 'right',
      },
    },
    {
      id: 'checkRows',
      label: 'Pagina Inicial',
      props: {
        align: 'right',
      },
    },

    {
      id: 'actionRows',
      label: 'Ações',
      props: {
        align: 'right',
      },
    },
  ]

  const headersSemcadastro = [
    {
      id: 'codigoRelatorio',
      label: 'Código',
      props: {
        align: 'left',
      },
    },
    {
      id: 'nome',
      label: 'Nome',
      props: {
        align: 'left',
      },
    },
    {
      id: 'menu',
      label: 'menu',
      props: {
        align: 'left',
      },
    },
    {
      id: 'subMenu',
      label: 'Submenu',
      props: {
        align: 'left',
      },
    },
    {
      id: 'statusRows',
      label: 'Status',
      props: {
        align: 'right',
      },
    },
  ]

  async function buscarCadastrados() {
    api
      .get('perfil/completo/' + props.idPerfil)
      .then(({ data }) => {
        setLoading(false)
        if (Utils.isNotObjectEmpty(data)) {
          setRelatorios(data.relatorios ? data.relatorios : [])
        }
      })
      .catch((error) => {
        setLoading(false)
        toast.error('Houve um problema na busca dos dados do Perfil!', {
          theme: 'colored',
        })
      })
  }

  async function buscarRelatoriosSemCadastro() {
    setLoading(true)
    api
      .post('/perfil/relatorio/semcadastro', stateRelatorio)
      .then(({ data }) => {
        setLoading(false)
        if (Utils.isArrayNotEmpty(data)) {
          setRelatoriosSemCadastro(data)
        } else {
          setRelatoriosSemCadastro([])
        }
      })
      .catch((error) => {
        setLoading(false)
        toast.error('Houve um problema ao buscar os Relatórios sem cadastro!', {
          theme: 'colored',
        })
      })
  }

  function editarRelatorio(data) {
    setInfoRelatorio(data)
    handleOpen()
  }

  function closeDialog() {
    setInfoRelatorio({})
    handleClose()
    //  getRelatorios()
  }
  function closeDialogMenu() {
    handleCloseMenu()
    //  getRelatorios()
  }

  function closeDialogSemCadastro() {
    handleCloseSemCadastro()
    setRelatoriosSemCadastro([])
  }

  function buscarMobioh() {
    setLoading(true)
    RelatorioService.buscar(stateRelatorio)
      .then((data) => {
        setLoading(false)
        if (Utils.isArrayNotEmpty(data)) {
          setRelatorios(data)
        } else {
          setRelatorios([])
        }
      })
      .catch((error) => {
        setLoading(false)
        toast.error('Houve um problema ao buscar os Relatórios!', {
          theme: 'colored',
        })
      })
  }

  // SELECAO SEM CADASTRO

  function sincronizarSelecionados() {
    setLoading(true)

    let newSincronizar = []

    selected.forEach((item) => {
      newSincronizar.push({
        idPerfil: props.idPerfil,
        idEmpresa: props.idEmpresa,
        idRelatorio: item.idRelatorio,
        ativoPerfil: true,
        idUsuarioAtualizacao: props.user.idUsuario,
      })
    })

    PerfisService.sincronizarRelatorio(newSincronizar)
      .then(({ status, mensagem }) => {
        setLoading(false)
        if (status) {
          toast.success(mensagem, {
            theme: 'colored',
          })
          closeDialogSemCadastro()
          buscarCadastrados()
        } else {
          toast.error(mensagem, {
            theme: 'colored',
          })
        }
      })
      .catch((error) => {
        setLoading(false)
        toast.error('Houve um problema ao sincronizar os Relatórios!', {
          theme: 'colored',
        })
      })
  }

  function ButtonAction() {
    return (
      <Stack direction="row" xs={12} spacing={2} justifyContent="end">
        <Button
          className="confirm-button"
          variant="contained"
          size="small"
          sx={{ height: '48px', minWidth: '300px', textTransform: 'none' }}
          disabled={loading}
          endIcon={
            loading ? (
              <CircularProgress color="inherit" size={25} />
            ) : (
              <GroupAddOutlined />
            )
          }
          onClick={sincronizarSelecionados}
        >
          Vincular Relatórios Selecionados
        </Button>
      </Stack>
    )
  }

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelected = relatoriosSemCadastro.map((n) => {
        n.idUsuarioAtualizacao = props.user.idUsuario
        return n
      })
      setSelected(newSelected)
      return
    }
    setSelected([])
  }

  const handleClickSelected = (event, relatorio) => {
    const selectedIndex = selected.findIndex(
      (selectedRelatorio) =>
        selectedRelatorio.idRelatorio === relatorio.idRelatorio,
    )
    let newSelected = []

    if (selectedIndex === -1) {
      relatorio.idUsuarioAtualizacao = props.user.idUsuario
      newSelected = [...selected, relatorio]
    } else {
      newSelected = selected.filter(
        (selectedRelatorio) =>
          selectedRelatorio.idRelatorio !== relatorio.idRelatorio,
      )
    }
    setSelected(newSelected)
  }

  return (
    <S.Container>
      <Titulo>
        <div>Relatórios Vinculados</div>
        <Stack
          direction="row"
          spacing={2}
          justifyContent="end"
          marginTop="-26px"
        >
          <Button
            className="confirm-button"
            variant="contained"
            size="medium"
            endIcon={<EditNoteIcon />}
            //sx={{ height: '43px' }}
            onClick={() => {
              handleOpenMenu()
            }}
          >
            Ordem dos Menus
          </Button>

          <Button
            className="confirm-button"
            variant="contained"
            size="medium"
            endIcon={<AddCircleOutlineOutlinedIcon />}
            //sx={{ height: '43px' }}
            onClick={() => {
              buscarRelatoriosSemCadastro()
              handleOpenSemCadastro()
            }}
          >
            Vincular Relatório
          </Button>
        </Stack>
      </Titulo>
      <S.ContainerForm>
        <Grid container padding={1} spacing={2}>
          <Grid xs={12} item>
            <TableComponet
              headers={headers}
              data={relatorios}
              handlerEditarAction={(data) => editarRelatorio(data)}
              labelCaption="Nenhum Relatório Encontrado"
              labelTable="Lista de Relatórios"
              status="ativoPerfil"
              checkRow="paginaInicialPerfil"
              checkLabelTrue={'Pagina Inicial'}
              statusLabelTrue="Ativo"
              statusLabelFalse="Inativo"
              loading={loading}
            />
          </Grid>
        </Grid>
      </S.ContainerForm>

      <FullScreenDialog
        label={'Editar Ordenação dos Menus'}
        open={openMenu}
        handleClose={closeDialogMenu}
      >
        <EditarOrdemMenu
          relatorios={relatorios}
          closeDialogMenu={closeDialogMenu}
          buscarCadastrados={buscarCadastrados}
          idUsuarioAtualizacao={props.user.idUsuario}
          idEmpresa={props.idEmpresa}
          idPerfil={props.idPerfil}
        />
      </FullScreenDialog>

      <FullScreenDialog
        label={'Editar Relatório'}
        open={open}
        handleClose={closeDialog}
      >
        <EditarRelatorio
          menus={menus}
          subMenus={subMenus}
          idUsuarioAtualizacao={props.user.idUsuario}
          editar={true}
          idEmpresa={props.idEmpresa}
          idPerfil={props.idPerfil}
          infoRelatorio={infoRelatorio}
        />
      </FullScreenDialog>

      <FullScreenDialog
        label={'Relatórios Disponíveis'}
        open={openSemCadastro}
        handleClose={closeDialogSemCadastro}
      >
        <Grid container padding={2} spacing={2} marginBottom={1} marginTop={1}>
          <Grid xs={12} md={5} item>
            <Input
              label="Pesquisar"
              id={'descricao'}
              // error={validacao(search)}
              // helperText={
              //   validacao(search) ? 'Essa informação é obrigatória' : ''
              // }
              value={stateRelatorio.descricao}
              onChange={(e) => {
                setStateRelatorio((state) => ({
                  ...state,
                  descricao: e.target.value,
                }))
              }}
              placeholder="Escreva o Nome ou descrição do Relatório"
              size="small"
            />
          </Grid>

          <Grid xs={12} md={3} item>
            <InputSelect
              value={stateRelatorio.menu}
              setValue={(value) => {
                setStateRelatorio((state) => ({
                  ...state,
                  menu: value,
                }))
              }}
              options={menus}
              label="Menus"
              id="id"
              descricao="id"
              size="small"
              sx={{ padding: '5px 0px 0px', marginTop: '-5px' }}
            />
          </Grid>

          <Grid xs={12} md={2} item>
            <InputSelect
              value={stateRelatorio.subMenu}
              setValue={(value) => {
                setStateRelatorio((state) => ({
                  ...state,
                  subMenu: value,
                }))
              }}
              options={subMenus}
              label="Submenus"
              id="id"
              descricao="id"
              size="small"
              sx={{ padding: '5px 0px 0px', marginTop: '-5px' }}
            />
          </Grid>

          <Grid xs={12} md={2} item display={'flex'}>
            <Button
              className="confirm-button"
              variant="contained"
              size="large"
              startIcon={
                loading ? (
                  <CircularProgress color="inherit" size={25} />
                ) : (
                  <SearchOutlinedIcon />
                )
              }
              disabled={loading}
              sx={{ height: '39px' }}
              onClick={buscarMobioh}
            >
              Buscar
            </Button>

            <Tooltip
              title={'Limpar Filtros'}
              placement="top"
              style={{ fontSize: '20px !important' }}
            >
              <Button
                className="confirm-button"
                variant="text"
                size="large"
                startIcon={
                  loading ? (
                    <CircularProgress color="inherit" size={25} />
                  ) : (
                    <FilterAltOff />
                  )
                }
                disabled={loading}
                sx={{ height: '39px', color: '#C4C4C4 !important' }}
                onClick={() =>
                  setStateRelatorio((state) => ({
                    ...state,
                    descricao: '',
                    menu: null,
                    subMenu: null,
                  }))
                }
              ></Button>
            </Tooltip>
          </Grid>
        </Grid>
        <Grid container padding={2}>
          <Grid xs={12} item>
            <TableComponet
              headers={headersSemcadastro}
              data={relatoriosSemCadastro}
              handlerEditarAction={(data) => editarRelatorio(data)}
              labelCaption="Nenhum Relatório Encontrado"
              labelTable="Lista de Relatórios"
              status="ativo"
              statusLabelTrue="Ativo"
              statusLabelFalse="Inativo"
              loading={loading}
              selected={selected}
              selectedName="idRelatorio"
              handleClickSelected={handleClickSelected}
              handleSelectAllClick={handleSelectAllClick}
              ActionSelect={ButtonAction}
            />
          </Grid>
        </Grid>
      </FullScreenDialog>
    </S.Container>
  )
}

const mapStateToProps = (store) => ({
  user: store.loginState.user.usuario,
})

export default connect(mapStateToProps)(RelatorioSincronizar)
