import { LOGIN, TOKEN_POWERBI } from "./ActionTypes";

export const logar = (loggedIn, user) => ({
  type: LOGIN,
  loggedIn,
  user
});


export const setAcessTokenUser = accessToken => ({
  type: TOKEN_POWERBI,
  accessToken
})

