import 'dayjs/locale/pt-br'
import React from 'react'
import { BrowserRouter } from 'react-router-dom'
import Routes from './routes/Routes'
import GlobalStyles from './assets/styles/global'
import { ThemeProvider } from 'styled-components'
import theme from './assets/styles/theme'
import { Provider } from 'react-redux'
import { Store } from './redux/store/Store'
import './assets/styles/classColors.css'
import 'react-toastify/dist/ReactToastify.css'
import ThemesContext from './components/context/ThemeContext'
import { LocalizationProvider } from '@mui/x-date-pickers'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import IPInfo from 'ip-info-react'

function Init() {
  return (
    <BrowserRouter>
      <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="pt-br">
        <Provider store={Store}>
          <ThemeProvider theme={theme}>
            <ThemesContext>
              <IPInfo>
                <GlobalStyles />
                <Routes />
              </IPInfo>
            </ThemesContext>
          </ThemeProvider>
        </Provider>
      </LocalizationProvider>
    </BrowserRouter>
  )
}

export default Init
