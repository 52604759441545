import * as React from 'react'
import { styled, alpha } from '@mui/material/styles'
import AppBar from '@mui/material/AppBar'
import Box from '@mui/material/Box'
import Toolbar from '@mui/material/Toolbar'
import IconButton from '@mui/material/IconButton'
import Typography from '@mui/material/Typography'
import Badge from '@mui/material/Badge'
import MenuItem from '@mui/material/MenuItem'
import Menu from '@mui/material/Menu'
import MenuIcon from '@mui/icons-material/Menu'
import MoreIcon from '@mui/icons-material/MoreVert'
import { Avatar } from '@mui/material'
import { bindActionCreators } from 'redux'
import {
  logar,
  setPowerbi,
  getEmpresa,
  setMobilePowerBi,
} from '../../redux/actions'
import { connect } from 'react-redux'
import FullscreenExitIcon from '@mui/icons-material/FullscreenExit'
import CachedIcon from '@mui/icons-material/Cached'
import RestoreIcon from '@mui/icons-material/Restore'
import { useHistory } from 'react-router-dom'
import { models } from 'powerbi-client'
import SubMenusEmpresa from './SubMenusEmpresa'
import { Utils, api } from '../../utilities'
import { useContext } from 'react'
import { IPInfoContext } from 'ip-info-react'
import { Add, Remove, StayCurrentPortrait, Tv } from '@mui/icons-material'

const Search = styled('div')(({ theme }) => ({
  position: 'relative',
  borderRadius: theme.shape.borderRadius,
  backgroundColor: alpha(theme.palette.common.white, 0.15),
  '&:hover': {
    backgroundColor: alpha(theme.palette.common.white, 0.25),
  },
  marginRight: theme.spacing(2),
  marginLeft: 0,
  width: '100%',
  [theme.breakpoints.up('sm')]: {
    marginLeft: theme.spacing(3),
    width: 'auto',
  },
}))

const stylesCustom = {
  icons: {
    fontSize: 24,
  },
}

function MenuTopUser(props) {
  const [anchorEl, setAnchorEl] = React.useState(null)
  const [anchorElEmpresa, setAnchorElEmpresa] = React.useState(null)
  const [mobileMoreAnchorEl, setMobileMoreAnchorEl] = React.useState(null)
  const [openDrawer, setOpenDrawer] = React.useState(false)
  const [openSubMenu, setOpenSubMenu] = React.useState(false)

  const isMenuOpen = Boolean(anchorEl)
  const isMenuEmpresaOpen = Boolean(anchorElEmpresa)
  const isMobileMenuOpen = Boolean(mobileMoreAnchorEl)

  const userInfo = useContext(IPInfoContext)
  const history = useHistory()

  function handleCloseDrawer() {
    setOpenDrawer(false)
  }

  function handlerOpenDrawer() {
    setOpenDrawer(true)
  }

  const {
    nomeMenu,
    user: {
      empresas,
      usuario: { imagemLogo, nome, tipo, idUsuario, idLogLogin },
    },
    mobilePowerbi,
    setMobilePowerBi,
  } = props

  function logDeslogar() {
    let newObj = {
      idUsuario: idUsuario,
      idLogLogin: idLogLogin, //retornado no login do usuario
      tipo: 'DESLOGOU',
      ip: userInfo.ip,
      localizacao: userInfo.city + ' - ' + userInfo.country_name,
      aparelho: '',
      navegador: '',
    }
    api
      .post('/usuario/log', newObj)
      .then(({ data }) => {
        if (data.status) {
          console.log(data.mensagem)
        } else {
          console.log(data.mensagem)
        }
      })
      .catch((error) => {
        console.log('Houve um problema ao enviar os logs do usuário!')
      })
  }

  function logout() {
    logDeslogar()
    setAnchorEl(null)
    handleMobileMenuClose()
    props.getEmpresa({}, '', '')
    props.logar(false, props.user)
    sessionStorage.removeItem('auth')
    sessionStorage.removeItem('empresa')
    sessionStorage.removeItem('powerbi')
    history.push('/login')
  }

  function rotaPadraoSemMenuBI(rota, nome) {
    props.setPowerbi(false, {
      accessToken: '',
      powerBiGroupId: '',
      powerBiReportId: '',
      nomeMenu: nome,
    })
    history.push(rota)
  }

  const refPowerBi = () => {
    let report = window.report
    return report
  }

  var zoom_original = 0

  function zoom_diminui() {
    window.report.getZoom().then((value) => {
      if (zoom_original == 0) {
        zoom_original = value
      }
      var zoom_atual = value
      zoom_atual -= zoom_atual * 0.1

      window.report.setZoom(zoom_atual)
    })
  }

  function zoom_aumenta() {
    window.report.getZoom().then((value) => {
      if (zoom_original == 0) {
        zoom_original = value
      }
      var zoom_atual = value
      zoom_atual += zoom_atual * 0.1

      window.report.setZoom(zoom_atual)
    })
  }

  async function handlerClearFilter() {
    await refPowerBi().updateFilters(models.FiltersOperations.RemoveAll)
  }

  const handleProfileMenuOpen = (event) => {
    setAnchorEl(event.currentTarget)
  }

  const handleMobileMenuClose = () => {
    setMobileMoreAnchorEl(null)
  }

  const handleMenuClose = () => {
    setAnchorEl(null)
    setAnchorElEmpresa(null)
    handleMobileMenuClose()
  }

  function handlerSetPowerBi(rota, menu, powerbi) {
    props.setPowerbi(menu, powerbi)
    history.push(rota)
    //refPowerBi().reload();
  }

  const handleMenuCloseEmpresa = (empresa) => {
    //this.setState({ empresaSelecionada: empresa.fantasia });
    props.getEmpresa(empresa.relatorios, empresa.imagemLogo, empresa.fantasia)

    let primeiroMenu = empresa.relatorios[0]

    handlerSetPowerBi(
      primeiroMenu.descricao === 'Dashboard'
        ? `/empresas/${primeiroMenu.powerBiReportId}`
        : '/empresas',
      false,
      {
        accessToken: props.accessToken,
        powerBiGroupId: primeiroMenu.powerBiGroupId,
        powerBiReportId: primeiroMenu.powerBiReportId,
        nomeMenu:
          primeiroMenu.descricao === 'Dashboard'
            ? primeiroMenu.descricao
            : 'Empresas',
      },
    )

    setAnchorElEmpresa(null)
  }
  const handleMenuOpenEmpresa = (event) => {
    setAnchorElEmpresa(event.currentTarget)
  }

  const handleMobileMenuOpen = (event) => {
    setMobileMoreAnchorEl(event.currentTarget)
  }

  const menuId = 'primary-search-account-menu'
  const menuEmpresaId = 'primary-empresa-account-menu'
  const renderMenu = (
    <Menu
      anchorEl={anchorEl}
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      id={menuId}
      keepMounted
      transformOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      open={isMenuOpen}
      onClose={handleMenuClose}
    >
      <MenuItem onClick={handleMenuClose}>{nome}</MenuItem>
      <MenuItem onClick={() => rotaPadraoSemMenuBI('/perfil', 'Minha Conta')}>
        Minha Conta
      </MenuItem>
      <MenuItem onClick={logout}>Sair</MenuItem>
    </Menu>
  )

  const renderMenuEmpresas = (
    <Menu
      anchorEl={anchorElEmpresa}
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      id={menuEmpresaId}
      keepMounted
      transformOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      open={isMenuEmpresaOpen}
      onClose={handleMenuClose}
    >
      <MenuItem>
        <Typography
          variant="h6"
          component="h6"
          marginBottom={2}
          sx={{ fontWeight: 'bolder' }}
        >
          Selecione uma Empresa
        </Typography>
      </MenuItem>
      {empresas &&
        empresas.map((empresa) => {
          if (empresa.imagemLogo === props.empresaLogo) {
            return
          }
          return (
            <MenuItem onClick={() => handleMenuCloseEmpresa(empresa)}>
              <Avatar
                alt={empresa.fantasia}
                src={empresa.imagemLogo}
                sx={{ width: 35, height: 35, marginRight: '10px' }}
                className="border avatar-card"
              />
              {empresa.fantasia}
            </MenuItem>
          )
        })}
    </Menu>
  )

  const mobileMenuId = 'primary-search-account-menu-mobile'
  const renderMobileMenu = (
    <Menu
      anchorEl={mobileMoreAnchorEl}
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      sx={stylesCustom.icons}
      id={mobileMenuId}
      keepMounted
      transformOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      open={isMobileMenuOpen}
      onClose={handleMobileMenuClose}
    >
      {props.menuPowerbi && (
        <>
          <MenuItem onClick={() => refPowerBi().reload()}>
            <IconButton
              size="large"
              aria-label="Recarregar"
              title="Recarregar"
              color="inherit"
            >
              <Badge color="error">
                <CachedIcon sx={stylesCustom.icons} />
              </Badge>
            </IconButton>
            <p>Recarregar</p>
          </MenuItem>

          <MenuItem onClick={() => handlerClearFilter()}>
            <IconButton
              size="large"
              aria-label="Atualizar"
              title="Atualizar"
              color="inherit"
            >
              <Badge color="error">
                <RestoreIcon sx={stylesCustom.icons} />
              </Badge>
            </IconButton>
            <p>Atualizar</p>
          </MenuItem>
        </>
      )}
      <MenuItem onClick={handleProfileMenuOpen}>
        <IconButton
          size="large"
          aria-label="account of current user"
          aria-controls="primary-search-account-menu"
          aria-haspopup="true"
          color="inherit"
        >
          <Avatar
            alt="Avatar"
            src={imagemLogo}
            sx={{ width: 35, height: 35 }}
          />
        </IconButton>
        <p>Perfil</p>
      </MenuItem>
    </Menu>
  )

  return (
    <Box sx={{ flexGrow: 1, width: '100%' }}>
      <AppBar
        position="fixed"
        color="inherit"
        sx={{ zIndex: (theme) => theme.zIndex.drawer + 1 }}
      >
        <Toolbar>
          <IconButton
            size="large"
            edge="start"
            color="inherit"
            aria-label="open drawer"
            sx={{ mr: 2 }}
            onClick={() => {
              if (openDrawer) {
                setOpenSubMenu(false)
              }
              setOpenDrawer(!openDrawer)
            }}
          >
            <MenuIcon />
          </IconButton>
          {props.empresaLogo && (
            <MenuItem onClick={handleMenuOpenEmpresa}>
              <Box
                sx={{
                  display: { xs: 'none', sm: 'flex' },
                  width: 90,
                  height: 50,
                  alignItems: 'center',
                }}
              >
                <img
                  className="responsive"
                  src={props.empresaLogo}
                  alt={'Logo Empresa'}
                />
              </Box>
            </MenuItem>
          )}
          <Search>
            {/* <SearchIconWrapper>
              <SearchIcon />
            </SearchIconWrapper>
            <StyledInputBase
              placeholder="Search…"
              inputProps={{ 'aria-label': 'search' }}
            /> */}
          </Search>
          <Box
            sx={{
              flexGrow: 1,
              display: { md: 'flex', sm: 'flex', xs: 'flex !important' },
              justifyContent: { md: 'center', sm: 'flex-end', xs: 'end' },
              minWidth: '250px',
              fontSize: { xs: '1.0rem' },
              alignItems: 'center',
              flexDirection: 'row',
            }}
          >
            {nomeMenu && nomeMenu}

            <Box
              sx={{ display: { xs: 'flex', md: 'none', marginLeft: '10px' } }}
            >
              {props.menuPowerbi && (
                <>
                  <IconButton
                    size="large"
                    aria-label="mobile powerbi"
                    title={mobilePowerbi ? 'Versão Desktop' : 'Versão Mobile'}
                    color="inherit"
                    onClick={() => {
                      setMobilePowerBi(!mobilePowerbi)
                    }}
                  >
                    <Badge color="error">
                      {mobilePowerbi ? (
                        <Tv sx={stylesCustom.icons} />
                      ) : (
                        <StayCurrentPortrait sx={stylesCustom.icons} />
                      )}
                    </Badge>
                  </IconButton>{' '}
                  <IconButton
                    size="large"
                    aria-label="Tela Cheia"
                    title="Tela Cheia"
                    color="inherit"
                    onClick={() => refPowerBi().fullscreen()}
                  >
                    <Badge color="error">
                      <FullscreenExitIcon sx={stylesCustom.icons} />
                    </Badge>
                  </IconButton>{' '}
                </>
              )}
            </Box>
          </Box>
          <Box sx={{ display: { xs: 'none', md: 'flex' } }}>
            {props.menuPowerbi && (
              <>
                <IconButton
                  size="large"
                  aria-label="mobile powerbi"
                  title={mobilePowerbi ? 'Versão Desktop' : 'Versão Mobile'}
                  color="inherit"
                  onClick={() => {
                    setMobilePowerBi(!mobilePowerbi)
                  }}
                >
                  <Badge color="error">
                    {mobilePowerbi ? (
                      <Tv sx={stylesCustom.icons} />
                    ) : (
                      <StayCurrentPortrait sx={stylesCustom.icons} />
                    )}
                  </Badge>
                </IconButton>
                <IconButton
                  size="large"
                  aria-label="aumentar"
                  title={'Aumentar'}
                  color="inherit"
                  onClick={() => {
                    zoom_aumenta()
                  }}
                >
                  <Badge color="error">
                    <Add sx={stylesCustom.icons} />
                  </Badge>
                </IconButton>
                <IconButton
                  size="large"
                  aria-label="menos"
                  title={'Diminuir'}
                  color="inherit"
                  onClick={() => {
                    zoom_diminui()
                  }}
                >
                  <Badge color="error">
                    <Remove sx={stylesCustom.icons} />
                  </Badge>
                </IconButton>
                <IconButton
                  size="large"
                  aria-label="Tela Cheia"
                  title="Tela Cheia"
                  color="inherit"
                  onClick={() => refPowerBi().fullscreen()}
                >
                  <Badge color="error">
                    <FullscreenExitIcon sx={stylesCustom.icons} />
                  </Badge>
                </IconButton>
                <IconButton
                  size="large"
                  aria-label="Recarregar"
                  title="Recarregar"
                  color="inherit"
                  onClick={() => refPowerBi().reload()}
                >
                  <Badge color="error">
                    <CachedIcon sx={stylesCustom.icons} />
                  </Badge>
                </IconButton>
                <IconButton
                  size="large"
                  aria-label="Atualizar"
                  title="Atualizar"
                  color="inherit"
                  onClick={() => handlerClearFilter()}
                >
                  <Badge color="error">
                    <RestoreIcon sx={stylesCustom.icons} />
                  </Badge>
                </IconButton>
              </>
            )}
            <IconButton
              size="large"
              edge="end"
              aria-label="account of current user"
              aria-controls={menuId}
              aria-haspopup="true"
              onClick={handleProfileMenuOpen}
              color="inherit"
            >
              <Avatar
                alt="Avatar"
                src={imagemLogo}
                sx={{ width: 45, height: 45 }}
              />
            </IconButton>
          </Box>
          <Box sx={{ display: { xs: 'flex', md: 'none' } }}>
            <IconButton
              size="large"
              aria-label="show more"
              aria-controls={mobileMenuId}
              aria-haspopup="true"
              onClick={handleMobileMenuOpen}
              color="inherit"
            >
              <MoreIcon />
            </IconButton>
          </Box>
        </Toolbar>
      </AppBar>
      {renderMobileMenu}
      {renderMenu}
      {Utils.isArrayLength(empresas) && renderMenuEmpresas}
      <SubMenusEmpresa
        tipo={tipo}
        open={openDrawer}
        handlerClose={handleCloseDrawer}
        handleOpen={handlerOpenDrawer}
        openSubMenu={openSubMenu}
        setOpenSubMenu={setOpenSubMenu}
      />
    </Box>
  )
}

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    { logar, getEmpresa, setPowerbi, setMobilePowerBi },
    dispatch,
  )

const mapStateToProps = (store) => ({
  loggedIn: store.loginState.loggedIn,
  empresaLogo: store.empresaReducer.imagemLogo,
  empresaFantasia: store.empresaReducer.fantasia,
  user: store.loginState.user,
  powerbi: store.powerbiReducer.powerbi,
  menuPowerbi: store.powerbiReducer.menu,
  nomeMenu: store.powerbiReducer.powerbi.nomeMenu,
  accessToken: store.powerbiReducer.powerbi.accessToken,
  mobilePowerbi: store.powerbiReducer.mobile,
})

export default connect(mapStateToProps, mapDispatchToProps)(MenuTopUser)
