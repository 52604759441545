import axios from 'axios'

//em producao
const BASE_URL = `https://${window.location.hostname}:443/powerbi/v1/`

//em dev
//const BASE_URL = `http://${window.location.hostname}:8590/powerbi/v1/`;
//const BASE_URL = 'https://bi-hom.winthor.app/powerbi/v1/'

let basicAuthorization = 'Basic ' + btoa('powerbi:powerbi')

let CONFIG = {
  baseURL: BASE_URL,
  headers: {
    'Content-Type': 'application/json',
    Authorization: basicAuthorization,
  },
}

const api = axios.create(CONFIG)

// api.interceptors.request.use(async (config) => {
//   const token = getToken()
//   if (token) {
//     config.headers.Authorization = `Bearer ${token}`
//   }
//   return config
// })

export default api
