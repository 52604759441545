import React from 'react'
import {
  Box,
  Button,
  CircularProgress,
  Grid,
  IconButton,
  InputAdornment,
  Stack,
  Typography,
} from '@mui/material'
import {
  ColorPicker,
  Input,
  InputSelect,
  ModalConfirmar,
  RadioButtonsGroup,
  Titulo,
} from '../../components'
import { Lock, Visibility, VisibilityOff } from '@mui/icons-material'
import { Utils } from '../../utilities'
import { toast } from 'react-toastify'
import CryptoJS from 'crypto-js'
import * as S from './styles'
import UserService from '../../service/UserService'
import ImageUploader from '../../components/ImageUploader/ImageUploader'

export default function EditarUsuario({
  tipos = [],
  empresas = [],
  infoUsuario = {},
  editar = false,
  idUsuarioAtualizacao = '',
  closeDialog,
  buscarMobioh,
}) {
  const [openModal, setOpenModal] = React.useState(false)
  const handleOpenModal = () => setOpenModal(true)
  const handleCloseModal = () => setOpenModal(false)

  const [state, setState] = React.useState({
    idUsuario: '',
    nome: '',
    login: '',
    tipo: '',
    tipoMenu: 'HORIZONTAL',
    ativo: true,
    utilizaPersonalizacaoEmpresa: false,
    corPrimaria: null,
    corTerciaria: null,
    corSecundaria: null,
    imagemLogo: null,
    ...infoUsuario,
    senha: null,
  })

  const optionsStatus = [
    { value: true, label: 'Ativo' },
    { value: false, label: 'Inativo' },
  ]

  const optionsPersonalizarEmpresa = [
    { value: true, label: 'Ativo' },
    { value: false, label: 'Inativo' },
  ]

  const [tipoMenu, setTipoMenu] = React.useState([
    {
      tipoMenu: 'HORIZONTAL',
      descricao: 'Horizontal',
    },
    {
      tipoMenu: 'VERTICAL',
      descricao: 'Vertical',
    },
  ])

  const [confirmarSenha, setConfirmarSenha] = React.useState(null)
  const [showPassword, setShowPassword] = React.useState(false)
  const [loading, setLoading] = React.useState(false)

  function validacao(value) {
    return modificado ? !Utils.isNotNull(value) : false
  }

  const [modificado, setModificado] = React.useState(false)
  const alignmentGrid = { margin: '0  0px 20px' }

  function setColor(colorName, color) {
    setState((state) => ({
      ...state,
      [colorName]: color,
    }))
  }

  function setImage(base64) {
    setState((state) => ({
      ...state,
      imagemLogo: base64,
    }))
  }

  function cadastrarEditarUsuario() {
    setLoading(true)
    setModificado(true)

    if (
      !Utils.isNotNull(state.nome) ||
      !Utils.isNotNull(state.tipo) ||
      !Utils.isNotNull(state.login)
    ) {
      toast.error('Preencha as informações obrigatórias!', {
        theme: 'colored',
      })
      setLoading(false)
      return
    }

    // if (!Utils.isNotNull(state.senha)) {
    //   toast.error('Preencha a senha!', {
    //     theme: 'colored',
    //   })
    //   setLoading(false)
    //   return
    // }

    if (state.senha !== confirmarSenha) {
      toast.error('Confirmar senha deve ser igual ao campo senha!', {
        theme: 'colored',
      })
      setLoading(false)
      return
    }

    let senha = (state.senha != null) ? CryptoJS.MD5(state.senha).toString() : null;

    if (!editar) {
      UserService.criarUsuario({
        nome: state.nome,
        login: state.login,
        senha: senha,
        tipo: state.tipo,
        tipoMenu: state.tipoMenu,
        idUsuarioAtualizacao: idUsuarioAtualizacao,
      })
        .then(({ status, mensagem }) => {
          setLoading(false)
          if (status) {
            toast.success(mensagem, {
              theme: 'colored',
            })
            closeDialog()
            buscarMobioh()
          } else {
            toast.error(mensagem, {
              theme: 'colored',
            })
          }
        })
        .catch((error) => {
          setLoading(false)
          toast.error('Houve um problema na criação do Usuário!', {
            theme: 'colored',
          })
        })
    } else {
      UserService.atualizarUsuarioPerfil({
        idUsuario: state.idUsuario,
        login: state.login,
        nome: state.nome,
        senha: senha,
        tipo: state.tipo,
        tipoMenu: state.tipoMenu,
        idUsuarioAtualizacao: idUsuarioAtualizacao,
        ativo: state.ativo,
        corPrimaria: state.corPrimaria,
        corSecundaria: state.corSecundaria,
        corTerciaria: state.corTerciaria,
        imagemLogo: state.imagemLogo,
        utilizaPersonalizacaoEmpresa: state.utilizaPersonalizacaoEmpresa,
      })
        .then(({ status, mensagem }) => {
          setLoading(false)
          if (status) {
            toast.success(mensagem, {
              theme: 'colored',
            })
            closeDialog()
            buscarMobioh()
          } else {
            toast.error(mensagem, {
              theme: 'colored',
            })
          }
        })
        .catch((error) => {
          setLoading(false)
          toast.error('Houve um problema na edição do Usuário!', {
            theme: 'colored',
          })
        })
    }

    handleCloseModal()
  }

  return (
    <Box padding={3}>
      <ModalConfirmar
        open={openModal}
        handleConfirm={cadastrarEditarUsuario}
        handleClose={handleCloseModal}
        customStyle={{ width: 400 }}
      >
        <S.TitleModal>Salvar as informações do Usuário?</S.TitleModal>
      </ModalConfirmar>
      <Grid container padding={1} spacing={2}>
        <Grid xs={12} md={12} item>
          <Input
            label="Nome"
            id={'nome'}
            error={validacao(state.nome)}
            helperText={
              validacao(state.nome) ? 'Essa informação é obrigatória' : ''
            }
            value={state.nome}
            onChange={(e) => {
              setState((state) => ({
                ...state,
                nome: e.target.value,
              }))
            }}
            placeholder="Escreva o Nome do Usuário"
            //    size="small"
          />
        </Grid>

        <Grid xs={12} md={8} item>
          <Input
            label="login"
            id={'login'}
            error={validacao(state.login)}
            helperText={
              validacao(state.login) ? 'Essa informação é obrigatória' : ''
            }
            value={state.login}
            onChange={(e) => {
              setState((state) => ({
                ...state,
                login: e.target.value,
              }))
            }}
            placeholder="Escreva um Login para o Usuário"
            //     size="small"
          />
        </Grid>

        <Grid xs={12} md={4} item>
          <InputSelect
            value={state.tipo}
            setValue={(value) => {
              setState((state) => ({
                ...state,
                tipo: value,
              }))
            }}
            error={validacao(state.tipo)}
            helperText={
              validacao(state.tipo) ? 'Essa informação é obrigatória' : ''
            }
            options={tipos}
            label="Tipo de Usuário"
            id="tipo"
            descricao="descricao"
            //    size="small"
            sx={{ padding: '5px 0px 0px', marginTop: '-5px' }}
          />
        </Grid>

        <Grid xs={12} md={4} item>
          <InputSelect
            value={state.tipoMenu}
            setValue={(value) => {
              setState((state) => ({
                ...state,
                tipoMenu: value,
              }))
            }}
            error={validacao(state.tipoMenu)}
            helperText={
              validacao(state.tipoMenu) ? 'Essa informação é obrigatória' : ''
            }
            options={tipoMenu}
            label="Tipo de Menu"
            id="tipoMenu"
            descricao="descricao"
            //    size="small"
            sx={{ padding: '5px 0px 0px', marginTop: '-5px' }}
          />
        </Grid>

        <Grid xs={12} md={4} item sx={alignmentGrid}>
          <Input
            //    size="small"
            label="Senha"
            type={showPassword ? 'text' : 'password'}
            id={'password'}
            // error={validacao(state.senha)}
            // helperText={
            //   validacao(state.senha) ? 'Essa informação é obrigatória' : ''
            // }
            value={state.senha}
            onChange={(e) => {
              setState((state) => {
                return { ...state, senha: e.target.value }
              })
            }}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <Lock fontSize="small" />
                </InputAdornment>
              ),

              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={() => setShowPassword(!showPassword)}
                    edge="end"
                  >
                    {showPassword ? (
                      <VisibilityOff fontSize="small" />
                    ) : (
                      <Visibility fontSize="small" />
                    )}
                  </IconButton>
                </InputAdornment>
              ),
            }}
            placeholder="Senha"
          />
        </Grid>

        <Grid xs={12} md={4} item sx={alignmentGrid}>
          <Input
            //  size="small"
            label="Confirmar Senha"
            type={showPassword ? 'text' : 'password'}
            id={'confirmarSenha'}
            error={confirmarSenha !== state.senha}
            helperText={
              confirmarSenha !== state.senha ? 'Os campos devem ser iguais' : ''
            }
            value={confirmarSenha}
            onChange={(e) => {
              setConfirmarSenha(e.target.value)
            }}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <Lock fontSize="small" />
                </InputAdornment>
              ),

              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={() => setShowPassword(!showPassword)}
                    edge="end"
                  >
                    {showPassword ? (
                      <VisibilityOff fontSize="small" />
                    ) : (
                      <Visibility fontSize="small" />
                    )}
                  </IconButton>
                </InputAdornment>
              ),
            }}
            placeholder="Confirmar Senha"
          />
        </Grid>

        {editar && (
          <Grid item xs={4}>
            <RadioButtonsGroup
              label="Utilizar Personalização da Empresa"
              options={optionsPersonalizarEmpresa}
              labelOptions="label"
              valueOptions="value"
              defaultValue={state.utilizaPersonalizacaoEmpresa}
              onChange={(e) => {
                setModificado(true)
                setState((state) => ({
                  ...state,
                  utilizaPersonalizacaoEmpresa: e.target.value === 'true',
                }))
              }}
            />
          </Grid>
        )}

        {editar && (
          <Grid item xs={4}>
            <RadioButtonsGroup
              label="Status"
              options={optionsStatus}
              labelOptions="label"
              valueOptions="value"
              defaultValue={state.ativo}
              onChange={(e) => {
                setModificado(true)
                setState((state) => ({
                  ...state,
                  ativo: e.target.value === 'true',
                }))
              }}
            />
          </Grid>
        )}
      </Grid>

      {!state.utilizaPersonalizacaoEmpresa && (
        <>
          <Titulo>Personalizar Cores</Titulo>
          <S.ContainerForm>
            <Grid container spacing={2}>
              <Grid xs={12} md={3} item>
                <ColorPicker
                  setColor={(color) => setColor('corPrimaria', color)}
                  color={state.corPrimaria}
                />
                <Typography
                  variant="h6"
                  component="span"
                  sx={{
                    fontSize: '16px !important',
                    fontWeight: 'bolder',
                    marginBottom: 1,
                    color: '#131022 !important',
                  }}
                >
                  Primária
                </Typography>
              </Grid>

              <Grid xs={12} md={3} item>
                <ColorPicker
                  setColor={(color) => setColor('corSecundaria', color)}
                  color={state.corSecundaria}
                />
                <Typography
                  variant="h6"
                  component="span"
                  sx={{
                    fontSize: '16px !important',
                    fontWeight: 'bolder',
                    marginBottom: 1,
                    color: '#131022 !important',
                  }}
                >
                  Secundária
                </Typography>
              </Grid>

              <Grid xs={12} md={3} item>
                <ColorPicker
                  setColor={(color) => setColor('corTerciaria', color)}
                  color={state.corTerciaria}
                />
                <Typography
                  variant="h6"
                  component="span"
                  sx={{
                    fontSize: '16px !important',
                    fontWeight: 'bolder',
                    marginBottom: 1,
                    color: '#131022 !important',
                  }}
                >
                  Terciária
                </Typography>
              </Grid>
            </Grid>
          </S.ContainerForm>
        </>
      )}

      <Titulo>Imagem do Perfil</Titulo>
      <S.ContainerForm>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <ImageUploader
              isConfirm={false}
              setImage={setImage}
              img={state.imagemLogo}
            />
          </Grid>
        </Grid>
      </S.ContainerForm>

      <Stack direction="row" spacing={2} justifyContent="end">
        <Button
          className="confirm-button"
          variant="contained"
          size="large"
          type="submit"
          onClick={handleOpenModal}
          startIcon={loading && <CircularProgress color="inherit" size={25} />}
          disabled={loading}
        >
          Salvar
        </Button>
      </Stack>
    </Box>
  )
}
