import React, { useState } from 'react'
import * as S from './styles'
import { Button, CircularProgress, Grid, Stack, Tooltip } from '@mui/material'
import {
  FullScreenDialog,
  Input,
  InputSelect,
  ModalConfirmar,
  TableComponet,
  Titulo,
} from '../../components'
import {
  AddCircleOutlineOutlined,
  FilterAltOff,
  GroupAddOutlined,
  SearchOutlined,
} from '@mui/icons-material'
import { api, Utils } from '../../utilities'
import UserService from '../../service/UserService'
import { toast } from 'react-toastify'
import { connect } from 'react-redux'

function SincronizarUsuarios({ empresa, ...props }) {
  const [open, setOpen] = React.useState(false)
  const handleOpen = () => setOpen(true)
  const handleClose = () => setOpen(false)

  const [openModal, setOpenModal] = React.useState(false)
  const handleOpenModal = () => setOpenModal(true)
  const handleCloseModal = () => {
    setOpenModal(false)
    setUsuarioSelecionado({
      nome: null,
      ativoEmpresa: false,
    })
  }

  const [empresas, setEmpresas] = useState([])
  const [tipos, setTipos] = useState([])
  const [totalRegistros, setTotalRegistros] = useState(0)
  const [usuariosCadastrados, setUsuariosCadastrados] = useState([])
  const [usuarios, setUsuarios] = useState([])
  const [selected, setSelected] = useState([])
  const [loading, setLoading] = useState(false)
  const [usuarioSelecionado, setUsuarioSelecionado] = useState({
    nome: null,
    ativoEmpresa: false,
  })

  const [stateUsuarioProducao, setStateUsuarioProducao] = React.useState({
    descricao: null,
    idEmpresa: null,
    tipo: null,
  })

  const headersUsuarios = [
    {
      id: 'nome',
      label: 'Nome',
      props: {
        align: 'left',
      },
    },
    {
      id: 'login',
      label: 'Login',
      props: {
        align: 'left',
      },
    },
    {
      id: 'tipoDescricao',
      label: 'Tipo',
      props: {
        align: 'left',
      },
    },
    {
      id: 'statusRows',
      label: 'Situação',
      props: {
        align: 'right',
      },
    },
    {
      id: 'actionRows',
      label: 'Ativar/Desativar',
      props: {
        align: 'right',
      },
    },
  ]

  const headersUsuariosSemCadastro = [
    {
      id: 'nome',
      label: 'Nome',
      props: {
        align: 'left',
      },
    },
    {
      id: 'login',
      label: 'Login',
      props: {
        align: 'left',
      },
    },
    {
      id: 'tipoDescricao',
      label: 'Tipo',
      props: {
        align: 'left',
      },
    },
    {
      id: 'statusRows',
      label: 'Situação',
      props: {
        align: 'right',
      },
    },
  ]

  let mounted = true
  React.useEffect(() => {
    async function getFiltros() {
      if (mounted) {
        setLoading(true)
        api.post('/usuario/admin/mobioh/dados').then(({ data }) => {
          if (Utils.isNotObjectEmpty(data)) {
            setEmpresas(data.empresas)
            setTipos(data.tipos)
            setUsuarios(data.usuarios)
            setTotalRegistros(data.totalRegistros)
            setLoading(false)
          }
        })
      }
    }

    getFiltros()
    return () => (mounted = false)
  }, [])

  React.useEffect(() => {
    if (empresa) {
      api
        .get(`/empresa/admin/mobioh/completa/${empresa.idEmpresa}`)
        .then(({ data }) => {
          if (Utils.isNotObjectEmpty(data)) {
            if (Utils.isNotObjectEmpty(data.usuarios)) {
              setUsuariosCadastrados(data.usuarios)
            }
            setLoading(false)
          }
        })
    }
  }, [empresa])

  React.useEffect(() => {
    if (usuarioSelecionado.nome) {
      handleOpenModal()
    }
  }, [usuarioSelecionado])

  function buscarUsuariosCadastrados() {
    setLoading(true)
    if (empresa) {
      api
        .get(`/empresa/admin/mobioh/completa/${empresa.idEmpresa}`)
        .then(({ data }) => {
          if (Utils.isNotObjectEmpty(data)) {
            if (Utils.isNotObjectEmpty(data.usuarios)) {
              setUsuariosCadastrados(data.usuarios)
            }
            setLoading(false)
          }
        })
    }
  }

  function ButtonAction() {
    return (
      <Stack direction="row" xs={12} spacing={2} justifyContent="end">
        <Button
          className="confirm-button"
          variant="contained"
          size="small"
          sx={{ height: '48px', minWidth: '300px', textTransform: 'none' }}
          disabled={loading}
          endIcon={
            loading ? (
              <CircularProgress color="inherit" size={25} />
            ) : (
              <GroupAddOutlined />
            )
          }
          onClick={sincronizarUsuariosSelecionados}
        >
          Vincular Usuários Selecionados
        </Button>
      </Stack>
    )
  }

  function closeDialog() {
    handleClose()
  }

  function buscarMobioh() {
    setLoading(true)
    setSelected([])

    UserService.buscarMobioh(stateUsuarioProducao)
      .then((data) => {
        setLoading(false)
        if (Utils.isArrayNotEmpty(data)) {
          setUsuarios(data)
        } else {
          setUsuarios([])
        }
      })
      .catch((error) => {
        setLoading(false)
        toast.error('Houve um problema ao inativar o Usuário!', {
          theme: 'colored',
        })
      })
  }

  function sincronizarUsuariosSelecionados() {
    setLoading(true)
    let newSincronizar = []

    selected.forEach((item) => {
      newSincronizar.push({
        idUsuario: item.idUsuario,
        idEmpresa: empresa.idEmpresa,
        ativo: true,
        idUsuarioAtualizacao: props.user.idUsuario,
      })
    })

    UserService.sincronizarEmpresa(newSincronizar)
      .then(({ status, mensagem }) => {
        setLoading(false)
        if (status) {
          toast.success(mensagem, {
            theme: 'colored',
          })
          closeDialog()
          buscarUsuariosCadastrados()
        } else {
          toast.error(mensagem, {
            theme: 'colored',
          })
        }
      })
      .catch((error) => {
        setLoading(false)
        toast.error('Houve um problema ao sincronizar os Usuário!', {
          theme: 'colored',
        })
      })
  }

  function desativarUsuario(data) {
    setLoading(true)
    let newUser = [
      {
        idUsuario: data.idUsuario,
        idEmpresa: empresa.idEmpresa,
        ativoEmpresa: !data.ativoEmpresa,
        idUsuarioAtualizacao: props.user.idUsuario,
      },
    ]

    UserService.sincronizarEmpresa(newUser)
      .then(({ status, mensagem }) => {
        setLoading(false)
        if (status) {
          toast.success(mensagem, {
            theme: 'colored',
          })
          closeDialog()
          buscarUsuariosCadastrados()
        } else {
          toast.error(mensagem, {
            theme: 'colored',
          })
        }
      })
      .catch((error) => {
        setLoading(false)
        toast.error('Houve um problema ao sincronizar os Usuário!', {
          theme: 'colored',
        })
      })

    handleCloseModal()
  }

  // FUNCOES DE SELECAO PARA TABLE

  const handleSelectAllClick = (event, name) => {
    if (event.target.checked) {
      const newSelected = usuarios.map((n) => {
        n.idUsuarioAtualizacao = props.user.idUsuario
        return n
      })
      setSelected(newSelected)
      return
    }
    setSelected([])
  }

  const handleClickSelected = (event, usuario) => {
    const selectedIndex = selected.findIndex(
      (selectedUsuario) => selectedUsuario.login === usuario.login,
    )
    let newSelected = []

    if (selectedIndex === -1) {
      usuario.idUsuarioAtualizacao = props.user.idUsuario
      newSelected = [...selected, usuario]
    } else {
      newSelected = selected.filter(
        (selectedUsuario) => selectedUsuario.login !== usuario.login,
      )
    }
    setSelected(newSelected)
  }

  return (
    <S.Container>
      <ModalConfirmar
        open={openModal}
        handleConfirm={() => desativarUsuario(usuarioSelecionado)}
        handleClose={handleCloseModal}
        customStyle={{ width: 400 }}
      >
        <S.TitleModal>
          {usuarioSelecionado.ativoEmpresa
            ? 'Deseja DESATIVAR o usuário a essa empresa?'
            : 'Deseja ATIVAR o usuário a essa empresa?'}
        </S.TitleModal>
      </ModalConfirmar>
      <Titulo>
        <div>Usuários</div>
        <Stack
          direction="row"
          spacing={2}
          justifyContent="end"
          marginTop="-26px"
        >
          <Button
            className="confirm-button"
            variant="contained"
            size="medium"
            endIcon={<AddCircleOutlineOutlined />}
            //sx={{ height: '43px' }}
            onClick={handleOpen}
          >
            Vincular Usuários
          </Button>
        </Stack>
      </Titulo>
      <S.ContainerForm>
        <Grid container padding={1}>
          <Grid xs={12}>
            <TableComponet
              headers={headersUsuarios}
              data={usuariosCadastrados}
              labelCaption="Nenhum Usuário Encontrado"
              labelTable="Lista de Usuários"
              status="ativoEmpresa"
              statusLabelTrue="Ativo"
              statusLabelFalse="Inativo"
              loading={loading}
              handlerDeletarAction={(data) => {
                setUsuarioSelecionado(data)
              }}
            />
          </Grid>
        </Grid>
      </S.ContainerForm>

      <FullScreenDialog
        label={'Usuários Disponíveis'}
        open={open}
        handleClose={closeDialog}
      >
        <S.Container>
          <Grid
            container
            padding={1}
            marginTop={2}
            spacing={2}
            marginBottom={1}
          >
            <Grid xs={5} item>
              <Input
                label="Pesquisar"
                id={'descricao'}
                value={stateUsuarioProducao.descricao}
                onChange={(e) => {
                  setStateUsuarioProducao((state) => ({
                    ...state,
                    descricao: e.target.value,
                  }))
                }}
                placeholder="Escreva o Nome ou Login do Usuário"
                size="small"
              />
            </Grid>

            <Grid xs={2} item>
              <InputSelect
                value={stateUsuarioProducao.tipo}
                setValue={(value) => {
                  setStateUsuarioProducao((state) => ({
                    ...state,
                    tipo: value,
                  }))
                }}
                options={tipos}
                label="Tipo de Usuário"
                id="tipo"
                descricao="descricao"
                size="small"
                sx={{ padding: '5px 0px 0px', marginTop: '-5px' }}
              />
            </Grid>

            <Grid xs={2} item display={'flex'}>
              <Button
                className="confirm-button"
                variant="contained"
                size="large"
                startIcon={
                  loading ? (
                    <CircularProgress color="inherit" size={25} />
                  ) : (
                    <SearchOutlined />
                  )
                }
                disabled={loading}
                sx={{ height: '39px' }}
                onClick={buscarMobioh}
              >
                Buscar
              </Button>

              <Tooltip
                title={'Limpar Filtros'}
                placement="top"
                style={{ fontSize: '20px !important' }}
              >
                <Button
                  className="confirm-button"
                  variant="text"
                  size="large"
                  startIcon={
                    loading ? (
                      <CircularProgress color="inherit" size={25} />
                    ) : (
                      <FilterAltOff />
                    )
                  }
                  disabled={loading}
                  sx={{ height: '39px', color: '#C4C4C4 !important' }}
                  onClick={() =>
                    setStateUsuarioProducao((state) => ({
                      ...state,
                      descricao: '',
                      tipo: null,
                    }))
                  }
                ></Button>
              </Tooltip>
            </Grid>
          </Grid>
          <Grid container padding={1}>
            <Grid xs={12}>
              <TableComponet
                headers={headersUsuariosSemCadastro}
                data={usuarios}
                labelCaption="Nenhum Usuário Encontrado"
                labelTable="Lista de Usuários"
                status="ativo"
                statusLabelTrue="Ativo"
                statusLabelFalse="Inativo"
                loading={loading}
                selected={selected}
                selectedName="login"
                handleClickSelected={handleClickSelected}
                handleSelectAllClick={handleSelectAllClick}
                ActionSelect={ButtonAction}
              />
            </Grid>
          </Grid>
        </S.Container>
      </FullScreenDialog>
    </S.Container>
  )
}

const mapStateToProps = (store) => ({
  user: store.loginState.user.usuario,
})

export default connect(mapStateToProps)(SincronizarUsuarios)
