import React from 'react'
import TabsComponent from '../../components/TabsComponent/TabsComponent'
import * as S from './styles'
import { Grid, Stack, Button } from '@mui/material'
import { Titulo } from '../../components'
import { useLocation } from 'react-router-dom'
import SincronizarUsuarios from './SincronizarUsuarios'
import EditarEmpresa from './EditarEmpresa'
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min'
import { ArrowBack } from '@mui/icons-material'
import SincronizarRelatorios from './SincronizarRelatorios'
import { Utils, api } from '../../utilities'
import { toast } from 'react-toastify'

export default function Sincronizar() {
  const location = useLocation()
  const history = useHistory()
  const [powerBiGroupId, setPowerBiGroupId] = React.useState('')

  const {
    state: { empresa, estados, idUsuarioAtualizacao },
  } = location
  const tabsHeader = [
    {
      id: 'usuarios',
      label: 'Usuários',
    },
    {
      id: 'relatorios',
      label: 'Relatórios',
    },
    {
      id: 'info',
      label: 'Informações',
    },
  ]

  const tabsPanel = [
    <SincronizarUsuarios
      empresa={empresa}
      idUsuarioAtualizacao={idUsuarioAtualizacao}
    />,
    <SincronizarRelatorios
      empresa={empresa}
      idUsuarioAtualizacao={idUsuarioAtualizacao}
      powerBiGroupId={powerBiGroupId}
    />,
    <EditarEmpresa
      estados={estados}
      idUsuarioAtualizacao={idUsuarioAtualizacao}
      editar={true}
      infoEmpresas={empresa}
      closeDialog={() => {}}
    />,
  ]

  let mounted = true
  React.useEffect(() => {
    async function getFiltros() {
      if (mounted) {
        if (location) {
          //   setLoading(true)
          api
            .post('/relatorio/filtro/empresa/semcadastro', {
              idEmpresa: empresa.idEmpresa,
            })
            .then(({ data }) => {
              //        setLoading(false)
              if (Utils.isNotObjectEmpty(data)) {
                console.log("dat", data)
                setPowerBiGroupId(data.powerBiGroupId)
              }
            })
            .catch((error) => {
              //       setLoading(false)
              toast.error('Houve um problema ao buscar os dados da empresa!', {
                theme: 'colored',
              })
            })
        }
      }
    }

    getFiltros()
    return () => (mounted = false)
  }, [])

  return (
    <S.Container>
      <Titulo>
        <div>{location ? empresa.razaoSocial : ''}</div>
        <Stack
          direction="row"
          spacing={2}
          justifyContent="end"
          marginTop="-26px"
        >
          <Button
            className="confirm-button"
            variant="contained"
            size="medium"
            color="warning"
            startIcon={<ArrowBack />}
            //sx={{ height: '43px' }}
            onClick={() => history.push('/admin/empresas')}
          >
            Voltar
          </Button>
        </Stack>
      </Titulo>
      <S.ContainerForm>
        <Grid container padding={1} spacing={2} marginBottom={1}>
          <Grid item xs={12}>
            <TabsComponent
              tabsHeader={tabsHeader}
              tabsPanel={tabsPanel}
              //  handleChangeTab={handlerChangeTabs}
            ></TabsComponent>
          </Grid>
        </Grid>
      </S.ContainerForm>
    </S.Container>
  )
}
