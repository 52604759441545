import { EMPRESAS } from "../actions";

const initialState = sessionStorage.getItem("empresa") ? JSON.parse(sessionStorage.getItem("empresa")) : {
  loading: false,
  imagemLogo: "",
  fantasia: "",
  empresa: {}
};

export const empresaReducer = (state = initialState, action) => {
  switch (action.type) {
    case EMPRESAS:
      sessionStorage.setItem("empresa", JSON.stringify({
        ...state,
        loading: action.loading,
        empresa: action.empresa,
        imagemLogo: action.imagemLogo,
        fantasia: action.fantasia
      }));
      return {
        ...state,
        loading: action.loading,
        empresa: action.empresa,
        imagemLogo: action.imagemLogo,
        fantasia: action.fantasia
      };
    default:
      return state;
  }
};
