import * as React from 'react'
import {
  Drawer,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Toolbar,
  Box,
  Collapse,
  Divider,
} from '@mui/material'

import { ExpandLess, ExpandMore } from '@mui/icons-material'
import { Link, withRouter } from 'react-router-dom'
import ExitToAppOutlinedIcon from '@mui/icons-material/ExitToAppOutlined'
import AccountCircleIcon from '@mui/icons-material/AccountCircle'

const drawerWidth = 270

const NewHOC = (PassedComponent) => {
  return class extends React.Component {
    render() {
      return (
        <div>
          <PassedComponent {...this.props} />
        </div>
      )
    }
  }
}

const Sidebar = (props) => {
  const { open, perfilUsuario, logout, rotaPadraoSemMenuBi } = props

  // let role = props.role

  // let {
  //   match: { path },
  // } = props

  const [isExpand, setIsExpand] = React.useState(999)

  const handleClickMenu = (index) => {
    setIsExpand((state) => (index === state ? 999 : index))
  }
  
  return (
    <Drawer
      sx={{
        width: drawerWidth,
        flexShrink: 0,
        '& .MuiDrawer-paper': {
          width: drawerWidth,
          boxSizing: 'border-box',
        },
      }}
      variant="persistent"
      anchor="left"
      open={open}
    >
      <Toolbar />
      <Box sx={{ overflow: 'auto', paddingTop: '18px' }}>
        <List>
          {perfilUsuario.map((p, index) => {
            const IconComponent = NewHOC(p.icon)
            return (
              <ListItem key={p.name} disablePadding>
                {p.route !== '#' ? (
                  <Link className="linksPrimeiro" to="#">
                    <ListItemButton
                      //  selected={path === p.route}
                      onClick={() => {
                        handleClickMenu(index)
                        if (!p.isSubmenu) {
                          p.route()
                        }
                      }}
                    >
                      <ListItemIcon className="icon-menu">
                        <IconComponent />
                      </ListItemIcon>
                      <ListItemText primary={p.name} />
                      {p.isSubmenu &&
                        (isExpand === index ? <ExpandLess /> : <ExpandMore />)}
                    </ListItemButton>
                  </Link>
                ) : (
                  <ListItemButton
                    //   selected={path === p.route}
                    onClick={() => handleClickMenu(index)}
                  >
                    <ListItemIcon className="icon-menu">
                      <IconComponent />
                    </ListItemIcon>
                    <ListItemText primary={p.name} />
                    {p.isSubmenu &&
                      (isExpand === index ? <ExpandLess /> : <ExpandMore />)}
                  </ListItemButton>
                )}

                {p.isSubmenu &&
                  p.submenu.map((menu) => {
                    return (
                      <Collapse
                        in={isExpand === index}
                        timeout="auto"
                        unmountOnExit
                        key={menu.name}
                      >
                        <List component="div" disablePadding>
                          <Link className="links" to="#">
                            <ListItemButton
                              sx={{ pl: 4 }}
                              onClick={() => menu.submenuRoute()}
                            >
                              <ListItemText primary={menu.name} />
                            </ListItemButton>
                          </Link>
                        </List>
                      </Collapse>
                    )
                  })}
              </ListItem>
            )
          })}
        </List>
        <Divider />
        <List>
          <ListItem key="perfil" disablePadding>
            <ListItemButton
              onClick={() => {
                rotaPadraoSemMenuBi('/perfil', 'Minha Conta')
              }}
            >
              <ListItemIcon>
                <AccountCircleIcon />
              </ListItemIcon>
              <ListItemText primary="Minha Conta" />
            </ListItemButton>
          </ListItem>

          <ListItem key="sair" disablePadding>
            <ListItemButton
              onClick={() => {
                logout()
                props.history.push('/login')
              }}
            >
              <ListItemIcon>
                <ExitToAppOutlinedIcon />
              </ListItemIcon>
              <ListItemText primary="Sair" />
            </ListItemButton>
          </ListItem>
        </List>
      </Box>
    </Drawer>
  )
}

export default withRouter(Sidebar)
