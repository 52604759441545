import React from 'react'
import * as S from './styles'
import { Button, CircularProgress, Grid, Stack } from '@mui/material'
import {
  FullScreenDialog,
  Input,
  InputSelect,
  TableComponet,
  Titulo,
} from '../../components'
import { useState } from 'react'
import { AddCircleOutlineOutlined, SearchOutlined } from '@mui/icons-material'
import { Utils, api } from '../../utilities'
import { toast } from 'react-toastify'
import { connect } from 'react-redux'
import CadastrarEditarPerfil from './CadastrarEditarPerfil'
import { useHistory } from 'react-router-dom'

function PerfisAdmin({ empresa, user }) {
  const [open, setOpen] = React.useState(false)
  const handleOpen = () => setOpen(true)
  const handleClose = () => setOpen(false)

  const history = useHistory()

  const [perfisCadastrados, setPerfisCadastrados] = useState([])
  const [loading, setLoading] = useState(false)
  const [editar, setEditar] = useState(false)
  const [perfilSelecionado, setPerfiSelecionado] = useState({
    idPerfil: '',
    descricao: '',
    ativo: true,
  })
  const [statePerfis, setStatePerfis] = React.useState({
    descricao: null,
    idEmpresa: null,
  })
  const [empresas, setEmpresas] = React.useState([])
  const headers = [
    {
      id: 'codigoPerfil',
      label: 'Código',
      props: {
        align: 'left',
      },
    },
    {
      id: 'descricao',
      label: 'Descrição',
      props: {
        align: 'left',
      },
    },
    {
      id: 'empresaFantasia',
      label: 'Empresa',
      props: {
        align: 'left',
      },
    },
    {
      id: 'statusRows',
      label: 'Situação',
      props: {
        align: 'right',
      },
    },
    {
      id: 'actionRows',
      label: 'Editar',
      props: {
        align: 'right',
      },
    },
  ]

  React.useEffect(() => {
    let userTipo = Utils.isNotNull(user) ? user.tipo : null

    if (userTipo === 'ADMIN_MOBIOH') {
      setLoading(true)
      api.post(`/perfil/admin/mobioh/dados`).then(({ data }) => {
        if (Utils.isNotObjectEmpty(data.perfis)) {
          setPerfisCadastrados(data.perfis)
          setEmpresas(data.empresas)
          setLoading(false)
        }
      })
    } else {
      api
        .post(`/perfil/admin/cliente/dados`, { idUsuario: user.idUsuario })
        .then(({ data }) => {
          if (Utils.isNotObjectEmpty(data.perfis)) {
            setPerfisCadastrados(data.perfis)
            setLoading(false)
          }
        })
    }
  }, [user])

  function buscarMobioh() {
    setLoading(true)

    let userTipo = Utils.isNotNull(user) ? user.tipo : null

    if (userTipo === 'ADMIN_MOBIOH') {
      api
        .post('/perfil/admin/mobioh/filtro', {
          idEmpresa: statePerfis.idEmpresa,
          descricao: statePerfis.descricao,
        })
        .then(({ data }) => {
          setLoading(false)
          setPerfisCadastrados(data)
        })
        .catch((error) => {
          setLoading(false)
          toast.error('Houve um problema ao buscar os Perfis!', {
            theme: 'colored',
          })
        })
    } else {
      api
        .post('/perfil/admin/cliente/filtro', {
          idEmpresa: statePerfis.idEmpresa,
          descricao: statePerfis.descricao,
          idUsuario: user.idUsuario,
        })
        .then(({ data }) => {
          setLoading(false)
          setPerfisCadastrados(data)
        })
        .catch((error) => {
          setLoading(false)
          toast.error('Houve um problema ao buscar os Perfis!', {
            theme: 'colored',
          })
        })
    }
  }

  function closeDialog() {
    setEditar(false)
    setPerfiSelecionado({
      idPerfil: '',
      descricao: '',
      ativo: true,
    })
    handleClose()

  }

  return (
    <S.Container>
      <Titulo>
        <div>Perfis</div>
        <Stack
          direction="row"
          spacing={2}
          justifyContent="end"
          marginTop="-26px"
        >
          <Button
            className="confirm-button"
            variant="contained"
            size="medium"
            endIcon={<AddCircleOutlineOutlined />}
            //sx={{ height: '43px' }}
            onClick={() => {
              handleOpen()
            }}
          >
            Cadastrar Perfis
          </Button>
        </Stack>
      </Titulo>
      <S.ContainerForm>
        <Grid container padding={1} spacing={2}>
          <Grid xs={5} item>
            <Input
              label="Pesquisar"
              id={'descricao'}
              value={statePerfis.descricao}
              onChange={(e) => {
                setStatePerfis((state) => ({
                  ...state,
                  descricao: e.target.value,
                }))
              }}
              placeholder="Escreva o Nome do Relatório"
              size="small"
            />
          </Grid>

          <Grid xs={12} md={2} item>
            <InputSelect
              value={statePerfis.idEmpresa}
              setValue={(value) => {
                setStatePerfis((state) => ({
                  ...state,
                  idEmpresa: value,
                }))
              }}
              options={empresas}
              label="Empresas"
              id="idEmpresa"
              descricao="fantasia"
              size="small"
              sx={{ padding: '5px 0px 0px', marginTop: '-5px' }}
            />
          </Grid>

          <Grid xs={2} item display={'flex'}>
            <Button
              className="confirm-button"
              variant="contained"
              size="large"
              startIcon={
                loading ? (
                  <CircularProgress color="inherit" size={25} />
                ) : (
                  <SearchOutlined />
                )
              }
              disabled={loading}
              sx={{ height: '39px' }}
              onClick={buscarMobioh}
            >
              Buscar
            </Button>
          </Grid>
          <Grid xs={12} item>
            <TableComponet
              headers={headers}
              data={perfisCadastrados}
              labelCaption="Nenhum Relatório Encontrado"
              labelTable="Lista de Usuários"
              status="ativo"
              statusLabelTrue="Ativo"
              statusLabelFalse="Inativo"
              loading={loading}
              handlerEditarAction={(data) => {
                setEditar(true)
                setPerfiSelecionado(data)
                handleOpen()
              }}
              handlerDetalhesAction={(data) => {
                history.push({
                  pathname: '/admin/perfil/sincronizar',
                  state: {
                    perfil: data,
                    idUsuarioAtualizacao: user.idUsuario,
                  },
                })
              }}
            />
          </Grid>
        </Grid>
      </S.ContainerForm>

      <FullScreenDialog
        label={editar ? 'Editar Perfil' : 'Cadastrar Novo Perfil'}
        open={open}
        handleClose={closeDialog}
      >
        <CadastrarEditarPerfil
          idUsuarioAtualizacao={user.idUsuario}
          editar={editar}
          perfil={perfilSelecionado}
          handleClose={closeDialog}
          empresas={empresas}
          tipo={user.tipo}
          buscarPerfis={buscarMobioh}
        />
      </FullScreenDialog>
    </S.Container>
  )
}

const mapStateToProps = (store) => ({
  user: store.loginState.user.usuario,
  empresa: store.empresaReducer.empresa,
})

export default connect(mapStateToProps)(PerfisAdmin)
