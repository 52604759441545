import styled, { css } from 'styled-components'

import { Initials } from '../Initials'

export const AVATAR_SIZE = '2.7rem'

const Style = css`
  width: ${AVATAR_SIZE};
  height: ${AVATAR_SIZE};
  border-radius: ${AVATAR_SIZE};
`

export const Image = styled.img`
  ${Style};
`

export const NameInitials = styled(Initials)`
  ${Style};
`
