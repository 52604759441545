import React from 'react'

import { Image, NameInitials } from './styles'


export const Avatar = (props) => {
  return props.src === null ? (
    <Image {...props} />
  ) : (
    <NameInitials
      text={props.alt || ''}
      style={props.style}
      className={props.className}
      onClick={props.onClick}
    />
  )
}
