import { api } from "../utilities";


class RelatorioService {

  static async atualizar(obj) {
    let res = null;

    await api.put('/relatorio', obj).then(response => {
      res = response.data;
    });

    return res;
  }

  static async cadastrar(obj) {
    let res = null;

    await api.post('/relatorio', obj).then(response => {
      res = response.data;
    });

    return res;
  }

  static async buscar(obj) {
    let res = null;

    await api.post('/relatorio/filtro', obj).then(response => {
      res = response.data;
    });

    return res;
  }

}

export default RelatorioService;
