import { createGlobalStyle, css } from 'styled-components'

const GlobalStyles = createGlobalStyle`

  * {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }

  ${({ theme }) => css`
    html {
      font-size: 100%;
    }

    a {
      text-decoration: none;
    }

    ul {
      list-style: none;
      padding-inline-start: 0px;
    }

    body,
    #root {
      background-color: ${theme.bg.custom}!important;
      font-family: ${theme.font.family};
      font-size: ${theme.font.sizes.small};

      height: 100vh;
      width: 100vw;

      overflow-x: hidden;

      .MuiDrawer-paper {
        border-bottom: none !important;
      }

      .btn-card {
        background-color: var(--cor-primaria) !important;
        font-size: 11px;
      }

      .btn-card:hover {
        background-color: var(--cor-secundaria) !important;
      }

      .btn-card-light {
        background-color: #fff !important;
        color: var(--cor-primaria) !important;
        font-size: 11px;
        border: 1px solid var(--cor-primaria) !important;
      }

      //ESTILO DOS BOTÔES

      .modal-cancel-button {
        border: 1px solid ${(props) => props.theme.colors.buttonCancel} !important;
        background-color: ${(props) =>
          props.theme.colors.buttonCancel} !important;
        color: #fff !important;
      }

      .modal-cancel-button:hover {
        border: 1px solid ${(props) => props.theme.colors.buttonCancelHover} !important;
        background-color: ${(props) =>
          props.theme.colors.buttonCancelHover} !important;
        color: #fff !important;
      }

      .check {
        background-color: #00923f !important;
      }

      .danger {
        background-color: #e00808 !important;
      }

      .pendente {
        background-color: #f9a825 !important;
      }

      .Toastify__toast-theme--colored.Toastify__toast--success {
        background-color: rgb(237, 247, 237);
        color: var(--cor-primaria);

        svg {
          color: var(--cor-primaria);
        }
      }
      .Toastify__toast-theme--colored.Toastify__toast--warning {
        background-color: rgb(255, 244, 229) !important;
        color: rgb(102, 60, 0) !important;

        svg {
          color: #ed6c02 !important;
        }
      }

      .Toastify__toast-theme--colored.Toastify__toast--error {
        background-color: rgb(253, 237, 237) !important;
        color: rgb(95, 33, 32) !important;

        svg {
          color: #d32f2f !important;
        }
      }
    }

    // SCROLLERBAR
    /* Firefox */
    * {
      scrollbar-width: thin;
      scrollbar-color: #a3a3a3 #dfe9eb;
    }

    /* Chrome, Edge and Safari */
    *::-webkit-scrollbar {
      width: 10px;
      width: 10px;
    }
    *::-webkit-scrollbar-track {
      border-radius: 5px;
      background-color: #dfe9eb;
    }

    *::-webkit-scrollbar-track:hover {
      background-color: #b8c0c2;
    }

    *::-webkit-scrollbar-track:active {
      background-color: #b8c0c2;
    }

    *::-webkit-scrollbar-thumb {
      border-radius: 5px;
      background-color: #a3a3a3;
    }

    *::-webkit-scrollbar-thumb:hover {
      background-color: #7d7d7d;
    }

    *::-webkit-scrollbar-thumb:active {
      background-color: #7d7d7d;
    }
  `} 
`

export default GlobalStyles
