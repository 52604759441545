import React, { useState } from 'react'
import * as S from './styles'
import {
  FullScreenDialog,
  Input,
  InputSelect,
  ModalConfirmar,
  TableComponet,
  Titulo,
} from '../../components'
import { Button, CircularProgress, Grid, Stack, Tooltip } from '@mui/material'
import AddCircleOutlineOutlinedIcon from '@mui/icons-material/AddCircleOutlineOutlined'
import SearchOutlinedIcon from '@mui/icons-material/SearchOutlined'
import { Utils, api } from '../../utilities'
import EditarRelatorio from './EditarRelatorio'
import { connect } from 'react-redux'
import { toast } from 'react-toastify'
import RelatorioService from '../../service/RelatorioService'
import FilterAltOffIcon from '@mui/icons-material/FilterAltOff'

function RelatorioAdmin(props) {
  const [open, setOpen] = React.useState(false)
  const handleOpen = () => setOpen(true)
  const handleClose = () => setOpen(false)

  const [openModal, setOpenModal] = React.useState(false)
  const handleOpenModal = () => setOpenModal(true)
  const handleCloseModal = () => setOpenModal(false)

  const [loading, setLoading] = useState(false)
  const [menus, setMenus] = useState([])
  const [subMenus, setSubMenus] = useState([])

  const [relatorios, setRelatorios] = useState([])
  const [isEditar, setIsEditar] = React.useState(false)
  const [infoRelatorio, setInfoRelatorio] = React.useState({})

  const [totalRegistros, setTotalRegistros] = useState(0)

  const [labelDialog, setLabelDialog] = React.useState(
    'Cadastrar Novo Relatório',
  )

  const [stateRelatorio, setStateRelatorio] = React.useState({
    descricao: null,
    menu: null,
    subMenu: null,
  })

  const headers = [
    {
      id: 'codigoRelatorio',
      label: 'Código',
      props: {
        align: 'left',
      },
    },
    {
      id: 'nome',
      label: 'Nome',
      props: {
        align: 'left',
      },
    },
    {
      id: 'menu',
      label: 'Menu',
      props: {
        align: 'left',
      },
    },
    {
      id: 'subMenu',
      label: 'Submenu',
      props: {
        align: 'left',
      },
    },
    {
      id: 'strDataAtualizacao',
      label: 'Data Atualização',
      props: {
        align: 'left',
      },
    },
    {
      id: 'statusRows',
      label: 'Status',
      props: {
        align: 'right',
      },
    },
    {
      id: 'actionRows',
      label: 'Ações',
      props: {
        align: 'right',
      },
    },
  ]

  let mounted = true
  React.useEffect(() => {
    async function getFiltros() {
      if (mounted) {
        setLoading(true)
        let newMenus = []
        let newSubmenus = []

        api.post('/relatorio/dados').then(({ data }) => {
          if (Utils.isNotObjectEmpty(data)) {
            data.menus.forEach((element) => {
              newMenus.push({ id: element })
            })

            data.subMenus.forEach((element) => {
              newSubmenus.push({ id: element })
            })

            setSubMenus([...newSubmenus])
            setMenus([...newMenus])
            setRelatorios(data.relatorios)
            setTotalRegistros(data.totalRegistros)
            setLoading(false)
          }
        })
      }
    }

    getFiltros()
    return () => (mounted = false)
  }, [])

  function editarUsuario(data) {
    setIsEditar(true)
    setInfoRelatorio(data)
    setLabelDialog('Editar Relatório')
    handleOpen()
  }

  function closeDialog() {
    setLabelDialog('Cadastrar Novo Relatórios')
    setIsEditar(false)
    setInfoRelatorio({})
    handleClose()
    //  getRelatorios()
  }

  function buscarMobioh() {
    setLoading(true)
    RelatorioService.buscar(stateRelatorio)
      .then((data) => {
        setLoading(false)
        if (Utils.isArrayNotEmpty(data)) {
          setRelatorios(data)
        } else {
          setRelatorios([])
        }
      })
      .catch((error) => {
        setLoading(false)
        toast.error('Houve um problema ao buscar os Relatórios!', {
          theme: 'colored',
        })
      })
  }

  function inativarRelatorio(data) {
    setLoading(true)

    RelatorioService.atualizar({
      ...data,
      ativo: false,
      idUsuarioAtualizacao: props.user.idUsuario,
    })
      .then(({ status, mensagem }) => {
        setLoading(false)
        if (status) {
          toast.success(mensagem, {
            theme: 'colored',
          })
          buscarMobioh()
          closeDialog()
        } else {
          toast.error(mensagem, {
            theme: 'colored',
          })
        }
      })
      .catch((error) => {
        setLoading(false)
        toast.error('Houve um problema na inativação do Relatório!', {
          theme: 'colored',
        })
      })
    handleCloseModal()
  }

  return (
    <S.Container>
      <ModalConfirmar
        open={openModal}
        handleConfirm={() => inativarRelatorio(infoRelatorio)}
        handleClose={handleCloseModal}
        customStyle={{ width: 400 }}
      >
        <S.TitleModal>Deseja inativar esse Relatório?</S.TitleModal>
      </ModalConfirmar>
      <Titulo>
        <div>Relatórios Cadastrados</div>
        <Stack
          direction="row"
          spacing={2}
          justifyContent="end"
          marginTop="-26px"
        >
          <Button
            className="confirm-button"
            variant="contained"
            size="medium"
            endIcon={<AddCircleOutlineOutlinedIcon />}
            //sx={{ height: '43px' }}
            onClick={handleOpen}
          >
            Cadastrar Relatório
          </Button>
        </Stack>
      </Titulo>
      <S.ContainerForm>
        <Grid container padding={1} spacing={2} marginBottom={1}>
          <Grid xs={5} item>
            <Input
              label="Pesquisar"
              id={'descricao'}
              // error={validacao(search)}
              // helperText={
              //   validacao(search) ? 'Essa informação é obrigatória' : ''
              // }
              value={stateRelatorio.descricao}
              onChange={(e) => {
                setStateRelatorio((state) => ({
                  ...state,
                  descricao: e.target.value,
                }))
              }}
              placeholder="Escreva o Nome ou descrição do Relatório"
              size="small"
            />
          </Grid>

          <Grid xs={3} item>
            <InputSelect
              value={stateRelatorio.menu}
              setValue={(value) => {
                setStateRelatorio((state) => ({
                  ...state,
                  menu: value,
                }))
              }}
              options={menus}
              label="Menus"
              id="id"
              descricao="id"
              size="small"
              sx={{ padding: '5px 0px 0px', marginTop: '-5px' }}
            />
          </Grid>

          <Grid xs={2} item>
            <InputSelect
              value={stateRelatorio.subMenu}
              setValue={(value) => {
                setStateRelatorio((state) => ({
                  ...state,
                  subMenu: value,
                }))
              }}
              options={subMenus}
              label="Submenus"
              id="id"
              descricao="id"
              size="small"
              sx={{ padding: '5px 0px 0px', marginTop: '-5px' }}
            />
          </Grid>

          <Grid xs={2} item display={'flex'}>
            <Button
              className="confirm-button"
              variant="contained"
              size="large"
              startIcon={
                loading ? (
                  <CircularProgress color="inherit" size={25} />
                ) : (
                  <SearchOutlinedIcon />
                )
              }
              disabled={loading}
              sx={{ height: '39px' }}
              onClick={buscarMobioh}
            >
              Buscar
            </Button>
            <Tooltip
              title={'Limpar Filtros'}
              placement="top"
              style={{ fontSize: '20px !important' }}
            >
              <Button
                className="confirm-button"
                variant="text"
                size="large"
                startIcon={
                  loading ? (
                    <CircularProgress color="inherit" size={25} />
                  ) : (
                    <FilterAltOffIcon />
                  )
                }
                disabled={loading}
                sx={{ height: '39px', color: '#C4C4C4 !important' }}
                onClick={() =>
                  setStateRelatorio({
                    descricao: "",
                    menu: null,
                    subMenu: null,
                  })
                }
              ></Button>
            </Tooltip>
          </Grid>
        </Grid>
        <Grid container padding={1}>
          <Grid xs={12}>
            <TableComponet
              headers={headers}
              data={relatorios}
              handlerEditarAction={(data) => editarUsuario(data)}
              handlerDeletarAction={(data) => {
                setInfoRelatorio(data)
                handleOpenModal()
              }}
              labelCaption="Nenhum Relatório Encontrado"
              labelTable="Lista de Relatórios"
              status="ativo"
              statusLabelTrue="Ativo"
              statusLabelFalse="Inativo"
              loading={loading}
            />
          </Grid>
        </Grid>
      </S.ContainerForm>

      <FullScreenDialog
        label={labelDialog}
        open={open}
        handleClose={closeDialog}
      >
        <EditarRelatorio
          menus={menus}
          subMenus={subMenus}
          idUsuarioAtualizacao={props.user.idUsuario}
          editar={isEditar}
          infoRelatorio={infoRelatorio}
          closeDialog={() => {
            closeDialog()
            buscarMobioh()
          }}
        />
      </FullScreenDialog>
    </S.Container>
  )
}

const mapStateToProps = (store) => ({
  user: store.loginState.user.usuario,
})

export default connect(mapStateToProps)(RelatorioAdmin)
