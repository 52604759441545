import React, { useState } from 'react'
import * as S from './styles'
import {
  FullScreenDialog,
  Input,
  InputSelect,
  ModalConfirmar,
  TableComponet,
  Titulo,
} from '../../components'
import { Button, CircularProgress, Grid, Stack, Tooltip } from '@mui/material'
import AddCircleOutlineOutlinedIcon from '@mui/icons-material/AddCircleOutlineOutlined'
import SearchOutlinedIcon from '@mui/icons-material/SearchOutlined'
import { Utils, api } from '../../utilities'
import EditarEmpresa from './EditarEmpresa'
import { connect } from 'react-redux'
import { toast } from 'react-toastify'
import { useHistory } from 'react-router-dom'
import EmpresaService from '../../service/EmpresaService'
import { FilterAltOff } from '@mui/icons-material'

function EmpresasAdmin(props) {
  const [open, setOpen] = React.useState(false)
  const handleOpen = () => setOpen(true)
  const handleClose = () => setOpen(false)

  const [openModal, setOpenModal] = React.useState(false)
  const handleOpenModal = () => setOpenModal(true)
  const handleCloseModal = () => setOpenModal(false)

  const [loading, setLoading] = useState(false)

  const [empresas, setEmpresas] = useState([])
  const [isEditar, setIsEditar] = React.useState(false)
  const [infoEmpresas, setInfoEmpresas] = React.useState({})
  const [estados, setEstados] = React.useState([])

  const [totalRegistros, setTotalRegistros] = useState(0)

  const [labelDialog, setLabelDialog] = React.useState('Cadastrar Nova Empresa')

  const [stateRelatorio, setStateRelatorio] = React.useState({
    descricao: null,
    // estado: null,
  })

  const history = useHistory()

  const headers = [
    {
      id: 'codigoEmpresa',
      label: 'Código',
      props: {
        align: 'left',
      },
    },
    {
      id: 'fantasia',
      label: 'Nome Fantasia',
      props: {
        align: 'left',
      },
    },
    {
      id: 'descricaoCidade',
      label: 'Cidade',
      props: {
        align: 'left',
      },
    },

    {
      id: 'descricaoCpfCnpj',
      label: 'Cnpj',
      props: {
        align: 'left',
      },
    },
    {
      id: 'motivoBloqueio',
      label: 'Motivo Bloqueio',
      props: {
        align: 'left',
      },
    },
    {
      id: 'bloqueioRows',
      label: 'Status Bloqueio',
      props: {
        align: 'right',
      },
    },
    {
      id: 'statusRows',
      label: 'Status',
      props: {
        align: 'right',
      },
    },
    {
      id: 'actionRows',
      label: 'Ações',
      props: {
        align: 'right',
      },
    },
  ]

  let mounted = true
  React.useEffect(() => {
    async function getFiltros() {
      if (mounted) {
        setLoading(true)

        api.post('/empresa/admin/mobioh/dados').then(({ data }) => {
          if (Utils.isNotObjectEmpty(data)) {
            setEstados(data.estados)
            setEmpresas(data.empresas)
            setTotalRegistros(data.totalRegistros)
            setLoading(false)
          }
        })
      }
    }

    getFiltros()
    return () => (mounted = false)
  }, [])

  function editarEmpresa(data) {
    setIsEditar(true)
    setInfoEmpresas(data)
    setLabelDialog('Editar Empresa')
    handleOpen()
  }

  function closeDialog() {
    setLabelDialog('Cadastrar Nova Empresa')
    setIsEditar(false)
    setInfoEmpresas({})
    handleClose()
    //  getRelatorios()
  }

  function buscarMobioh() {
    setLoading(true)
    api
      .post('/empresa/admin/mobioh/filtro', stateRelatorio)
      .then(({ data }) => {
        setLoading(false)
        if (Utils.isArrayNotEmpty(data)) {
          setEmpresas(data)
        } else {
          setEmpresas([])
        }
      })
      .catch((error) => {
        setLoading(false)
        toast.error('Houve um problema ao buscar as Empresas!', {
          theme: 'colored',
        })
      })
  }

  function inativarEmpresa(data) {
    setLoading(true)

    EmpresaService.atualizar({
      ...data,
      ativo: false,
      idUsuarioAtualizacao: props.user.idUsuario,
    })
      .then(({ status, mensagem }) => {
        setLoading(false)
        if (status) {
          toast.success(mensagem, {
            theme: 'colored',
          })
          buscarMobioh()
        } else {
          toast.error(mensagem, {
            theme: 'colored',
          })
        }
      })
      .catch((error) => {
        setLoading(false)
        toast.error('Houve um problema na inativação do Relatório!', {
          theme: 'colored',
        })
      })
    handleCloseModal()
  }

  return (
    <S.Container>
      <ModalConfirmar
        open={openModal}
        handleConfirm={() => inativarEmpresa(infoEmpresas)}
        handleClose={handleCloseModal}
        customStyle={{ width: 400 }}
      >
        <S.TitleModal>Deseja inativar essa Empresa?</S.TitleModal>
      </ModalConfirmar>
      <Titulo>
        <div>Empresas Cadastradas</div>
        <Stack
          direction="row"
          spacing={2}
          justifyContent="end"
          marginTop="-26px"
        >
          <Button
            className="confirm-button"
            variant="contained"
            size="medium"
            endIcon={<AddCircleOutlineOutlinedIcon />}
            //sx={{ height: '43px' }}
            onClick={handleOpen}
          >
            Cadastrar Empresas
          </Button>
        </Stack>
      </Titulo>
      <S.ContainerForm>
        <Grid container padding={1} spacing={2} marginBottom={1}>
          <Grid xs={5} item>
            <Input
              label="Pesquisar"
              id={'descricao'}
              // error={validacao(search)}
              // helperText={
              //   validacao(search) ? 'Essa informação é obrigatória' : ''
              // }
              value={stateRelatorio.descricao}
              onChange={(e) => {
                setStateRelatorio((state) => ({
                  ...state,
                  descricao: e.target.value,
                }))
              }}
              placeholder="Escreva o Nome ou descrição do Relatório"
              size="small"
            />
          </Grid>

          {/* <Grid xs={3} item>
            <InputSelect
              value={stateRelatorio.estado}
              setValue={(value) => {
                setStateRelatorio((state) => ({
                  ...state,
                  estado: value,
                }))
              }}
              options={estados}
              label="Estados"
              id="idEstadoIbge"
              descricao="nomeEstado"
              codigo="sigla"
              size="small"
              sx={{ padding: '5px 0px 0px', marginTop: '-5px' }}
            />
          </Grid> */}

          <Grid xs={2} item display={'flex'}>
            <Button
              className="confirm-button"
              variant="contained"
              size="large"
              startIcon={
                loading ? (
                  <CircularProgress color="inherit" size={25} />
                ) : (
                  <SearchOutlinedIcon />
                )
              }
              disabled={loading}
              sx={{ height: '39px' }}
              onClick={buscarMobioh}
            >
              Buscar
            </Button>

            <Tooltip
              title={'Limpar Filtro'}
              placement="top"
              style={{ fontSize: '20px !important' }}
            >
              <Button
                className="confirm-button"
                variant="text"
                size="large"
                startIcon={
                  loading ? (
                    <CircularProgress color="inherit" size={25} />
                  ) : (
                    <FilterAltOff />
                  )
                }
                disabled={loading}
                sx={{ height: '39px', color: '#C4C4C4 !important' }}
                onClick={() =>
                  setStateRelatorio({
                    descricao: '',
                  })
                }
              ></Button>
            </Tooltip>
          </Grid>
        </Grid>
        <Grid container padding={1}>
          <Grid xs={12}>
            <TableComponet
              headers={headers}
              data={empresas}
              handlerEditarAction={(data) => editarEmpresa(data)}
              handlerDeletarAction={(data) => {
                setInfoEmpresas(data)
                handleOpenModal()
              }}
              handlerDetalhesAction={(data) => {
                history.push({
                  pathname: '/admin/empresas/sincronizar',
                  state: {
                    empresa: data,
                    estados: estados,
                    idUsuarioAtualizacao: props.user.idUsuario,
                  },
                })
              }}
              labelCaption="Nenhuma Empresa Encontrado"
              labelTable="Lista de Empresas"
              status="ativo"
              statusLabelTrue="Ativo"
              statusLabelFalse="Inativo"
              bloqueio="bloqueio"
              bloqueioLabelTrue="Sem Bloqueio"
              bloqueioLabelFalse="Bloqueado"
              loading={loading}
            />
          </Grid>
        </Grid>
      </S.ContainerForm>

      <FullScreenDialog
        label={labelDialog}
        open={open}
        handleClose={closeDialog}
      >
        <EditarEmpresa
          estados={estados}
          idUsuarioAtualizacao={props.user.idUsuario}
          editar={isEditar}
          infoEmpresas={infoEmpresas}
          closeDialog={() => {
            closeDialog()
            buscarMobioh()
          }}
        />
      </FullScreenDialog>
    </S.Container>
  )
}

const mapStateToProps = (store) => ({
  user: store.loginState.user.usuario,
})

export default connect(mapStateToProps)(EmpresasAdmin)
