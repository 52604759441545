import * as React from 'react'
import Radio from '@mui/material/Radio'
import RadioGroup from '@mui/material/RadioGroup'
import FormControlLabel from '@mui/material/FormControlLabel'
import FormControl from '@mui/material/FormControl'
import FormLabel from '@mui/material/FormLabel'

export default function RadioButtonsGroup({
  label,
  options,
  onChange,
  defaultValue,
  valueOptions,
  labelOptions,
}) {
  return (
    <FormControl>
      <FormLabel id="demo-row-radio-buttons-group-label">{label}</FormLabel>
      <RadioGroup
        row
        aria-labelledby="demo-row-radio-buttons-group-label"
        name="row-radio-buttons-group"
        onChange={onChange}
        defaultValue={defaultValue}
      >
        {options.map((item) => {
          return (
            <FormControlLabel
              value={item[valueOptions]}
              control={<Radio />}
              label={item[labelOptions]}
            />
          )
        })}
      </RadioGroup>
    </FormControl>
  )
}
