import React, { useState } from 'react'
import * as S from './styles'
import {
  FullScreenDialog,
  Input,
  InputSelect,
  ModalConfirmar,
  TableComponet,
  Titulo,
} from '../../components'
import { Button, CircularProgress, Grid, Stack, Tooltip } from '@mui/material'
import AddCircleOutlineOutlinedIcon from '@mui/icons-material/AddCircleOutlineOutlined'
import SearchOutlinedIcon from '@mui/icons-material/SearchOutlined'
import { Utils, api } from '../../utilities'
import EditarUsuario from './EditarUsuario'
import { connect } from 'react-redux'
import UserService from '../../service/UserService'
import { toast } from 'react-toastify'
import { FilterAltOff } from '@mui/icons-material'

function UsuariosAdmin(props) {
  const [open, setOpen] = React.useState(false)
  const handleOpen = () => setOpen(true)
  const handleClose = () => setOpen(false)

  const [openModal, setOpenModal] = React.useState(false)
  const handleOpenModal = () => setOpenModal(true)
  const handleCloseModal = () => setOpenModal(false)

  const [loading, setLoading] = useState(false)
  const [empresas, setEmpresas] = useState([])
  const [tipos, setTipos] = useState([])

  const [usuarios, setUsuarios] = useState([])
  const [isEditar, setIsEditar] = React.useState(false)
  const [infoUsuario, setInfoUsuario] = React.useState({})

  const [totalRegistros, setTotalRegistros] = useState(0)

  const [labelDialog, setLabelDialog] = React.useState('Cadastrar Novo Usuário')

  const [stateUsuarioProducao, setStateUsuarioProducao] = React.useState({
    descricao: null,
    idEmpresa: null,
    tipo: null,
  })

  const headersUsuarios = [
    {
      id: 'nome',
      label: 'Nome',
      props: {
        align: 'left',
      },
    },
    {
      id: 'login',
      label: 'Login',
      props: {
        align: 'left',
      },
    },
    {
      id: 'tipoDescricao',
      label: 'Tipo',
      props: {
        align: 'right',
      },
    },
    {
      id: 'strDataAtualizacao',
      label: 'Data Atualização',
      props: {
        align: 'right',
      },
    },
    {
      id: 'quantidadeEmpresas',
      label: 'Qtd. Empresas Vinculadas',
      props: {
        align: 'right',
      },
    },

    {
      id: 'statusRows',
      label: 'Status',
      props: {
        align: 'right',
      },
    },
    {
      id: 'actionRows',
      label: 'Ações',
      props: {
        align: 'right',
      },
    },
  ]

  let mounted = true
  React.useEffect(() => {
    async function getFiltros() {
      if (mounted) {
        setLoading(true)
        api.post('/usuario/admin/mobioh/dados').then(({ data }) => {
          if (Utils.isNotObjectEmpty(data)) {
            setEmpresas(data.empresas)
            setTipos(data.tipos)
            setUsuarios(data.usuarios)
            setTotalRegistros(data.totalRegistros)

            setLoading(false)
          }
        })
      }
    }

    getFiltros()
    return () => (mounted = false)
  }, [])

  async function getUsuarios() {
    setLoading(true)
    api.post('/usuario/admin/mobioh/dados').then(({ data }) => {
      if (Utils.isNotObjectEmpty(data)) {
        setEmpresas(data.empresas)
        setTipos(data.tipos)
        setUsuarios(data.usuarios)
        setTotalRegistros(data.totalRegistros)

        setLoading(false)
      }
    })
  }

  function editarUsuario(data) {
    setIsEditar(true)
    setInfoUsuario(data)
    setLabelDialog('Editar Usuário')
    handleOpen()
  }

  function closeDialog() {
    setLabelDialog('Cadastrar Novo Usuário')
    setIsEditar(false)
    setInfoUsuario({})
    handleClose()
    //getUsuarios()
  }

  function buscarMobioh() {
    setLoading(true)

    UserService.buscarMobioh(stateUsuarioProducao)
      .then((data) => {
        setLoading(false)
        if (Utils.isArrayNotEmpty(data)) {
          setUsuarios(data)
        } else {
          setUsuarios([])
        }
      })
      .catch((error) => {
        setLoading(false)
        toast.error('Houve um problema ao inativar o Usuário!', {
          theme: 'colored',
        })
      })
  }

  function inativarUsuario(data) {
    setLoading(true)

    UserService.atualizarUsuarioPerfil({
      ...infoUsuario,
      ativo: false,
    })
      .then(({ status, mensagem }) => {
        setLoading(false)
        if (status) {
          toast.success(mensagem, {
            theme: 'colored',
          })
          buscarMobioh()
          closeDialog()
        } else {
          toast.error(mensagem, {
            theme: 'colored',
          })
        }
      })
      .catch((error) => {
        setLoading(false)
        toast.error('Houve um problema na inativação do Usuário!', {
          theme: 'colored',
        })
      })

    handleCloseModal()
  }

  return (
    <S.Container>
      <ModalConfirmar
        open={openModal}
        handleConfirm={() => inativarUsuario(infoUsuario)}
        handleClose={handleCloseModal}
        customStyle={{ width: 400 }}
      >
        <S.TitleModal>Deseja inativar esse Usuário?</S.TitleModal>
      </ModalConfirmar>
      <Titulo>
        <div>Usuários Cadastrados</div>
        <Stack
          direction="row"
          spacing={2}
          justifyContent="end"
          marginTop="-26px"
        >
          <Button
            className="confirm-button"
            variant="contained"
            size="medium"
            endIcon={<AddCircleOutlineOutlinedIcon />}
            //sx={{ height: '43px' }}
            onClick={handleOpen}
          >
            Cadastrar usuários
          </Button>
        </Stack>
      </Titulo>
      <S.ContainerForm>
        <Grid container padding={1} spacing={2} marginBottom={1}>
          <Grid xs={5} item>
            <Input
              label="Pesquisar"
              id={'descricao'}
              // error={validacao(search)}
              // helperText={
              //   validacao(search) ? 'Essa informação é obrigatória' : ''
              // }
              value={stateUsuarioProducao.descricao}
              onChange={(e) => {
                setStateUsuarioProducao((state) => ({
                  ...state,
                  descricao: e.target.value,
                }))
              }}
              placeholder="Escreva o Nome ou Login do Usuário"
              size="small"
            />
          </Grid>

          <Grid xs={3} item>
            <InputSelect
              value={stateUsuarioProducao.idEmpresa}
              setValue={(value) => {
                setStateUsuarioProducao((state) => ({
                  ...state,
                  idEmpresa: value,
                }))
              }}
              options={empresas}
              label="Empresas"
              id="idEmpresa"
              descricao="descricao"
              size="small"
              sx={{ padding: '5px 0px 0px', marginTop: '-5px' }}
            />
          </Grid>

          <Grid xs={2} item>
            <InputSelect
              value={stateUsuarioProducao.tipo}
              setValue={(value) => {
                setStateUsuarioProducao((state) => ({
                  ...state,
                  tipo: value,
                }))
              }}
              options={tipos}
              label="Tipo de Usuário"
              id="tipo"
              descricao="descricao"
              size="small"
              sx={{ padding: '5px 0px 0px', marginTop: '-5px' }}
            />
          </Grid>

          <Grid xs={2} item display={'flex'}>
            <Button
              className="confirm-button"
              variant="contained"
              size="large"
              startIcon={
                loading ? (
                  <CircularProgress color="inherit" size={25} />
                ) : (
                  <SearchOutlinedIcon />
                )
              }
              disabled={loading}
              sx={{ height: '39px' }}
              onClick={buscarMobioh}
            >
              Buscar
            </Button>

            <Tooltip
              title={'Limpar Filtros'}
              placement="top"
              style={{ fontSize: '20px !important' }}
            >
              <Button
                className="confirm-button"
                variant="text"
                size="large"
                startIcon={
                  loading ? (
                    <CircularProgress color="inherit" size={25} />
                  ) : (
                    <FilterAltOff />
                  )
                }
                disabled={loading}
                sx={{ height: '39px', color: '#C4C4C4 !important' }}
                onClick={() =>
                  setStateUsuarioProducao({
                    descricao: "",
                    menu: null,
                    subMenu: null,
                  })
                }
              ></Button>
            </Tooltip>
          </Grid>
        </Grid>
        <Grid container padding={1}>
          <Grid xs={12}>
            <TableComponet
              headers={headersUsuarios}
              data={usuarios}
              handlerEditarAction={(data) => editarUsuario(data)}
              handlerDeletarAction={(data) => {
                setInfoUsuario(data)
                handleOpenModal()
              }}
              labelCaption="Nenhum Usuário Encontrado"
              labelTable="Lista de Usuários"
              status="ativo"
              statusLabelTrue="Ativo"
              statusLabelFalse="Inativo"
              loading={loading}
            />
          </Grid>
        </Grid>
      </S.ContainerForm>

      <FullScreenDialog
        label={labelDialog}
        open={open}
        handleClose={closeDialog}
      >
        <EditarUsuario
          tipos={tipos}
          empresas={empresas}
          idUsuarioAtualizacao={props.user.idUsuario}
          editar={isEditar}
          infoUsuario={infoUsuario}
          closeDialog={() => {
            closeDialog()
          }}
          buscarMobioh={buscarMobioh}
        />
      </FullScreenDialog>
    </S.Container>
  )
}

const mapStateToProps = (store) => ({
  user: store.loginState.user.usuario,
})

export default connect(mapStateToProps)(UsuariosAdmin)
