import React, { useState } from 'react'
import TabsComponent from '../../components/TabsComponent/TabsComponent'
import * as S from './styles'
import { Grid, Stack, Button, LinearProgress } from '@mui/material'
import { Titulo } from '../../components'
import { useLocation } from 'react-router-dom'
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min'
import { ArrowBack } from '@mui/icons-material'
import RelatorioSincronizar from './RelatorioSincronizar'
import { Utils, api } from '../../utilities'
import { toast } from 'react-toastify'
import UsuariosSincronizar from './UsuariosSincronizar'

export default function SincronizarPerfil() {
  const [relatorios, setRelatorios] = useState([])
  const [usuarios, setUsuarios] = useState([])
  const [loading, setLoading] = useState(false)
  const [perfilCompleto, setPerfilCompleto] = useState({
    idEmpresa: '',
    idPerfil: '',
  })

  const location = useLocation()
  const history = useHistory()
  const {
    state: { perfil },
  } = location
  const tabsHeader = [
    {
      id: 'relatorios',
      label: 'Relatórios',
    },
    {
      id: 'usuarios',
      label: 'Usuários',
    },
  ]

  const tabsPanel = [
    <RelatorioSincronizar
      idPerfil={perfil.idPerfil}
      idEmpresa={perfilCompleto.idEmpresa}
      relatorios={relatorios}
    />,
    <UsuariosSincronizar
      idPerfil={perfil.idPerfil}
      idEmpresa={perfilCompleto.idEmpresa}
      usuarios={usuarios}
    />,
  ]

  let mounted = true
  React.useEffect(() => {
    setLoading(true)
    async function getFiltros() {
      if (mounted) {
        if (Utils.isNotNull(perfil)) {
          api
            .get('perfil/completo/' + perfil.idPerfil)
            .then(({ data }) => {
              setLoading(false)
              if (Utils.isNotObjectEmpty(data)) {
                setUsuarios(data.usuarios ? data.usuarios : [])
                setRelatorios(data.relatorios ? data.relatorios : [])
                setPerfilCompleto(data)
              }
            })
            .catch((error) => {
              setLoading(false)
              toast.error('Houve um problema na busca dos dados do Perfil!', {
                theme: 'colored',
              })
            })
        }
      }
    }
    getFiltros()
    return () => (mounted = false)
  }, [])

  return (
    <S.Container>
      <Titulo>
        <div>{location ? perfil.descricao : ''}</div>
        <Stack
          direction="row"
          spacing={2}
          justifyContent="end"
          marginTop="-26px"
        >
          <Button
            className="confirm-button"
            variant="contained"
            size="medium"
            color="warning"
            startIcon={<ArrowBack />}
            //sx={{ height: '43px' }}
            onClick={() => history.push('/admin/perfis')}
          >
            Voltar
          </Button>
        </Stack>
      </Titulo>
      <S.ContainerForm>
        <Grid container padding={1} spacing={2} marginBottom={1}>
          {loading && (
            <Grid item xs={12}>
              <LinearProgress sx={{ width: '100%' }} />
            </Grid>
          )}

          {!loading && (
            <Grid item xs={12}>
              <TabsComponent
                tabsHeader={tabsHeader}
                tabsPanel={tabsPanel}
                //  handleChangeTab={handlerChangeTabs}
              ></TabsComponent>
            </Grid>
          )}
        </Grid>
      </S.ContainerForm>
    </S.Container>
  )
}
