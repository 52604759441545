import React from 'react'
import { Route, Redirect } from 'react-router-dom'
import { ToastContainer } from 'react-toastify'
import App from '../App'

function isLogin() {
  if (sessionStorage.getItem('auth')) {
    return true
  }
  return false
}

const NewHOC = (PassedComponent) => {
  return class extends React.Component {
    render() {
      return (
        <div>
          <PassedComponent {...this.props} />
        </div>
      )
    }
  }
}

const PrivateRoute = ({ Component, ...rest }) => {
  const NewComponent = NewHOC(Component)

  return isLogin() ? (
    <Route
      {...rest}
      render={(props) => {
        return (
          <>
            <ToastContainer />
            <App {...props}>
              <NewComponent />
            </App>
          </>
        )
      }}
    />
  ) : (
    <Redirect to="/login" />
  )
}

export default PrivateRoute
