import * as React from 'react'
import { styled } from '@mui/material/styles'
import AppBar from '@mui/material/AppBar'
import Box from '@mui/material/Box'
import Toolbar from '@mui/material/Toolbar'
import IconButton from '@mui/material/IconButton'
import Typography from '@mui/material/Typography'
import Badge from '@mui/material/Badge'
import MenuItem from '@mui/material/MenuItem'
import Menu from '@mui/material/Menu'
import MenuIcon from '@mui/icons-material/Menu'
import MoreIcon from '@mui/icons-material/MoreVert'
import { Avatar } from '@mui/material'
import { bindActionCreators } from 'redux'
import {
  logar,
  setPowerbi,
  getEmpresa,
  setMobilePowerBi,
} from '../../../redux/actions'
import { connect } from 'react-redux'
import FullscreenExitIcon from '@mui/icons-material/FullscreenExit'
import CachedIcon from '@mui/icons-material/Cached'
import RestoreIcon from '@mui/icons-material/Restore'
import { useHistory } from 'react-router-dom'
import { models } from 'powerbi-client'
import Sidebar from '../Sidebar/Sidebar'
import { Utils, api } from '../../../utilities'
import AddchartIcon from '@mui/icons-material/Addchart'
import BusinessIcon from '@mui/icons-material/Business'
import { Container } from './styles'

import GroupIcon from '@mui/icons-material/Group'
import GroupAddIcon from '@mui/icons-material/GroupAdd'
import DvrIcon from '@mui/icons-material/Dvr'
import {
  Add,
  Equalizer,
  Minimize,
  Remove,
  StayCurrentPortrait,
  Tv,
} from '@mui/icons-material'

import { useContext } from 'react'
import { IPInfoContext } from 'ip-info-react'

const stylesCustom = {
  icons: {
    fontSize: 24,
  },
}

const drawerWidth = 275

const Main = styled('main', { shouldForwardProp: (prop) => prop !== 'open' })(
  ({ theme, open }) => ({
    flexGrow: 1,
    padding: theme.spacing(2),
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    marginLeft: `-${drawerWidth}px`,
    ...(open && {
      transition: theme.transitions.create('margin', {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.enteringScreen,
      }),
      marginLeft: 0,
    }),
  }),
)

const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
  justifyContent: 'flex-end',
}))

function DrawerBar(props) {
  const history = useHistory()
  const userInfo = useContext(IPInfoContext)

  const {
    nomeMenu,
    empresa,
    user: {
      empresas,
      usuario: { imagemLogo, nome, tipo, idUsuario, idLogLogin },
    },
    mobilePowerbi,
    setMobilePowerBi,
  } = props

  const [anchorEl, setAnchorEl] = React.useState(null)
  const [anchorElEmpresa, setAnchorElEmpresa] = React.useState(null)
  const [mobileMoreAnchorEl, setMobileMoreAnchorEl] = React.useState(null)
  const [open, setOpen] = React.useState(false)
  const [perfilUsuarioNew, setPefilUsuarioNew] = React.useState([
    {
      name: 'Minhas Empresas',
      icon: BusinessIcon,
      route: () => rotaPadraoSemMenuBI('/empresas', 'Minhas Empresas'),
      isSubmenu: false,
    },
  ])
  const menuPadrao = {
    USUARIO: [
      {
        name: 'Minhas Empresas',
        icon: BusinessIcon,
        route: () => rotaPadraoSemMenuBI('/empresas', 'Minhas Empresas'),
        isSubmenu: false,
      },
    ],
    ADMIN_MOBIOH: [
      {
        name: 'Minhas Empresas',
        icon: BusinessIcon,
        route: () => rotaPadraoSemMenuBI('/empresas', 'Minhas Empresas'),
        isSubmenu: false,
      },
      {
        name: 'Relatórios',
        icon: Equalizer,
        route: () => rotaPadraoSemMenuBI('/relatorios', 'Relatórios'),
        isSubmenu: false,
      },
      {
        name: 'Usuários',
        icon: GroupIcon,
        route: () => rotaPadraoSemMenuBI('/usuarios', 'Usuários'),
        isSubmenu: false,
      },
      {
        name: 'Empresas',
        icon: BusinessIcon,
        route: () => rotaPadraoSemMenuBI('/admin/empresas', 'Empresas'),
        isSubmenu: false,
      },
      {
        name: 'Perfis',
        icon: GroupAddIcon,
        route: () => rotaPadraoSemMenuBI('/admin/perfis', 'Perfis'),
        isSubmenu: false,
      },
      {
        name: 'Logs',
        icon: DvrIcon,
        route: () => rotaPadraoSemMenuBI('/admin/logs', 'Logs'),
        isSubmenu: false,
      },
    ],
    ADMIN: [
      {
        name: 'Minhas Empresas',
        icon: BusinessIcon,
        route: () => rotaPadraoSemMenuBI('/empresas', 'Minhas Empresas'),
        isSubmenu: false,
      },
      {
        name: 'Logs',
        icon: DvrIcon,
        route: () => rotaPadraoSemMenuBI('/admin/logs', 'Logs'),
        isSubmenu: false,
      },
    ],
  }

  const isMenuOpen = Boolean(anchorEl)
  const isMenuEmpresaOpen = Boolean(anchorElEmpresa)
  const isMobileMenuOpen = Boolean(mobileMoreAnchorEl)

  React.useEffect(() => {
    createListaMenu(props.empresa)
  }, [props.empresa])

  function handlerSetPowerBi(rota, menu, powerbi) {
    props.setPowerbi(menu, powerbi)
    history.push(rota)
  }

  function rotaPadraoSemMenuBI(rota, nome) {
    props.setPowerbi(false, {
      accessToken: '',
      powerBiGroupId: '',
      powerBiReportId: '',
      nomeMenu: nome,
    })
    history.push(rota)
  }

  function logDeslogar() {
    let newObj = {
      idUsuario: idUsuario,
      idLogLogin: idLogLogin, //retornado no login do usuario
      tipo: 'DESLOGOU',
      ip: userInfo.ip,
      localizacao: userInfo.city + ' - ' + userInfo.country_name,
      aparelho: '',
      navegador: '',
    }
    api
      .post('/usuario/log', newObj)
      .then(({ data }) => {
        if (data.status) {
          console.log(data.mensagem)
        } else {
          console.log(data.mensagem)
        }
      })
      .catch((error) => {
        console.log('Houve um problema ao enviar os logs do usuário!')
      })
  }

  function logAcessouRelatorio(dados) {
    let newObj = {
      idUsuario: idUsuario,
      idLogLogin: idLogLogin, //retornado no login do usuario
      idEmpresa: empresa.idEmpresa, //enviar atual
      powerBiGroupId: dados.powerBiGroupId, //dados relatorio
      powerBiReportId: dados.powerBiReportId, //dados relatorio
      tipo: 'ACESSOU_RELATORIO',
      ip: userInfo.ip,
      localizacao: userInfo.city + ' - ' + userInfo.country_name,
      aparelho: '',
      navegador: '',
    }
    api
      .post('/usuario/log', newObj)
      .then(({ data }) => {
        if (data.status) {
          console.log(data.mensagem)
        } else {
          console.log(data.mensagem)
        }
      })
      .catch((error) => {
        console.log('Houve um problema ao enviar os logs do usuário!')
      })
  }

  function handlerClickSubMenus(sub) {
    logAcessouRelatorio(sub)
    handlerSetPowerBi(`/empresas/${sub.menu}`, true, {
      accessToken: props.accessToken,
      powerBiGroupId: sub.powerBiGroupId,
      powerBiReportId: sub.powerBiReportId,
      nomeMenu: sub.descricao,
    })
  }

  function groupByKey(array, key) {
    return array.reduce((hash, obj) => {
      if (obj[key] === undefined) return hash
      return Object.assign(hash, {
        [obj[key]]: (hash[obj[key]] || []).concat(obj),
      })
    }, {})
  }

  function createListaMenu(empresa) {
    let menu = null

    if (Utils.isNotObjectEmpty(props.empresa)) {
      menu = groupByKey(empresa, 'menu')
    }

    let newArray = []

    if (tipo === 'ADMIN_MOBIOH') {
      newArray = [...menuPadrao.ADMIN_MOBIOH]
      if (menu) {
        Object.keys(menu).forEach((item) => {
          let menuItem = {
            name: item,
            icon: AddchartIcon,
            route: () => handlerClickSubMenus(menu[item][0]),
            isSubmenu: false,
            submenu: [],
          }
          if (menu[item].length > 1) {
            menuItem.isSubmenu = true

            menu[item].forEach((sub) => {
              menuItem.submenu.push({
                name: sub.subMenu,
                submenuRoute: () => handlerClickSubMenus(sub),
              })
            })
          }

          newArray.push(menuItem)
        })
      }
    }

    if (tipo === 'ADMIN') {
      newArray = [...menuPadrao.ADMIN]
      if (menu) {
        Object.keys(menu).forEach((item) => {
          let menuItem = {
            name: item,
            icon: AddchartIcon,
            route: () => handlerClickSubMenus(menu[item][0]),
            isSubmenu: false,
            submenu: [],
          }
          if (menu[item].length > 1) {
            menuItem.isSubmenu = true

            menu[item].forEach((sub) => {
              menuItem.submenu.push({
                name: sub.subMenu,
                submenuRoute: () => handlerClickSubMenus(sub),
              })
            })
          }

          newArray.push(menuItem)
        })
      }
    }

    if (tipo === 'USUARIO') {
      newArray = [
        {
          name: 'Minhas Empresas',
          icon: BusinessIcon,
          route: () => rotaPadraoSemMenuBI('/empresas', 'Minhas Empresas'),
          isSubmenu: false,
        },
      ]

      if (menu) {
        Object.keys(menu).forEach((item) => {
          let menuItem = {
            name: item,
            icon: AddchartIcon,
            route: () => handlerClickSubMenus(menu[item][0]),
            isSubmenu: false,
            submenu: [],
          }
          if (menu[item].length > 1) {
            menuItem.isSubmenu = true

            menu[item].forEach((sub) => {
              menuItem.submenu.push({
                name: sub.subMenu,
                submenuRoute: () => handlerClickSubMenus(sub),
              })
            })
          }

          newArray.push(menuItem)
        })
      }
    }

    setPefilUsuarioNew([...newArray])
  }

  const handleDrawerOpen = () => {
    setOpen(!open)
  }

  function logout() {
    logDeslogar()
    setAnchorEl(null)
    handleMobileMenuClose()
    props.getEmpresa({}, '', '')
    props.logar(false, props.user)
    sessionStorage.removeItem('auth')
    sessionStorage.removeItem('empresa')
    sessionStorage.removeItem('powerbi')
    setPefilUsuarioNew([
      {
        name: 'Minhas Empresas',
        icon: BusinessIcon,
        route: () => rotaPadraoSemMenuBI('/empresas', 'Minhas Empresas'),
        isSubmenu: false,
      },
    ])
    history.push('/login')
  }

  const refPowerBi = () => {
    let report = window.report
    return report
  }

  var zoom_original = 0

  function zoom_diminui() {
    window.report.getZoom().then((value) => {
      if (zoom_original == 0) {
        zoom_original = value
      }

      var zoom_atual = value

      //console.log(zoom_atual);

      zoom_atual -= zoom_atual * 0.1

      //console.log(zoom_novo);

      window.report.setZoom(zoom_atual)
    })
  }

  function zoom_aumenta() {
    window.report.getZoom().then((value) => {
      if (zoom_original == 0) {
        zoom_original = value
      }

      var zoom_atual = value

      //console.log(zoom_atual);

      zoom_atual += zoom_atual * 0.1

      //console.log(zoom_novo);

      window.report.setZoom(zoom_atual)
    })
  }

  async function handlerClearFilter() {
    await refPowerBi().updateFilters(models.FiltersOperations.RemoveAll)
  }

  const handleProfileMenuOpen = (event) => {
    setAnchorEl(event.currentTarget)
  }

  const handleMobileMenuOpen = (event) => {
    setMobileMoreAnchorEl(event.currentTarget)
  }

  const handleMobileMenuClose = () => {
    setMobileMoreAnchorEl(null)
  }

  const handleMenuClose = () => {
    setAnchorEl(null)
    setAnchorElEmpresa(null)
    handleMobileMenuClose()
  }

  const handleMenuCloseEmpresa = (empresa) => {
    //this.setState({ empresaSelecionada: empresa.fantasia });
    props.getEmpresa(empresa.relatorios, empresa.imagemLogo, empresa.fantasia)

    let primeiroMenu = empresa.relatorios[0]

    handlerSetPowerBi(
      primeiroMenu.descricao === 'Dashboard'
        ? `/empresas/${primeiroMenu.powerBiReportId}`
        : '/empresas',
      false,
      {
        accessToken: props.accessToken,
        powerBiGroupId: primeiroMenu.powerBiGroupId,
        powerBiReportId: primeiroMenu.powerBiReportId,
        nomeMenu:
          primeiroMenu.descricao === 'Dashboard'
            ? primeiroMenu.descricao
            : 'Empresas',
      },
    )

    setAnchorElEmpresa(null)
  }
  const handleMenuOpenEmpresa = (event) => {
    setAnchorElEmpresa(event.currentTarget)
  }

  const menuId = 'primary-search-account-menu'
  const menuEmpresaId = 'primary-empresa-account-menu'
  const renderMenu = (
    <Menu
      anchorEl={anchorEl}
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      id={menuId}
      keepMounted
      transformOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      open={isMenuOpen}
      onClose={handleMenuClose}
    >
      <MenuItem onClick={handleMenuClose}>{nome}</MenuItem>
      <MenuItem onClick={() => rotaPadraoSemMenuBI('/perfil', 'Minha Conta')}>
        Minha Conta
      </MenuItem>
      <MenuItem onClick={logout}>Sair</MenuItem>
    </Menu>
  )

  const renderMenuEmpresas = (
    <Menu
      anchorEl={anchorElEmpresa}
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      id={menuEmpresaId}
      keepMounted
      transformOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      open={isMenuEmpresaOpen}
      onClose={handleMenuClose}
    >
      <MenuItem>
        <Typography
          variant="h6"
          component="h6"
          marginBottom={2}
          sx={{ fontWeight: 'bolder' }}
        >
          Selecione uma Empresa
        </Typography>
      </MenuItem>
      {empresas &&
        empresas.map((empresa) => {
          if (empresa.imagemLogo === props.empresaLogo) {
            return null
          }
          return (
            <MenuItem
              key={empresa.fantasia}
              onClick={() => handleMenuCloseEmpresa(empresa)}
            >
              <Avatar
                alt={empresa.fantasia}
                src={empresa.imagemLogo}
                sx={{ width: 35, height: 35, marginRight: '10px' }}
                className="border avatar-card"
              />
              {empresa.fantasia}
            </MenuItem>
          )
        })}
    </Menu>
  )

  const mobileMenuId = 'primary-search-account-menu-mobile'
  const renderMobileMenu = (
    <Menu
      anchorEl={mobileMoreAnchorEl}
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      sx={stylesCustom.icons}
      id={mobileMenuId}
      keepMounted
      transformOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      open={isMobileMenuOpen}
      onClose={handleMobileMenuClose}
    >
      {props.menuPowerbi && (
        <div>
          <MenuItem onClick={() => refPowerBi().reload()}>
            <IconButton
              size="large"
              aria-label="Recarregar"
              title="Recarregar"
              color="inherit"
            >
              <Badge color="error">
                <CachedIcon sx={stylesCustom.icons} />
              </Badge>
            </IconButton>
            <p>Recarregar</p>
          </MenuItem>

          <MenuItem onClick={() => handlerClearFilter()}>
            <IconButton
              size="large"
              aria-label="Atualizar"
              title="Atualizar"
              color="inherit"
            >
              <Badge color="error">
                <RestoreIcon sx={stylesCustom.icons} />
              </Badge>
            </IconButton>
            <p>Atualizar</p>
          </MenuItem>
        </div>
      )}
      <MenuItem onClick={handleProfileMenuOpen}>
        <IconButton
          size="large"
          aria-label="account of current user"
          aria-controls="primary-search-account-menu"
          aria-haspopup="true"
          color="inherit"
        >
          <Avatar
            alt="Avatar"
            src={imagemLogo}
            sx={{ width: 35, height: 35 }}
          />
        </IconButton>
        <p>Minha Conta</p>
      </MenuItem>
    </Menu>
  )

  return (
    <Container>
      <AppBar
        position="fixed"

        key={'Appbar'}
        sx={{ zIndex: (theme) => theme.zIndex.drawer + 1 }}
      >
        <Toolbar>
          <IconButton
            size="large"
            edge="start"
            color="inherit"
            aria-label="open drawer"
            sx={{ mr: 2 }}
            onClick={() => {
              handleDrawerOpen()
            }}
          >
            <MenuIcon />
          </IconButton>
          {props.empresaLogo && (
            <MenuItem onClick={handleMenuOpenEmpresa}>
              <Box
                sx={{
                  display: { xs: 'none', sm: 'flex' },
                  width: 90,
                  height: 50,
                  alignItems: 'center',
                }}
              >
                <img
                  className="responsive"
                  src={props.empresaLogo}
                  alt={'Logo Empresa'}
                />
              </Box>
            </MenuItem>
          )}
          <Box
            sx={{
              flexGrow: 1,
              display: { md: 'flex', sm: 'flex', xs: 'flex !important' },
              justifyContent: { md: 'center', sm: 'flex-end', xs: 'end' },
              minWidth: '250px',
              fontSize: { xs: '1.0rem' },
              alignItems: 'center',
              flexDirection: 'row'
            }}
          >
            {nomeMenu && nomeMenu}

            <Box sx={{ display: { xs: 'flex', md: 'none', marginLeft: '10px' } }}>
              {props.menuPowerbi && (
                <>
                  <IconButton
                    size="large"
                    aria-label="mobile powerbi"
                    title={mobilePowerbi ? 'Versão Desktop' : 'Versão Mobile'}
                    color="inherit"
                    onClick={() => {
                      setMobilePowerBi(!mobilePowerbi)
                    }}
                  >
                    <Badge color="error">
                      {mobilePowerbi ? (
                        <Tv sx={stylesCustom.icons} />
                      ) : (
                        <StayCurrentPortrait sx={stylesCustom.icons} />
                      )}
                    </Badge>
                  </IconButton>{' '}
                  <IconButton
                    size="large"
                    aria-label="Tela Cheia"
                    title="Tela Cheia"
                    color="inherit"
                    onClick={() => refPowerBi().fullscreen()}
                  >
                    <Badge color="error">
                      <FullscreenExitIcon sx={stylesCustom.icons} />
                    </Badge>
                  </IconButton>{' '}
                </>
              )}
            </Box>
          </Box>

          <Box sx={{ display: { xs: 'none', md: 'flex' } }}>
            {props.menuPowerbi && (
              <>
                <IconButton
                  size="large"
                  aria-label="mobile powerbi"
                  title={mobilePowerbi ? 'Versão Desktop' : 'Versão Mobile'}
                  color="inherit"
                  onClick={() => {
                    setMobilePowerBi(!mobilePowerbi)
                  }}
                >
                  <Badge color="error">
                    {mobilePowerbi ? (
                      <Tv sx={stylesCustom.icons} />
                    ) : (
                      <StayCurrentPortrait sx={stylesCustom.icons} />
                    )}
                  </Badge>
                </IconButton>
                <IconButton
                  size="large"
                  aria-label="aumentar"
                  title={'Aumentar'}
                  color="inherit"
                  onClick={() => {
                    zoom_aumenta()
                  }}
                >
                  <Badge color="error">
                    <Add sx={stylesCustom.icons} />
                  </Badge>
                </IconButton>
                <IconButton
                  size="large"
                  aria-label="menos"
                  title={'Diminuir'}
                  color="inherit"
                  onClick={() => {
                    zoom_diminui()
                  }}
                >
                  <Badge color="error">
                    <Remove sx={stylesCustom.icons} />
                  </Badge>
                </IconButton>
                <IconButton
                  size="large"
                  aria-label="Tela Cheia"
                  title="Tela Cheia"
                  color="inherit"
                  onClick={() => refPowerBi().fullscreen()}
                >
                  <Badge color="error">
                    <FullscreenExitIcon sx={stylesCustom.icons} />
                  </Badge>
                </IconButton>
                <IconButton
                  size="large"
                  aria-label="Recarregar"
                  title="Recarregar"
                  color="inherit"
                  onClick={() => refPowerBi().reload()}
                >
                  <Badge color="error">
                    <CachedIcon sx={stylesCustom.icons} />
                  </Badge>
                </IconButton>
                <IconButton
                  size="large"
                  aria-label="Atualizar"
                  title="Atualizar"
                  color="inherit"
                  onClick={() => handlerClearFilter()}
                >
                  <Badge color="error">
                    <RestoreIcon sx={stylesCustom.icons} />
                  </Badge>
                </IconButton>
              </>
            )}
            <IconButton
              size="large"
              edge="end"
              aria-label="account of current user"
              aria-controls={menuId}
              aria-haspopup="true"
              onClick={handleProfileMenuOpen}
              color="inherit"
            >
              <Avatar
                alt="Avatar"
                src={imagemLogo}
                sx={{ width: 45, height: 45 }}
              />
            </IconButton>
          </Box>
          <Box sx={{ display: { xs: 'flex', md: 'none' } }}>
            <IconButton
              size="large"
              aria-label="show more"
              aria-controls={mobileMenuId}
              aria-haspopup="true"
              onClick={handleMobileMenuOpen}
              color="inherit"
            >
              <MoreIcon />
            </IconButton>
          </Box>
        </Toolbar>
      </AppBar>
      {renderMobileMenu}
      {renderMenu}
      {Utils.isArrayLength(empresas) && renderMenuEmpresas}
      <Sidebar
        perfilUsuario={perfilUsuarioNew}
        open={open}
        logout={props.logout}
        history={props.history}
        rotaPadraoSemMenuBi={rotaPadraoSemMenuBI}
      />
      <Main open={open}>
        <DrawerHeader />
        {props.children}
      </Main>
    </Container>
  )
}

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    { logar, getEmpresa, setPowerbi, setMobilePowerBi },
    dispatch,
  )

const mapStateToProps = (store) => ({
  loggedIn: store.loginState.loggedIn,
  empresaLogo: store.empresaReducer.imagemLogo,
  empresaFantasia: store.empresaReducer.fantasia,
  user: store.loginState.user,
  powerbi: store.powerbiReducer.powerbi,
  menuPowerbi: store.powerbiReducer.menu,
  nomeMenu: store.powerbiReducer.powerbi.nomeMenu,
  accessToken: store.powerbiReducer.powerbi.accessToken,
  empresa: store.empresaReducer.empresa,
  mobilePowerbi: store.powerbiReducer.mobile,
})

export default connect(mapStateToProps, mapDispatchToProps)(DrawerBar)
