import React, { useState } from 'react'
import { Box, Button, CircularProgress, Grid, Stack } from '@mui/material'
import { Input, InputSelect, RadioButtonsGroup } from '../../components'
import { Utils } from '../../utilities'
import { toast } from 'react-toastify'
import PerfisService from '../../service/PerfisService'

export default function CadastrarEditarPerfil({
  editar,
  perfil,
  idUsuarioAtualizacao,
  handleClose,
  tipo,
  empresas,
  buscarPerfis
}) {
  const [loading, setLoading] = useState(false)
  const [descricao, setDescricao] = useState(perfil.descricao)
  const [idEmpresa, setIdEmpresa] = useState(perfil.idEmpresa)
  const [ativo, setAtivo] = useState(perfil.ativo)
  const [modificado, setModificado] = React.useState(false)

  const optionsStatus = [
    { value: true, label: 'Ativo' },
    { value: false, label: 'Inativo' },
  ]

  function validacao(value) {
    return modificado ? !Utils.isNotNull(value) : false
  }

  function cadastrarEditar() {
    setLoading(true)
    setModificado(true)

    if (!Utils.isNotNull(descricao)) {
      toast.error('Preencha as informações obrigatórias!', {
        theme: 'colored',
      })
      setLoading(false)
      return
    }

    let obj = {
      idEmpresa: idEmpresa,
      descricao: descricao,
      idUsuarioAtualizacao: idUsuarioAtualizacao,
    }

    let objPut = {
      idPerfil: perfil.idPerfil,
      descricao: descricao,
      idUsuarioAtualizacao: idUsuarioAtualizacao,
      ativo: ativo
    }

    if (!editar) {
      PerfisService.cadastrar(obj)
        .then(({ status, mensagem }) => {
          setLoading(false)
          buscarPerfis()
          handleClose()
          if (status) {
            toast.success(mensagem, {
              theme: 'colored',
            })
          } else {
            toast.error(mensagem, {
              theme: 'colored',
            })
          }
        })
        .catch((error) => {
          setLoading(false)
          toast.error('Houve um problema no cadastro do Perfil!', {
            theme: 'colored',
          })
        })
    } else {
      PerfisService.atualizar(objPut)
        .then(({ status, mensagem }) => {
          setLoading(false)
          buscarPerfis()
          handleClose()
          if (status) {
            toast.success(mensagem, {
              theme: 'colored',
            })
          } else {
            toast.error(mensagem, {
              theme: 'colored',
            })
          }
        })
        .catch((error) => {
          setLoading(false)
          toast.error('Houve um problema na atualização do Perfil!', {
            theme: 'colored',
          })
        })
    }

    
  }

  return (
    <Box padding={3}>
      {/* <ModalConfirmar
        open={openModal}
        handleConfirm={cadastrarEditarUsuario}
        handleClose={handleCloseModal}
        customStyle={{ width: 400 }}
      >
        <S.TitleModal>Salvar as informações do Usuário?</S.TitleModal>
      </ModalConfirmar> */}
      <Grid container padding={1} spacing={2}>
        <Grid xs={12} md={6} marginTop={2}>
          <Input
            label="Descrição"
            id={'descricao'}
            error={validacao(descricao)}
            helperText={
              validacao(descricao) ? 'Essa informação é obrigatória' : ''
            }
            value={descricao}
            onChange={(e) => {
              setDescricao(e.target.value)
            }}
            placeholder="Escreva a Descrição do Perfil"
            size={tipo === 'ADMIN_MOBIOH' ? 'small' : 'large'}
          />
        </Grid>

        {tipo === 'ADMIN_MOBIOH' && (
          <Grid xs={12} md={4} item>
            <InputSelect
              value={idEmpresa}
              setValue={(value) => {
                setIdEmpresa(value)
              }}
              disabled={editar}
              options={empresas}
              label="Empresas"
              id="idEmpresa"
              descricao="fantasia"
              size="small"
              sx={{ padding: '5px 0px 0px', marginTop: '-5px' }}
            />
          </Grid>
        )}

        {editar && (
          <Grid item xs={4}>
            <RadioButtonsGroup
              label="Status"
              options={optionsStatus}
              labelOptions="label"
              valueOptions="value"
              defaultValue={ativo}
              onChange={(e) => {
                setModificado(true)
                setAtivo(e.target.value === 'true')
              }}
            />
          </Grid>
        )}
      </Grid>
      <Stack direction="row" spacing={2} justifyContent="end">
        <Button
          className="confirm-button"
          variant="contained"
          size="large"
          type="submit"
          onClick={cadastrarEditar}
          startIcon={loading && <CircularProgress color="inherit" size={25} />}
          disabled={loading}
        >
          Salvar
        </Button>
      </Stack>
    </Box>
  )
}
