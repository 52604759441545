import React from 'react'
import {
  Box,
  Button,
  CircularProgress,
  Grid,
  Stack,
} from '@mui/material'
import {
  ComponentWithIcon,
  IconMaterial,
  Input,
  InputSelect,
  Item,
  ModalConfirmar,
  RadioButtonsGroup,
} from '../../components'
import { Utils } from '../../utilities'
import { toast } from 'react-toastify'
import * as S from './styles'
import RelatorioService from '../../service/RelatorioService'
import SearchOutlinedIcon from '@mui/icons-material/SearchOutlined'
import AddRoundedIcon from '@mui/icons-material/AddRounded'
import AddCircleOutlineOutlinedIcon from '@mui/icons-material/AddCircleOutlineOutlined'

export default function EditarRelatorio({
  menus = [],
  subMenus = [],
  infoRelatorio = {},
  editar = false,
  idUsuarioAtualizacao = '',
  closeDialog
}) {
  const [openModal, setOpenModal] = React.useState(false)
  const handleOpenModal = () => setOpenModal(true)
  const handleCloseModal = () => setOpenModal(false)

  const [openModalIcone, setOpenModalIcone] = React.useState(false)
  const handleOpenModalIcone = () => setOpenModalIcone(true)
  const handleCloseModalIcone = () => setOpenModalIcone(false)

  const [state, setState] = React.useState({
    idRelatorio: '',
    nome: '',
    descricao: '',
    menu: '',
    menuIcone: null,
    subMenu: '',
    subMenuIcone: null,
    ...infoRelatorio,
  })
  const [inputMenu, setInputMenu] = React.useState(false)
  const [pesquisar, setPesquisar] = React.useState('')
  const [selectedMenu, setSelectedMenu] = React.useState('menuIcone')

  const [loading, setLoading] = React.useState(false)

  function validacao(value) {
    return modificado ? !Utils.isNotNull(value) : false
  }

  const [modificado, setModificado] = React.useState(false)

  const optionsStatus = [
    { value: true, label: 'Ativo' },
    { value: false, label: 'Inativo' },
  ]

  function cadastrarEditar() {
    setLoading(true)
    setModificado(true)

    if (
      !Utils.isNotNull(state.nome) ||
      !Utils.isNotNull(state.descricao) ||
      !Utils.isNotNull(state.menu) ||
      !Utils.isNotNull(state.subMenu)
    ) {
      toast.error('Preencha as informações obrigatórias!', {
        theme: 'colored',
      })
      setLoading(false)
      handleCloseModal()
      return
    }

    if (!editar) {
      RelatorioService.cadastrar({
        nome: state.nome,
        descricao: state.descricao,
        menu: state.menu,
        menuIcone: state.menuIcone,
        subMenu: state.subMenu,
        subMenuIcone: state.subMenuIcone,
        idUsuarioAtualizacao: idUsuarioAtualizacao,
      })
        .then(({ status, mensagem }) => {
          setLoading(false)
          if (status) {
            toast.success(mensagem, {
              theme: 'colored',
            })
            closeDialog()
          } else {
            toast.error(mensagem, {
              theme: 'colored',
            })
          }
        })
        .catch((error) => {
          setLoading(false)
          toast.error('Houve um problema ao tentar cadastrar o relatório!', {
            theme: 'colored',
          })
        })
    } else {
      RelatorioService.atualizar({
        idRelatorio: state.idRelatorio,
        nome: state.nome,
        descricao: state.descricao,
        menu: state.menu,
        menuIcone: state.menuIcone,
        subMenu: state.subMenu,
        subMenuIcone: state.subMenuIcone,
        ativo: state.ativo,
        idUsuarioAtualizacao: idUsuarioAtualizacao,
      })
        .then(({ status, mensagem }) => {
          setLoading(false)
          if (status) {
            toast.success(mensagem, {
              theme: 'colored',
            })
            closeDialog()
          } else {
            toast.error(mensagem, {
              theme: 'colored',
            })
          }
        })
        .catch((error) => {
          setLoading(false)
          toast.error('Houve um problema na edição do Relatório!', {
            theme: 'colored',
          })
        })
    }

    handleCloseModal()
  }

  function handleSelectedIcone(icone) {
    setState((state) => ({
      ...state,
      [selectedMenu]: icone,
    }))
    handleCloseModalIcone()
  }

  return (
    <Box padding={3}>
      <ModalConfirmar
        open={openModal}
        handleConfirm={cadastrarEditar}
        handleClose={handleCloseModal}
        customStyle={{ width: 400 }}
      >
        <S.TitleModal>Salvar as informações do Relatório?</S.TitleModal>
      </ModalConfirmar>

      <ModalConfirmar
        open={openModalIcone}
        //  handleConfirm={cadastrarEditar}
        handleClose={handleCloseModalIcone}
        customStyle={{ width: '90vw', height: '90vh' }}
      >
        <S.TitleModal>Selecione um Ícone</S.TitleModal>

        <Grid container padding={1} spacing={2}>
          <Grid xs={5} item>
            <Input
              label="Pesquisar"
              id={'descricao'}
              // error={validacao(search)}
              // helperText={
              //   validacao(search) ? 'Essa informação é obrigatória' : ''
              // }
              value={pesquisar}
              onChange={(e) => {
                setPesquisar(e.target.value)
              }}
              placeholder="Escreva o nome do ícone "
              size="small"
            />
          </Grid>
          <Grid xs={2} item display={'flex'}>
            <Button
              className="confirm-button"
              variant="contained"
              size="large"
              startIcon={
                loading ? (
                  <CircularProgress color="inherit" size={25} />
                ) : (
                  <SearchOutlinedIcon />
                )
              }
              disabled={loading}
              sx={{ height: '39px' }}
              // onClick={buscarMobioh}
            >
              Buscar
            </Button>
          </Grid>
          <Grid xs={12}>
            <ComponentWithIcon
              handleSelectedIcone={handleSelectedIcone}
              pesquisar={pesquisar}
            />
          </Grid>
        </Grid>
      </ModalConfirmar>
      <Grid container padding={1} spacing={2}>
        <Grid xs={12} md={4} item>
          <Input
            label="Nome"
            id={'nome'}
            error={validacao(state.nome)}
            helperText={
              validacao(state.nome) ? 'Essa informação é obrigatória' : ''
            }
            value={state.nome}
            onChange={(e) => {
              setState((state) => ({
                ...state,
                nome: e.target.value,
              }))
            }}
            placeholder="Escreva o Nome para o Relatório"
            size="small"
          />
        </Grid>

        <Grid xs={12} md={8} item>
          <Input
            label="Descrição"
            id={'descricao'}
            error={validacao(state.descricao)}
            helperText={
              validacao(state.descricao) ? 'Essa informação é obrigatória' : ''
            }
            value={state.descricao}
            onChange={(e) => {
              setState((state) => ({
                ...state,
                descricao: e.target.value,
              }))
            }}
            placeholder="Escreva uma descrição para o Relatórios"
            size="small"
          />
        </Grid>

        <Grid xs={12} md={6} item display={'flex'} flexDirection={'row'}>
          <Button
            className="confirm-button"
            variant="outlined"
            color="secondary"
            size="large"
            endIcon={
              loading ? (
                <CircularProgress color="inherit" size={25} />
              ) : (
                !inputMenu && <AddCircleOutlineOutlinedIcon sx={{ marginTop: '-5px' }} />
              )
            }
            disabled={loading}
            sx={{ height: '39px', width: 200, marginRight: '15px' }}
            onClick={() => setInputMenu(!inputMenu)}
          >
            {inputMenu ? 'Selecione' : 'Novo'}
          </Button>

          {inputMenu ? (
            <Input
              label="Menu"
              id={'menu'}
              error={validacao(state.menu)}
              helperText={
                validacao(state.menu) ? 'Essa informação é obrigatória' : ''
              }
              value={state.menu}
              onChange={(e) => {
                setState((state) => ({
                  ...state,
                  menu: e.target.value,
                }))
              }}
              placeholder="Escreva um menu para o Relatório"
              size="small"
            />
          ) : (
            <InputSelect
              value={state.menu}
              setValue={(value) => {
                setState((state) => ({
                  ...state,
                  menu: value,
                }))
              }}
              options={menus}
              label="Menus"
              id="id"
              descricao="id"
              size="small"
              sx={{ padding: '5px 0px 0px', marginTop: '-5px' }}
              error={validacao(state.menu)}
              helperText={
                validacao(state.menu) ? 'Essa informação é obrigatória' : ''
              }
            />
          )}
        </Grid>

        <Grid xs={12} md={6} item>
          <Input
            label="Submenu"
            id={'subMenu'}
            error={validacao(state.subMenu)}
            helperText={
              validacao(state.subMenu) ? 'Essa informação é obrigatória' : ''
            }
            value={state.subMenu}
            onChange={(e) => {
              setState((state) => ({
                ...state,
                subMenu: e.target.value,
              }))
            }}
            placeholder="Escreva um Submenu para o Relatório"
            size="small"
          />
        </Grid>

        <Grid xs={12} md={6} item display={'flex'} flexDirection={'row'}>
          <Button
            className="confirm-button"
            variant="outlined"
            size="large"
            color="info"
            disabled={loading}
            sx={{ height: '39px' }}
            onClick={() => {
              handleOpenModalIcone()
              setSelectedMenu('menuIcone')
            }}
          >
            Selecionar Ícone para Menu
          </Button>
          {state.menuIcone && (
            <Item
              style={{ height: 39, marginLeft: '15px', marginBottom: '8px' }}
            >
              <IconMaterial iconName={state.menuIcone} />
            </Item>
          )}
        </Grid>

        <Grid xs={12} md={6} item display={'flex'} flexDirection={'row'}>
          <Button
            className="confirm-button"
            variant="outlined"
            size="large"
            color="info"
            disabled={loading}
            sx={{ height: '39px' }}
            onClick={() => {
              handleOpenModalIcone()
              setSelectedMenu('subMenuIcone')
            }}
          >
            Selecionar Ícone para Submenu
          </Button>
          {state.subMenuIcone && (
            <Item
              style={{ height: 39, marginLeft: '15px', marginBottom: '8px' }}
            >
              <IconMaterial iconName={state.subMenuIcone} />
            </Item>
          )}
        </Grid>

        {editar && (
          <Grid item xs={4}>
            <RadioButtonsGroup
              label="Status"
              options={optionsStatus}
              labelOptions="label"
              valueOptions="value"
              defaultValue={state.ativo}
              onChange={(e) => {
                setModificado(true)
                setState((state) => ({
                  ...state,
                  ativo: e.target.value === 'true',
                }))
              }}
            />
          </Grid>
        )}
      </Grid>

      <Stack direction="row" spacing={2} justifyContent="end">
        <Button
          className="confirm-button"
          variant="contained"
          size="large"
          type="submit"
          onClick={handleOpenModal}
          startIcon={loading && <CircularProgress color="inherit" size={25} />}
          disabled={loading}
        >
          Salvar
        </Button>
      </Stack>
    </Box>
  )
}
