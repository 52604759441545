import styled from 'styled-components'

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  color: ${(props) => props.theme.font.color};
  padding: 5px 15px 15px 15px;
  background-color: #fff;

  border: 1px solid transparent;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 6px -1px,
    rgba(0, 0, 0, 0.06) 0px 2px 4px -1px;

  width: 100%;
  border-radius: 4px;
`

export const TitleModal = styled.h3`
  font-size: 22px;
  font-weight: bolder;

  width: 100%;
  margin: 5px 5px 0;

  display: flex;
  align-items: center;

  padding: 15px;

`

export const TitleModalSincronizar = styled.h3`
  font-size: 22px;
  font-weight: bolder;

  width: 100%;
  margin: 5px 0;

  display: flex;
  align-items: center;

  padding: 10px;

`

export const ContainerForm = styled.div`
  width: 100%;
  margin: 0 5px 10px;

  border: 1px solid #e3e6ed;

  padding: 15px;
`
